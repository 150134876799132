<template>
<div class="contentBox">
  <div  :class="chooseArea==1?'contentBox1':'contentBox2'">
    <div class="content">
      <div class="dispatch_title">
        <img src="../../assets/content/index_title.png" alt="">
      </div>
      <div class="dispath_content">
        <div class="dispath_cont_left">
          <div class="leftDataItem">
              <div class="leftNum" style="color:#FA6400">{{Number(allPortSummary.yearTotal).toLocaleString() }}</div>
              <div class="leftNumTitle">全部港区 全年吞吐量(吨)</div>
          </div>
          <div class="leftDataItem">
              <div class="leftNum">{{Number(allPortSummary.monthTotal).toLocaleString() }}</div>
              <div class="leftNumTitle">全部港区 本月吞吐量(吨)</div>
          </div>
          <div class="leftDataItem">
              <div class="leftNum">{{Number(allPortSummary.todayTotal).toLocaleString() }}</div>
              <div class="leftNumTitle">全部港区 今日吞吐量(吨)</div>
          </div>
          <div class="leftDataItem">
              <div class="leftNum">{{Number(allPortSummary.todayCarNum).toLocaleString() }}</div>
              <div class="leftNumTitle">全部港区 今日车量总计</div>
          </div>
          <div class="leftDataItem">
              <div class="leftNum">{{Number(allPortSummary.todayShipNum).toLocaleString() }}</div>
              <div class="leftNumTitle">全部港区 今日船量总计</div>
          </div>
        </div>
  
        <div class="dispath_cont_center">
            <div class="chooseBut" :class="chooseArea==1?'chooseShandong':'chooseChina'">
              <div @click="chooseItem(0)">全国</div>
              <div @click="chooseItem(1)">山东</div>
            </div>
          <div class="portContent">
            <!-- 选择山东时 -->
            <div class="shandongMap" v-if="chooseArea==1">
              <div class="setPort1">
                <img src="../../assets/content/green.png" alt="" style="width:60px">
                <span  style="font-size: 20px;color: rgba(255, 255, 255, 0.5);">祥城北港</span>
              </div>
               <div class="setPort2">
                <img src="../../assets/content/green.png" alt="" style="width:60px">
                <span  style="font-size: 20px;color: rgba(255, 255, 255, 0.5);">广源陆港</span>
              </div>
              <div class="setPort3">
                <img src="../../assets/content/green.png" alt="" style="width:60px">
                <span  style="font-size: 20px;color: rgba(255, 255, 255, 0.5);">微山港</span>
              </div>
              <div v-if="false" class="setPort4">
                <img src="../../assets/content/grey.png" alt="" style="width:60px">
                <span  style="font-size: 20px;color: rgba(255, 255, 255, 0.5);">徐州双楼港</span>
              </div>
              <div class="zjPort" @mouseover="mouseOver('zj')" @mouseleave="mouseLeave('zj')">
                <img src="../../assets/content/blue.png" alt="" style="width:60px"  v-if="zjActive==0">
                <img src="../../assets/content/avtivePort.png" alt="" style="width:60px"  v-if="zjActive==1">
                <span  style="font-size: 20px;color:#FFFFFF;">济州港</span>
                <div v-if="zjActive==1" class="PortDetail">
                  <div class="showPortName">
                    <img src="../../assets/content/blue.png" alt="" style="width:30px"  >
                    <span style="font-size: 28px;">济州港</span>
                  </div>
                  <div class="showItem">
                    <div style=" display: flex;align-items: center;"><span class="blueIcon"></span> 本月吞吐量(吨)</div>
                    <div style="font-size: 20px;color: #FFFFFF;">{{Number(zjPortData.monthTotal).toLocaleString() }}</div>
                  </div>
                  <div class="showItem" >
                    <div style=" display: flex;align-items: center;"><span class="blueIcon"></span>  今日吞吐量(吨)</div>
                    <div style="font-size: 20px;color: #FFFFFF;">{{Number(zjPortData.todayTotal).toLocaleString() }}</div>
                  </div>
                  <div style="box-shadow: 0px 2px 20px 0px #000000;border-bottom: 1px solid #363636;height: 1px;margin-top:10px"></div>
                  <div style=" display: flex;align-items: center;">
                      <div style="width:50%">
                        <div class="showItem2" ><span class="greenIcon"></span> 今日车量</div>
                        <div style="font-size: 20px;color: #FFFFFF;padding-left: 25px;">{{Number(zjPortData.todayCarNum).toLocaleString() }}</div>
                      </div>
                      <div style="width:50%" >
                        <div class="showItem2"><span class="greenIcon"></span>  今日船量</div>
                        <div style="font-size: 20px;color: #FFFFFF;;padding-left: 25px;">{{Number(zjPortData.todayShipNum).toLocaleString() }}</div>
                      </div>
                  </div>
                  
                </div>
              </div>
              <div class="tylPort" @mouseover="mouseOver('tyl')" @mouseleave="mouseLeave('tyl')">
                <span  style="font-size: 20px;color:#FFFFFF;">天宇龙港</span>
                <img src="../../assets/content/blue.png" alt="" style="width:60px"  v-if="tylActive==0">
                <img src="../../assets/content/avtivePort.png" alt="" style="width:60px"  v-if="tylActive==1">
                <div v-if="tylActive==1" class="PortDetail tylPortDetailS">
                  <div class="showPortName">
                    <img src="../../assets/content/blue.png" alt="" style="width:30px"  >
                    <span style="font-size: 28px;">天宇龙港</span>
                  </div>
                  <div class="showItem">
                    <div style=" display: flex;align-items: center;"><span class="blueIcon"></span> 本月吞吐量(吨)</div>
                    <div style="font-size: 20px;color: #FFFFFF;">{{Number(tylPortData.monthTotal).toLocaleString() }}</div>
                  </div>
                  <div class="showItem" >
                    <div style=" display: flex;align-items: center;"><span class="blueIcon"></span>  今日吞吐量(吨)</div>
                    <div style="font-size: 20px;color: #FFFFFF;">{{Number(tylPortData.todayTotal).toLocaleString() }}</div>
                  </div>
                  <div style="box-shadow: 0px 2px 20px 0px #000000;border-bottom: 1px solid #363636;height: 1px;margin-top:10px"></div>
                  <div style=" display: flex;align-items: center;">
                      <div style="width:50%">
                        <div class="showItem2" ><span class="greenIcon"></span> 今日车量</div>
                        <div style="font-size: 20px;color: #FFFFFF;padding-left: 25px;">{{Number(tylPortData.todayCarNum).toLocaleString() }}</div>
                      </div>
                      <div style="width:50%" >
                        <div class="showItem2"><span class="greenIcon"></span>  今日船量</div>
                        <div style="font-size: 20px;color: #FFFFFF;;padding-left: 25px;">{{Number(tylPortData.todayShipNum).toLocaleString() }}</div>
                      </div>
                  </div>
                  
                </div>
                
              </div>
              <div class="wfPort" @mouseover="mouseOver('wf')" @mouseleave="mouseLeave('wf')">
                <span  style="font-size: 20px;color:#FFFFFF;">万丰港</span>
                 <img src="../../assets/content/blue.png" alt="" style="width:60px"  v-if="wfActive==0">
                <img src="../../assets/content/avtivePort.png" alt="" style="width:60px"  v-if="wfActive==1">
                <div v-if="wfActive==1" class="PortDetail wfPortDetailS">
                  <div class="showPortName">
                    <img src="../../assets/content/blue.png" alt="" style="width:30px"  >
                    <span style="font-size: 28px;">万丰港</span>
                  </div>
                  <div class="showItem">
                    <div style=" display: flex;align-items: center;"><span class="blueIcon"></span> 本月吞吐量(吨)</div>
                    <div style="font-size: 20px;color: #FFFFFF;">{{Number(wfPortData.monthTotal).toLocaleString() }}</div>
                  </div>
                  <div class="showItem" >
                    <div style=" display: flex;align-items: center;"><span class="blueIcon"></span>  今日吞吐量(吨)</div>
                    <div style="font-size: 20px;color: #FFFFFF;">{{Number(wfPortData.todayTotal).toLocaleString() }}</div>
                  </div>
                  <div style="box-shadow: 0px 2px 20px 0px #000000;border-bottom: 1px solid #363636;height: 1px;margin-top:10px"></div>
                  <div style=" display: flex;align-items: center;">
                      <div style="width:50%">
                        <div class="showItem2" ><span class="greenIcon"></span> 今日车量</div>
                        <div style="font-size: 20px;color: #FFFFFF;padding-left: 25px;">{{Number(wfPortData.todayCarNum).toLocaleString() }}</div>
                      </div>
                      <div style="width:50%" >
                        <div class="showItem2"><span class="greenIcon"></span>  今日船量</div>
                        <div style="font-size: 20px;color: #FFFFFF;;padding-left: 25px;">{{Number(wfPortData.todayShipNum).toLocaleString() }}</div>
                      </div>
                  </div>
                  
                </div>
                
              </div>
            </div>
            <div class="chinaMap" v-if="chooseArea==0">
              <div class="setPortC1">
                <img src="../../assets/content/green.png" alt="" style="width:20px">
                <span  style="font-size: 12px;color: rgba(255, 255, 255, 0.5);">祥城北港</span>
              </div>
              <div class="setPortC2">
                <img src="../../assets/content/green.png" alt="" style="width:20px">
                <span  style="font-size: 12px;color: rgba(255, 255, 255, 0.5);">广源陆港</span>
              </div>
              <div class="setPortC3">
                <img src="../../assets/content/green.png" alt="" style="width:20px">
                <span  style="font-size: 12px;color: rgba(255, 255, 255, 0.5);">微山港</span>
              </div>
              <div v-if="false" class="setPortC4">
                <img src="../../assets/content/grey.png" alt="" style="width:20px">
                <span  style="font-size: 12px;color: rgba(255, 255, 255, 0.5);">徐州双楼港</span>
              </div>
              <div v-if="false" class="setPortC5">
                <img src="../../assets/content/grey.png" alt="" style="width:20px">
                <span  style="font-size: 12px;color: rgba(255, 255, 255, 0.5);">淮安东港</span>
              </div>
              <div class="setPortC6">
                <img src="../../assets/content/green.png" alt="" style="width:20px">
                <span  style="font-size: 12px;color: rgba(255, 255, 255, 0.5);">蚌埠港</span>
              </div>
              <div class="setPortC7">
                <img src="../../assets/content/grey.png" alt="" style="width:20px">
                <span  style="font-size: 12px;color: rgba(255, 255, 255, 0.5);">泰兴港</span>
              </div>
              <div class="setPortC8">
                <img src="../../assets/content/grey.png" alt="" style="width:20px">
                <span  style="font-size: 12px;color: rgba(255, 255, 255, 0.5);">渔人码头</span>
              </div>
              <div class="setPortC9">
                <img src="../../assets/content/grey.png" alt="" style="width:20px">
                <span  style="font-size: 12px;color: rgba(255, 255, 255, 0.5);">连云港</span>
              </div>
              <div class="setPortC10">
                <img src="../../assets/content/grey.png" alt="" style="width:20px">
                <span  style="font-size: 12px;color: rgba(255, 255, 255, 0.5);">武宣港</span>
              </div>
              <div class="zjPortC" @mouseover="mouseOver('zj')" @mouseleave="mouseLeave('zj')">
                <img src="../../assets/content/blue.png" alt="" style="width:20px"  v-if="zjActive==0">
                <img src="../../assets/content/avtivePort.png" alt="" style="width:20px"  v-if="zjActive==1">
                <span  style="font-size: 12px;color:#FFFFFF;">济州港</span>
                <div v-if="zjActive==1" class="PortDetail jzPortDetailC">
                  <div class="showPortName">
                    <img src="../../assets/content/blue.png" alt="" style="width:30px"  >
                    <span style="font-size: 28px;">济州港</span>
                  </div>
                  <div class="showItem">
                    <div style=" display: flex;align-items: center;"><span class="blueIcon"></span> 本月吞吐量(吨)</div>
                    <div style="font-size: 20px;color: #FFFFFF;">{{Number(zjPortData.monthTotal).toLocaleString() }}</div>
                  </div>
                  <div class="showItem" >
                    <div style=" display: flex;align-items: center;"><span class="blueIcon"></span>  今日吞吐量(吨)</div>
                    <div style="font-size: 20px;color: #FFFFFF;">{{Number(zjPortData.todayTotal).toLocaleString() }}</div>
                  </div>
                  <div style="box-shadow: 0px 2px 20px 0px #000000;border-bottom: 1px solid #363636;height: 1px;margin-top:10px"></div>
                  <div style=" display: flex;align-items: center;">
                      <div style="width:50%">
                        <div class="showItem2" ><span class="greenIcon"></span> 今日车量</div>
                        <div style="font-size: 20px;color: #FFFFFF;padding-left: 25px;">{{Number(zjPortData.todayCarNum).toLocaleString() }}</div>
                      </div>
                      <div style="width:50%" >
                        <div class="showItem2"><span class="greenIcon"></span>  今日船量</div>
                        <div style="font-size: 20px;color: #FFFFFF;;padding-left: 25px;">{{Number(zjPortData.todayShipNum).toLocaleString() }}</div>
                      </div>
                  </div>
                  
                </div>
              </div>
               <div class="tylPortC" @mouseover="mouseOver('tyl')" @mouseleave="mouseLeave('tyl')">
                <span  style="font-size: 12px;color:#FFFFFF;">天宇龙港</span>
                 <img src="../../assets/content/blue.png" alt="" style="width:20px"  v-if="tylActive==0">
                <img src="../../assets/content/avtivePort.png" alt="" style="width:20px"  v-if="tylActive==1">
                <div v-if="tylActive==1" class="PortDetail tylPortDetailC">
                  <div class="showPortName">
                    <img src="../../assets/content/blue.png" alt="" style="width:30px"  >
                    <span style="font-size: 28px;">天宇龙港</span>
                  </div>
                  <div class="showItem">
                    <div style=" display: flex;align-items: center;"><span class="blueIcon"></span> 本月吞吐量(吨)</div>
                    <div style="font-size: 20px;color: #FFFFFF;">{{Number(tylPortData.monthTotal).toLocaleString() }}</div>
                  </div>
                  <div class="showItem" >
                    <div style=" display: flex;align-items: center;"><span class="blueIcon"></span>  今日吞吐量(吨)</div>
                    <div style="font-size: 20px;color: #FFFFFF;">{{Number(tylPortData.todayTotal).toLocaleString() }}</div>
                  </div>
                  <div style="box-shadow: 0px 2px 20px 0px #000000;border-bottom: 1px solid #363636;height: 1px;margin-top:10px"></div>
                  <div style=" display: flex;align-items: center;">
                      <div style="width:50%">
                        <div class="showItem2" ><span class="greenIcon"></span> 今日车量</div>
                        <div style="font-size: 20px;color: #FFFFFF;padding-left: 25px;">{{Number(tylPortData.todayCarNum).toLocaleString() }}</div>
                      </div>
                      <div style="width:50%" >
                        <div class="showItem2"><span class="greenIcon"></span>  今日船量</div>
                        <div style="font-size: 20px;color: #FFFFFF;;padding-left: 25px;">{{Number(tylPortData.todayShipNum).toLocaleString() }}</div>
                      </div>
                  </div>
                  
                </div>
              </div>
              <div class="wfPortC" @mouseover="mouseOver('wf')" @mouseleave="mouseLeave('wf')">
                <img src="../../assets/content/blue.png" alt="" style="width:20px"  v-if="wfActive==0">
                <img src="../../assets/content/avtivePort.png" alt="" style="width:20px"  v-if="wfActive==1">
                <span  style="font-size: 12px;color:#FFFFFF;">万丰港</span>
                <div v-if="wfActive==1" class="PortDetail wfPortDetailC">
                  <div class="showPortName">
                    <img src="../../assets/content/blue.png" alt="" style="width:30px"  >
                    <span style="font-size: 28px;">万丰港</span>
                  </div>
                  <div class="showItem">
                    <div style=" display: flex;align-items: center;"><span class="blueIcon"></span> 本月吞吐量(吨)</div>
                    <div style="font-size: 20px;color: #FFFFFF;">{{Number(wfPortData.monthTotal).toLocaleString() }}</div>
                  </div>
                  <div class="showItem" >
                    <div style=" display: flex;align-items: center;"><span class="blueIcon"></span>  今日吞吐量(吨)</div>
                    <div style="font-size: 20px;color: #FFFFFF;">{{Number(wfPortData.todayTotal).toLocaleString() }}</div>
                  </div>
                  <div style="box-shadow: 0px 2px 20px 0px #000000;border-bottom: 1px solid #363636;height: 1px;margin-top:10px"></div>
                  <div style=" display: flex;align-items: center;">
                      <div style="width:50%">
                        <div class="showItem2" ><span class="greenIcon"></span> 今日车量</div>
                        <div style="font-size: 20px;color: #FFFFFF;padding-left: 25px;">{{Number(wfPortData.todayCarNum).toLocaleString() }}</div>
                      </div>
                      <div style="width:50%" >
                        <div class="showItem2"><span class="greenIcon"></span>  今日船量</div>
                        <div style="font-size: 20px;color: #FFFFFF;;padding-left: 25px;">{{Number(wfPortData.todayShipNum).toLocaleString() }}</div>
                      </div>
                  </div>
                  
                </div>
              </div>
            </div>
            
          </div>
          <div>
            <div class="godataCenter">
              <img src="../../assets/content/gocenterBlue.png" alt="" style="margin-bottom:-10px">
              <!-- <img v-if="chooseArea==0" src="../../assets/content/gocenter.png" alt="" > -->
            </div>

          </div>
        </div>
        <!-- 右侧的 -->
        <div class="dispath_cont_right">
          <!-- 右侧标题行 -->
          <div class="rightTitle">
            <div class="rightTitleL1"></div>
            <div class="rightTitleL"></div>
            <div class="rightTitleText">各港吞吐量</div>
            <div class="rightTitleR"></div>
            <div class="rightTitleR1"></div>
          </div>
          <!-- 单位 -->
          <div class="utilRow">
            <div>年吞吐量</div>
            <div>单位：吨</div>
          </div>
          <div v-for="(data,index) in allPortThroughput" :key="'allPortThroughput'+index" class="havePortData">
            <div>
              <img src="../../assets/content/blue.png" alt="" style="width:35px">
              <span style="font-size: 16px;">{{data.postName}}</span>
            </div>
            <div>{{Number(data.throughput).toLocaleString() }}</div>
          </div>
          <div v-for="(data,index) in buildingName" :key="'buildingName_'+index" class="havePortData">
            <div>
              <img src="../../assets/content/green.png" alt="" style="width:35px">
              <span  style="font-size: 16px;color: rgba(100, 255, 100, 0.3);">{{data}}</span>
            </div>
            <div style="font-size: 16px;color: rgba(100, 255, 100, 0.3);">在建中</div>
          </div>
          <div v-for="(data,index) in noPortName" :key="'noPortName_'+index" class="havePortData">
            <div>
              <img src="../../assets/content/grey.png" alt="" style="width:35px">
              <span  style="font-size: 16px;color: rgba(255, 255, 255, 0.4);">{{data}}</span>
            </div>
            <div style="font-size: 16px;color: rgba(255, 255, 255, 0.4);">洽谈中</div>
          </div>
        </div>

      </div>
      <div class="dispath_bottom_tab">
        <div class="dispath_tabbox">
          <div  v-for="(item,i) in tablist" :key="i">
            <img :src="item.src" alt="" @click="godetails(i)">
          </div>
        </div>
      </div>
    </div>
  </div>
  
</div>
  
</template>
<script>

import {
  // 所有港区汇总
getAllPortSummary,
  // 各港年度吞吐量
getAllPortThroughput,
getOnePortSummary
} from '../../http/index'
export default {
  data() {
    return {

      tablist:[
        {
          id:1,
          src:require("../../assets/content/scjh_text.png")
        },
        {
          id:2,
          src:require("../../assets/content/cljk_text.png")
        },
        {
          id:3,
          src:require("../../assets/content/cbjk_text.png")
        },
        {
          id:4,
          src:require("../../assets/content/jkdp_text.png")
        },
        {
          id:5,
          src:require("../../assets/content/sbgl_text.png")
        },
        {
          id:6,
          src:require("../../assets/content/xssj_text.png")
        },
        {
          id:7,
          src:require("../../assets/content/hwsj_text.png")
        }
      ],
      noPortNum:0,
      noPortName:['泰兴港','武宣港','连云港','渔人码头'],
      buildingName:['祥城北港','广源陆港','微山港','蚌埠港'],
      chooseArea:1,
      allPortSummary:{},
      allPortThroughput:[],
      xcbActive:0,
      zjActive:0,
      wfActive:0,
      tylActive:0,
      zjPortData:{},
      wfPortData:{},
      tylPortData:{},
      intervalId:null
  

     
    };
  },
  beforeDestroy() {
     this.clear();
  },
  mounted() {
    
  },
  created(){
    this.loadData()
    this.dataRefreh();
  },
  methods: {
    // 定时刷新数据函数
    dataRefreh() {
      // 计时器正在进行中，退出函数
      if (this.intervalId !== null) {
        return;
      }
      // 计时器为空，操作
      this.intervalId = setInterval(() => {
       this.loadData()  
      }, 60000*5);  
    },
    // 停止定时器
    clear() {
      this.intervalId && clearInterval(this.intervalId); //清除计时器
    },
    loadData(){
      getAllPortSummary().then((res)=>{
        this.allPortSummary=res
        console.log(res,'res')
      })
      getAllPortThroughput().then((res)=>{
        this.allPortThroughput=res
        this.noPortNum=Number(8-this.allPortThroughput.length)
        console.log(res,'res00000')
      })
      let query={
        apikey: "Sw4Mi3MdjII=",
        portName: "wf"
      }
      getOnePortSummary(query).then((res)=>{
        this.wfPortData=res
        console.log(res,'res0000022222')
      })
      let query1={
        apikey: "Sw4Mi3MdjII=",
        portName: "wf"
      }
      getOnePortSummary(query1).then((res)=>{
        console.log(res,'res00000333')
        this.zjPortData=res
      })
      let query2={
        apikey: "Sw4Mi3MdjII=",
        portName: "tyl"
      }
      getOnePortSummary(query2).then((res)=>{
        this.tylPortData=res
        console.log(res,'res00000444')
      })
      
      
    },
    chooseItem(val){
      this.chooseArea=val
    },
     // 移入
    mouseOver(val) {
      if(val=='zj'){
        this.zjActive=1
      }
      if(val=='wf'){
        this.wfActive=1
      }
      if(val=='tyl'){
        this.tylActive=1
      }
      if(val=='xcb'){
        this.xcbActive=1
      }
      
    },
    // 移出
    mouseLeave(val) {
      if(val=='zj'){
        this.zjActive=0
      }
      if(val=='wf'){
        this.wfActive=0
      }
      if(val=='tyl'){
        this.tylActive=0
      }
      if(val=='xcb'){
        this.xcbActive=0
      }
    },
    // 点击底部按钮跳转页面
    godetails(e){
      if(e === 0){
        this.$router.push({
          path:'/Production'
        })
      }else if(e === 1){
        this.$router.push({
          path:'/WbCenter'
        })
      }else if( e === 2){
        this.$router.push({
          path:'/shipCenter'
        })
      }else if( e === 3){
        this.$router.push({
          path:'/MonitoringCenter'
        })
      }else if( e === 4){
        this.$router.push({
          path:'/Equipment'
        })
      }else if( e === 5){
        this.$router.push({
          path:'/SaleCenter'
        })
      }else if( e === 6){
        this.$router.push({
          path:'/GoodsCenter'
        })
      }
    },
  }
};
</script>

<style scoped lang="less">
@import "./index.less";
</style>
<template>
  <div id="Production-cont">
    <div class="Production-cont-box">
      <div class="Production_bg"></div>
      <div class="Production">
        <div class="Production-title">
          <div class="Production-title-left">
            <a-cascader
              class="Production-chose"
              :field-names="{ label: 'name', value: 'code', children: 'items' }"
              :options="options"
              :allowClear="false"
              placeholder="万丰港区"
              @change="onChange"
            />
          </div>
          <div class="sale-title-center">
            <img src="../../assets/image/left.png" alt="" @click="golast()"/>
            <img src="../../assets/image/productionTitle.png" alt="" />
            <img src="../../assets/image/right.png" alt="" @click="gonext()" />
          </div>
          <div class="sale-title-right">
            <div class="sale-title-right-button1">
              <img src="../../assets/image/back.png" alt="" @click="goback()" />
            </div>
            <div class="sale-title-right-button1">
               <img src="../../assets/image/fullScreen.png" alt=""  v-if="!isFullscreen"  @click="f11Click" />
              <img src="../../assets/image/fullScreenUn.png" alt=""  v-else  @click="f11Click" />
            </div>
          </div>
        </div>
        <div class="Production-top-echart">
          <div class="Production-top-echart-left">
            <div class="Production-port-date">
              <p class="todayData">
                <span>车辆</span>计划及各货物流向装卸量<span
                  >Current Equipment condition in Port</span
                >
              </p>
              <div class="Production-port-date-num">
                <div class="Production-portBox_1">
                  <p>计划总装卸量</p>
                  <p>{{summarydata.val1?summarydata.val1:'-'}}<span>吨</span></p>
                </div>
                <div class="Production-portBox">
                  <div>
                    <img src="../../assets/image/carToPort.png" alt="" />
                    <p>{{summarydata.val2?summarydata.val2:'-'}}<span>吨</span></p>
                  </div>
                  <div>
                    <img src="../../assets/image/portToCar.png" alt="" />
                    <p>{{summarydata.val3?summarydata.val3:'-'}}<span>吨</span></p>
                  </div>
                </div>
                <div class="Production-portBox">
                  <div>
                    <img src="../../assets/image/carToShip.png" alt="" />
                    <p>{{summarydata.val4?summarydata.val4:'-'}}<span>吨</span></p>
                  </div>
                  <div>
                    <img src="../../assets/image/shipToCar.png" alt="" />
                    <p>{{summarydata.val5?summarydata.val5:'-'}}<span>吨</span></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="Production-top-echart-right">
            <div class="Production-port-date">
              <p class="todayData">
                <span>船舶</span>计划及各货物流向装卸量<span
                  >Production Data of This Month</span
                >
              </p>
              <div class="Production-port-date-num">
                <div class="Production-portBox_1">
                  <p>计划总装卸量</p>
                  <p>{{summarydata.val6?summarydata.val6:'-'}}<span>吨</span></p>
                </div>
                <div class="Production-portBox">
                  <div>
                    <img src="../../assets/image/img_3.png" alt="" />
                    <p>{{summarydata.val7?summarydata.val7:'-'}}<span>吨</span></p>
                  </div>
                  <div>
                    <img src="../../assets/image/img_1.png" alt="" />
                    <p>{{summarydata.val8?summarydata.val8:'-'}}<span>吨</span></p>
                  </div>
                </div>
                <div class="Production-portBox">
                  <div>
                    <img src="../../assets/image/img_4.png" alt="" />
                    <p>{{summarydata.val9?summarydata.val9:'-'}}<span>吨</span></p>
                  </div>
                  <div>
                    <img src="../../assets/image/img_2.png" alt="" />
                    <p>{{summarydata.val10?summarydata.val10:'-'}}<span>吨</span></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="Production-bottom">
          <div class="Production-bottom-table">
            <div class="Production-bottom-table-cont">
              <div class="table-box" id="table-box">
                <a-table
                  :columns="columns"
                  :data-source="columnsData"
                  :pagination="false"
                  :scroll="{x:500, y: tableHeight }"
                  style="color: #000; overflow-x: hidden"
                  :locale="locale"
                >
                  <template slot="inOrOut" slot-scope="text">
                    <span :style="`color: ${text === '卸货' ? '#FF5C2C' : '#00B994'};`">{{ text }}</span>
                  </template>
                  <template slot="optType" slot-scope="text">
                    <img style="width:57px; height:24px;" :src="imgList[text]" alt="">
                  </template>
                </a-table>
              </div>
            </div>
          </div>
          <div class="Production-bottom-table">
            <div class="Production-bottom-table-cont">
              <div class="table-box" id="table-box">
                <a-table
                  :columns="shipcolumns"
                  :data-source="shipcolumnsData"
                  :pagination="false"
                  :scroll="{x:500, y: tableHeight }"
                  style="color: #000; overflow-x: hidden"
                  :locale="locale"
                >
                   <template slot="inOrOut" slot-scope="text">
                    <span :style="`color: ${text === '卸货' ? '#FF5C2C' : '#00B994'};`">{{ text }}</span>
                  </template>
                   <template slot="optType" slot-scope="text">
                    <img style="width:57px; height:24px;" :src="shipimgList[text]" alt="">
                  </template>
                </a-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const options = [
  {
    code: "wf",
    name: "万丰港区",
  },
];
const columns = [
  {
    title: "",
    dataIndex: "id",
    width: 50,
    fixd:'left'
  },

  {
    title: "车牌号",
    dataIndex: "plateNumber",
    width: 88,
    fixd:'left'
  },
  {
    title: "离港时间",
    dataIndex: "actualTime",
    width: 100,
  },
  {
    title: "是否自卸",
    dataIndex: "isSelfService",
    width: 80,
  },
  {
    title: "货量(kg)",
    dataIndex: "goodsKg",
    width: 80,
  },
  {
    title: "装卸货",
    dataIndex: "inOrOut",
    width: 80,
    scopedSlots: { customRender: 'inOrOut' },
    
  },
  {
    title: "装卸车辆",
    dataIndex: "assignVehicles",
    width: 90,
  },
  {
    title: "堆场",
    dataIndex: "storageName",
    width: 100,
  },
    {
    title: "班组",
    dataIndex: "teamName",
    width: 100,
  },
    {
    title: "货物流向",
    dataIndex: "optType",
    width: 100,
    scopedSlots: { customRender: 'optType' },

  },

];

import { getVehicleList ,getshipList,getSummary} from "../../http/index.js";
import screenfull from 'screenfull' // 全屏功能
export default {
  name: "Production",
  data() {
    return {
      locale:{
        emptyText: '暂无数据'
      },
      isFullscreen: false,
      imgList: {
        // chebangchuan("车磅船", "1"), 
        // changbangche("场磅车", "2"), 
        // chuanbangche("船磅车", "3"), 
        // chebangchang("车磅场", "4"), 
        // changbangchuan("场磅船", "5"), 
        // chuanbangchang("船磅场","6");
        1: require('../../assets/image/toShip.png'),
        2: require('../../assets/image/shipPort.png'),
        3: require('../../assets/image/ship.png'),
        4: require('../../assets/image/tocar.png'),
        5: require('../../assets/image/shipPort.png'),
        6: require('../../assets/image/tocar.png'),

        // 1: require('../../assets/image/carToShip.png'),
        // 2: require('../../assets/image/portToCar.png'),
        // 3: require('../../assets/image/shipToCar.png'),
        // 4: require('../../assets/image/carToPort.png'),
        // 5: require('../../assets/image/img_1.png'),
        // 6: require('../../assets/image/img_3.png'),

      },
      shipimgList:{
        1: require('../../assets/image/carTo.png'),
        2: require('../../assets/image/shipPort.png'),
        3: require('../../assets/image/toCar_1.png'),
        4: require('../../assets/image/tocar.png'),
        5: require('../../assets/image/shipPort.png'),
        6: require('../../assets/image/tocar.png'),
      },
      columnsData:[],
      shipcolumnsData:[],
      active: "0",
      actives: "0",
      columns,
      options,
      tableHeight: 0,
      summarydata:[],
      shipcolumns : [
      {
        title: "",
        dataIndex: "id",
        width: 50,
        fixd:'left'
      },
      {
        title: "船名",
        dataIndex: "plateNumber",
        width: 100,
        // fixed: 'left',
      },
      {
        title: "到港时间",
        dataIndex: "actualTime",
        width: 100,
      },
      {
        title: "停靠泊位",
        dataIndex: "berthName",
        width: 100,
      },
      {
        title: "货量(t)",
        dataIndex: "goodsKg",
        width: 100,
      },
      {
        title: "装卸货",
        dataIndex: "inOrOut",
        width: 80,
        scopedSlots: { customRender: 'inOrOut' },

      },
      {
        title: "装卸设备",
        dataIndex: "deviceName",
        width: 100,
      },
        {
        title: "班组",
        dataIndex: "teamName",
        width: 100,
      },
        {
        title: "货物流向",
        dataIndex: "optType",
        width: 100,
        scopedSlots: { customRender: 'optType' },

      },

      ],
      intervalId:null,
      searchData:''
    };
  },
  created() {
    this.searchData = {apikey:'Sw4Mi3MdjII=',portName:'wf'}
    this.getDataList();
    this.dataRefreh();
  },
  mounted() {
    this.$nextTick(() => {
      this.initHeight();
      this.init()
      this.isFullscreen=screenfull.isFullscreen
    });

     window.addEventListener('keydown', this.KeyDown, true)// 监听按键事件
  },

  methods: {
    KeyDown(event) {
      if (event.keyCode === 122) {
        // 禁用f11
        event.returnValue = false
        // 触发全屏的按钮
        this.f11Click()
      }
    },
    f11Click() {
      if (!screenfull.isEnabled) {
        this.$message({
          message: '浏览器不能全屏',
          type: 'warning'
        })
        return false
      }
      screenfull.toggle()
    },
    change() {
      this.isFullscreen = screenfull.isFullscreen
    },
    init() {
      if (screenfull.isEnabled) {
        screenfull.on('change', this.change) // ！！！！这儿方法名不能带括号
      }
    },
    destroy() {
      if (screenfull.isEnabled) {
        screenfull.off('change', this.change) // ！！！！这儿方法名不能带括号
      }
    },
     // 返回大屏
    goback(){
      console.log('qq')
      this.$router.push({
        path:'/MonitoringCenter'
      })
    },
    golast(){
      console.log('qq')
      this.$router.push({
        path:'/GoodsCenter'
      })
    },
    gonext(){
      console.log('qq')
      this.$router.push({
        path:'/WbCenter'
      })
    },
        // 定时刷新数据函数
    dataRefreh() {
      // 计时器正在进行中，退出函数
      if (this.intervalId !== null) {
        return;
      }
      // 计时器为空，操作
      this.intervalId = setInterval(() => {
        this.getDataList(); //加载数据函数
      }, 60000*5);
    },
    // 停止定时器
    clear() {
      this.intervalId && clearInterval(this.intervalId); //清除计时器
    },
    initHeight() {
      const tableBox = document.getElementById("table-box");
      const height = tableBox.offsetHeight;
      console.log(height,'ssssssss');
      this.tableHeight = height;
    },
        // 港区选择
    onChange(value) {
      if(value.toString() == 'jz'){
        this.searchData = {apikey:'Sw4Mi3MdjII=',portName:'jz'}
        this.getDataList(this.searchData)
        // alert('济州港区数据正在接通中......')
      }else{
        this.searchData = {apikey:'Sw4Mi3MdjII=',portName:'wf'}
        this.getDataList(this.searchData)
      }
    }, 
    getDataList(){
      getVehicleList(this.searchData).then(res=>{
        this.columnsData = res
      })
      getshipList(this.searchData).then(res=>{
        this.shipcolumnsData = res
      })
      getSummary(this.searchData).then(res=>{
        this.summarydata = res
      })
    },
 
  },
  beforeDestroy() {
    // 在页面销毁后，清除计时器
    this.clear();
    this.destroy()
  },
};
</script>

<style scoped lang="less">
@import "./index.less";
</style>


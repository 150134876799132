<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App"
};
</script>

<style lang="less">
/* #app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */

* {
  padding: 0;
  margin: 0;
}
html,
body {
  width: 100%;
  height: 100%;
}
body {
  overflow: hidden;
}
#app {
  width: 100%;
  height: 100%;
}
.ant-cascader-picker-label{
  font-size: 20px !important;
}
.anticon svg {
    color: #fff;
    display: inline-block;
}
@font-face{
  font-family: 'PingFang SC';
  src: '../static/PingFangHK-Semibold.otf';
}
@font-face{
  font-family: 'pangmen';
  src: '../static/PangMenZhengDaoBiaoTiTi-1.ttf';
}
/* p,span,div,input,h1,h2,h3{
  font-family: 'PingFangHK';
} */
p{
  margin-bottom: 0px !important ;
}
.ant-cascader-input.ant-input {
  position: static;
  width: 100%;
  padding-right: 24px;
  background-color: transparent !important;
  cursor: pointer;
  border: none !important;
  height: 100%;
  font-size: 20px;
  text-align: center;
  color: #ffffff;
}
* {
  scrollbar-color: #737372 #f7f7f9; /* 滑块颜色  滚动条背景颜色 */
  scrollbar-width: thin; /* 滚动条宽度有三种：thin、auto、none */
}
.ant-table-body{
    &::-webkit-scrollbar {
      width: 1px;
      height: 5px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.2);
      background: #737372;
    }
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: 0;
      border-radius: 0;
      background: #191f28;
    }
    &::-moz-scrollbar {
      width: 1px !important;
      height: 5px;
    }
    &::-moz-scrollbar-thumb {
      border-radius: 5px;
      -moz-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.2);
      background: #737372;
    }
    &::-moz-scrollbar-track {
      -moz-box-shadow: 0;
      border-radius: 0;
      background: #191f28;
    }
  }
.ant-table table {
  width: 100%;
  font-size: 10px !important;
  text-align: left;
  border-radius: 4px 4px 0 0;
  border-collapse: separate;
  border-spacing: 0;
  color: #ffffff !important;
  background: rgba(46, 47, 48, 0.99) !important;
}
.ant-table-thead > tr > th{
  background: #000 !important;
  color: #ffffff !important;
}

.ant-table-tbody > tr > td {
  padding: 16px 6px;
  overflow-wrap: break-word;
  background: #212121 !important;
  color: #ffffff;
}
.ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
    background: #666e72 !important ;
}
.ant-table-fixed-header .ant-table-scroll .ant-table-header {
    margin-bottom: -27px;
    background: #000 !important;
}
.ant-table-bordered .ant-table-header > table, 
.ant-table-bordered .ant-table-body > table, 
.ant-table-bordered .ant-table-fixed-left table, 
.ant-table-bordered .ant-table-fixed-right table{
  border-left: none !important;
}

.ant-table-bordered {
  .ant-table-thead > tr > th:last-child{
    border-right: none !important;
  }
  .ant-table-tbody > tr > td:last-child{
    border-right: none !important;
  }
}

.ant-table-tbody > tr:last-child > td{
  border-bottom: none !important;
}
// 表格数据占位符
.ant-table-placeholder {
    position: relative;
    z-index: 1;
    margin-top: -1px;
    padding: 16px 16px;
    color: rgba(255, 255, 255, 0.5) !important;
    // font-size: 14px;
    // text-align: center;
    background: rgba(0, 0, 0) !important;
    // border-top: none !important;
    border-top: 1px solid rgba(255, 255, 255, 0.3) !important;
    border-bottom: none !important;
    // padding: 0 !important;
    // border-radius: 0 0 4px 4px;
}
.ant-cascader-menus{
  width: 150px;
  background: rgba(255, 255, 255, 0.2) !important;
  box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
  color: #fff !important;
}
.ant-cascader-menu:only-child{
  width: 100%;
  height:auto
}
.ant-cascader-menu-item{
  padding: 10px 12px;
  text-align: center;
}
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled), .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover{
  background: rgba(255, 255, 255, 0.3) !important;
}
</style>

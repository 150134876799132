<template>
  <div id="sale-cont">
    <div class="sale-cont-box">
      <div class="sale_bg"></div>
      <div class="sale">
        <div class="sale-title">
          <div class="sale-title-left">
            <a-cascader
              class="sale-chose"
              :field-names="{ label: 'name', value: 'code', children: 'items' }"
              :options="options"
              :allowClear="false"
              placeholder="万丰港区"
              @change="onChange"
            />
          </div>
          <div class="sale-title-center">
            <img src="../../assets/image/left.png" alt="" @click="golast()"/>
            <img src="../../assets/image/sale_title.png" alt="" />
            <img src="../../assets/image/right.png" alt="" @click="gonext()"/>
          </div>
         <div class="MonitoringCenter-title-right">
            <div class="MonitoringCenter-title-right-button1">
              <img src="../../assets/image/back.png" alt="" @click="goback()"/>
            </div>
            <div class="MonitoringCenter-title-right-button1">
               <img src="../../assets/image/fullScreen.png" alt=""  v-if="!isFullscreen"  @click="f11Click" />
               <img src="../../assets/image/fullScreenUn.png" alt=""  v-else  @click="f11Click" />
            </div>
          </div>
        </div>
        <div class="sale-top-echart">
          <div class="sale-top-echart-left">
            <div class="sale-port-date">
              <p>当前销售情况<span>Current Sales</span></p>
              <div class="sale-port-date-num" >
                <div class="table-box" id="lefttable" style="width:100%">
                  <a-table
                    :columns="topcolumnleft"
                    :data-source="currentSalesData.list1"
                    :pagination="false"
                    :scroll="{ y: this.lefttableheight}"
                     :rowKey="(record,index)=>{return index}"
                    :locale="locale"
                    :cell-style="{ 'text-align': 'center' }" 
                    :header-cell-style="{ 'text-align': 'center' }"
                  />
                </div>
                <!-- <div class="table-box" id="righttable">
                  <a-table
                    v-if="currentSalesData.list2.length>0"
                    :columns="topcolumnright"
                    :data-source="currentSalesData.list2"
                    :pagination="false"
                    :scroll="{ y: this.righttableheight }"
                    :showHeader='false'
                    :locale="locale"
                  />
                  <div v-else class="elsetext">暂无数据</div>
                </div> -->
                
              </div>
            </div>
            
          </div>
          <div class="sale-top-echart-center">
            <div class="sale-center-port-date">
              <p class="title">
               <span></span> 销售数据<span style="font-size: 16px;"> Sales Data</span>
              </p>
              <div class="sale-center-port-date-num">
                <div>
                  <p><span>本月</span>销售货物量（万吨）</p>
                  <p>{{MonthSalesData.val1 ? MonthSalesData.val1 :'-'}}</p>
                </div>
                <div>
                  <p>累计销售货物量（万吨）</p>
                  <p>{{MonthSalesData.val2?MonthSalesData.val2:'-'}}</p>
                </div>
                <div>
                  <p>累计客户量</p>
                  <p>{{MonthSalesData.val3?MonthSalesData.val3:'-'}}</p>
                </div>
              </div>
            </div>
            <div class="sale-bottom-center-top">
              <span class="title"
                >各个销售货物流向<span>计划完成</span>情况(吨)</span
              >
              <span class="spanbox">
                <span
                  v-for="(item, i) in datalist"
                  :key="i"
                  :class="{ active: active == i }"
                  @click="choseTime(item, i)"
                >
                  {{ item }}
                </span>
              </span>
              <div class="tipOutBox">
                <div class="leftTipbox">
                  <div class="tipbox" ref="barchart" id="Box2"></div>
                  <p>{{this.TargetStatusData[0]?this.TargetStatusData[0].val1:'-'}}</p>
                  <p>{{this.TargetStatusData[0]?this.TargetStatusData[0].val3:'-'}}</p>
                </div>
                <div class="rightTipbox">
                  <div class="single">
                    <div class="lefttpie" ref="rightpie_1"></div>
                    <div class="rightpie">
                      <p>{{this.TargetStatusData[1]?this.TargetStatusData[1].val1:'-'}}</p>
                      <p>{{this.TargetStatusData[1]?this.TargetStatusData[1].val3:'-'}}</p>
                    </div>
                  </div>
                  <div class="single">
                    <div class="lefttpie" ref="rightpie_2"></div>
                    <div class="rightpie">
                      <p>{{this.TargetStatusData[2]?this.TargetStatusData[2].val1:'-'}}</p>
                      <p>{{this.TargetStatusData[2]?this.TargetStatusData[2].val3:'-'}}</p>
                    </div>
                  </div>
                  <div class="single">
                    <div class="lefttpie" ref="rightpie_3"></div>
                    <div class="rightpie">
                      <p>{{this.TargetStatusData[3]?this.TargetStatusData[3].val1:'-'}}</p>
                      <p>{{this.TargetStatusData[3]?this.TargetStatusData[3].val3:'-'}}</p>
                     </div>
                  </div>
                  <div class="single">
                    <div class="lefttpie" ref="rightpie_4"></div>
                    <div class="rightpie">
                      <p>{{this.TargetStatusData[4]?this.TargetStatusData[4].val1:'-'}}</p>
                      <p>{{this.TargetStatusData[4]?this.TargetStatusData[4].val3:'-'}}</p>
                    </div>
                  </div>
                  <div class="single">
                    <div class="lefttpie" ref="rightpie_5"></div>
                    <div class="rightpie">
                      <p>{{this.TargetStatusData[5]?this.TargetStatusData[5].val1:'-'}}</p>
                      <p>{{this.TargetStatusData[5]?this.TargetStatusData[5].val3:'-'}}</p>
                     </div>
                  </div>
                  <div class="single">
                    <div class="lefttpie" ref="rightpie_6"></div>
                    <div class="rightpie">
                      <p>{{this.TargetStatusData[6]?this.TargetStatusData[6].val1:'-'}}</p>
                      <p>{{this.TargetStatusData[6]?this.TargetStatusData[6].val3:'-'}}</p>
                     </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="sale-top-echart-right">
            <div class="sale-port-date">
              <p>
                <span></span>客户数据<span>Customer Data</span
                >
              </p>
              <div class="sale-port-date-num">
                <div>
                  <p><span style="color:#FF5C2C">本月销售量最大</span></p>
                  <p>{{thisMonthCustomerData.val1 ? thisMonthCustomerData.val1 : '-'}}</p>
                </div>
                <div>
                  <p>本月<span>最大气</span>客户</p>
                  <p>{{thisMonthCustomerData.val2?thisMonthCustomerData.val2:'-'}}</p>
                </div>
              </div>
            </div>
            <div class="sale-bottom-top">
              <span class="title">客户按销售<span>货物量</span>排名</span>
              <span class="spanbox">
                <span
                  v-for="(item, i) in datalist2"
                  :key="i"
                  :class="{ active: active2 == i }"
                  @click="choseTime2(item, i)"
                >
                  {{ item }}
                </span>
              </span>
              <img src="../../assets/image/winner.png" alt="" />
              <div class="saleranking-top">
                <div
                  class="sale-list"
                  v-for="(item, i) in CustomerRankByGoodsData"
                  :key="i"
                >
                  <span>{{ item.val1 }}</span>
                  <span>{{ item.val2 }}</span>
                  <span>{{ item.val3 }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="sale-bottom">
          <div class="sale-bottom-table">
            <div class="sale-bottom-table-cont">
              <p>销售进度监控</p>
              <div class="table-box" id="tableBox">
                <a-table
                  :columns="ProgressListcolumns"
                  :data-source="ProgressListData"
                  :pagination="false"
                  :scroll="{ y: tableHeight }"
                  :locale="locale"
                   :rowKey="(record,index)=>{return index}"
                >
                   <template slot="val6" slot-scope="text">
                    <span :style="`color: ${text === '进行中' ? '#FF5C2C' :text === '已结束' ? 'rgba(255, 255, 255, 0.5)': '#ffffff'};`">{{ text }}</span>
                  </template>
                </a-table>
              </div>
            </div>
          </div>
          <div class="sale-bottom-center">
            
            <div class="sale-bottom-center-bottom">
              <span class="title">销售<span>货物量</span>变化(吨)</span>
              <span class="spanbox">
                <span
                  v-for="(item, i) in datalist1"
                  :key="i"
                  :class="{ active: active1 == i }"
                  @click="choseTime1(item, i)"
                >
                  {{ item }}
                </span>
              </span>
              <div ref="salebarchart" style="width: 100%; height: 100%"></div>
            </div>
          </div>
          <div class="sale-bottom-data">
            
            <div class="sale-bottom-bottom">
              <span class="title">客户按<span>销售单量</span>排名</span>
              <span class="spanbox">
                <span
                  v-for="(item, i) in datalist3"
                  :key="i"
                  :class="{ active: active3 == i }"
                  @click="choseTime3(item, i)"
                >
                  {{ item }}
                </span>
              </span>
              <img src="../../assets/image/winner.png" alt="" />

              <div class="saleranking-top">
                <div
                  class="sale-list"
                  v-for="(item, i) in CustomerRankByOrdersData"
                  :key="i"
                >
                  <span>{{ item.val1 }}</span>
                  <span>{{ item.val2 }}</span>
                  <span>{{ item.val3 }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const options = [
  {
    code: "wf",
    name: "万丰港区",
  },
];
import {
// 本月销售数据
getThisMonthSales,
// 当前销售情况接口
getCurrentSales,
// 本月客户数据
getThisMonthCustomer,
// 销售进度监控
getProgressList,
// 销售指标达成
getTargetStatus,
// 客户按销售货物量排名
getCustomerRankByGoods,
// 客户按销售单量排名
getCustomerRankByOrders,
// 销售货物量变化图表
getGoodsAmountChart
} from '../../http/index'
import screenfull from 'screenfull' // 全屏功能
export default {
  name: "sale",
  data() {
    return {
      locale:{
        emptyText: '暂无数据'
      },
      isFullscreen: false,
      active: "1",
      active1: "0",
      active2: "0",
      active3: "0",
      ProgressListcolumns:[
        {
          title:'合同时间',
          dataIndex:'val1',
          width: 100
        },
         {
          title:'货物流向',
          dataIndex:'val2',
          width: 80
        },{
          title:'货名',
          dataIndex:'val3',
          width: 60
        },{
          title:'货量(t)',
          dataIndex:'val4',
          width: 80
        },{
          title:'客户',
          dataIndex:'val5',
          width: 60
        },{
          title:'合同状态',
          dataIndex:'val6',
          width: 60,
          scopedSlots: { customRender: 'inOrOut' },
        }
      ],
      topcolumnleft:[
         {
          title:'客户',
          dataIndex:'val1',
          width: 50
         }, {
           title:'货名',
          dataIndex:'val2',
          width: 50
         }, {
          title:'计划作业量(吨)',
          dataIndex:'val3',
          width: 50
         },
      ],
      topcolumnright:[
         {
        
          dataIndex:'val1',
          width: 50
         }, {
         
          dataIndex:'val2',
          width: 50
         }, {
        
          dataIndex:'val3',
          width: 50
         },
      ],
      options,
      datalist: ["日", "月", "年"],
      datalist1: ["月", "年"],
      datalist2: ["月", "年"],
      datalist3: ["月", "年"],
      photo: [{ id: 1, img: require("../../assets/image/img.jpg") }],
      tableHeight: 0,
      lefttableheight:0,
      righttableheight:0,
      params:'',
      portName:'',
      MonthSalesData:'',
      currentSalesData:[],
      thisMonthCustomerData:'',
      ProgressListData:[],
      TargetStatusData:[],
      CustomerRankByGoodsData:'',
      goodsType:'',
      CustomerRankByOrdersData:'',
      saleType:'',
      amountType:'',
      GoodsAmountChartDataX:'',
      GoodsAmountChartDataY:'',
      intervalId: null,
    };
  },
  created() {
    this.params = {
      apikey:'Sw4Mi3MdjII=',
      portName:'wf'
    }
    this.initData()
    
    this.dataRefreh();
  },
  mounted() {
    this.init()
    this.isFullscreen=screenfull.isFullscreen
    this.$nextTick(() => {
      this.initHeight();
    });
    window.addEventListener('keydown', this.KeyDown, true)// 监听按键事件
    
  },
  beforeDestroy() {
    // 在页面销毁后，清除计时器
    this.clear();
    this.destroy()
  },
  methods: {
    KeyDown(event) {
      if (event.keyCode === 122) {
        // 禁用f11
        event.returnValue = false
        // 触发全屏的按钮
        this.f11Click()
      }
    },
    f11Click() {
      if (!screenfull.isEnabled) {
        this.$message({
          message: '浏览器不能全屏',
          type: 'warning'
        })
        return false
      }
      screenfull.toggle()
    },
    change() {
      this.isFullscreen = screenfull.isFullscreen
    },
    init() {
      if (screenfull.isEnabled) {
        screenfull.on('change', this.change) // ！！！！这儿方法名不能带括号
      }
    },
    destroy() {
      if (screenfull.isEnabled) {
        screenfull.off('change', this.change) // ！！！！这儿方法名不能带括号
      }
    },
     // 返回大屏
    goback(){
      this.$router.push({
        path:'/MonitoringCenter'
      })
    },
    golast(){
      console.log('qq')
      this.$router.push({
        path:'/Equipment'
      })
    },
    gonext(){
      console.log('qq')
      this.$router.push({
        path:'/GoodsCenter'
      })
    },
    initHeight() {

      const tableBox = document.getElementById("tableBox");
      console.log(tableBox.offsetHeight,'tableBoxtableBoxtableBox')
      if(tableBox){
        const height = tableBox.offsetHeight+40 ;
        this.tableHeight = height;
      }else{
        if(this.isFullscreen){
          this.tableHeight=300
        }else{
          this.tableHeight=200
        }
        
      }
      
      const leftable = document.getElementById("lefttable");
      console.log(leftable.offsetHeight,'leftableleftableleftable')
      if(leftable){
        const leftheight = leftable.offsetHeight +300;
        this.lefttableheight = leftheight;
      }else{
        this.lefttableheight=360
      }
      
      console.log(leftheight,'左边表格高度');


    },
    // 定时刷新数据函数
    dataRefreh() {
      // 计时器正在进行中，退出函数
      if (this.intervalId !== null) {
        return;
      }
      // 计时器为空，操作
      this.intervalId = setInterval(() => {
       this.initData()  
      }, 60000*5);  
    },
    // 停止定时器
    clear() {
      this.intervalId && clearInterval(this.intervalId); //清除计时器
    },
    // 年月日选择
    choseTime(item, i) {
      this.active = i;
      this.dateType = i + 1
      this.getTargetStatusList()
    },
    // 销售货物量年月选择
    choseTime1(item, i) {
      this.active1 = i;
      this.amountType = i + 2
      this.getGoodsAmountChartlist()
    },
    // 客户按销售货物排名
    choseTime2(item, i) {
      this.active2 = i;
      this.goodsType = i + 2
      this.getCustomerRankByGoodslist()
     
    },
    // 客户按销售单量排名
    choseTime3(item, i) {
      this.active3 = i;
      this.saleType = i + 2
      this.getCustomerRankByOrderslist()
    },
    // 港区选择
    onChange(value) {
      console.log(value);
      this.portName = value
      if(value.toString() === 'wf'){
        this.params = {
          apikey:'Sw4Mi3MdjII=',
          portName:'wf'
        }
        this.initData()
        // getDeviceStatus(this.params)
      }else{
        // alert('济州港区数据正在接通中......')
         this.params = {
          apikey:'Sw4Mi3MdjII=',
          portName:'jz'
        }
        this.initData()
      }
    },
    //销售指标达成接口
    getTargetStatusList(){
      getTargetStatus({...this.params,dateType:this.dateType?this.dateType:2}).then((res)=>{
        this.TargetStatusData = res
        setTimeout(()=>{
          this.getbarData();
          this.getRightChartData_1();
          this.getRightChartData_2();
          this.getRightChartData_3();
          this.getRightChartData_4();
          this.getRightChartData_5();
          this.getRightChartData_6();
        },1000)
      })
    },
    // 初始化数据
    initData(){
      this.getCustomerRankByGoodslist()
      this.getCustomerRankByOrderslist()
      this.getGoodsAmountChartlist()
      this.getTargetStatusList()
      this.getDetails()
    },
    // 客户按销售货物量排名
    getCustomerRankByGoodslist(){
      getCustomerRankByGoods({...this.params,dateType:this.goodsType || '2'}).then((res)=>{
        this.CustomerRankByGoodsData = res
      })
    },
    // 客户按销售单量排名
    getCustomerRankByOrderslist(){
      getCustomerRankByOrders({...this.params,dateType:this.saleType || '2'}).then((res)=>{
        this.CustomerRankByOrdersData = res
      })
    },
    // 销售货物量变化图表
    getGoodsAmountChartlist(){
      getGoodsAmountChart({...this.params,dateType:this.amountType || '2'}).then((res)=>{
        this.GoodsAmountChartDataX = res.dataX
        this.GoodsAmountChartDataY = res.dataY
        setTimeout(()=>{
          this.getbottombarData()
        },1000)
      })
    },
    getDetails(){
      getThisMonthSales(this.params).then((res)=>{
        this.MonthSalesData = res
      })
      getCurrentSales(this.params).then((res)=>{
        this.currentSalesData = res
      })
      getThisMonthCustomer(this.params).then((res)=>{
        this.thisMonthCustomerData = res
      })
      getProgressList(this.params).then((res)=>{
        this.ProgressListData = res
      })
    },
    getbottombarData() {
      const chart = this.$refs.salebarchart;
      if (chart) {
        const myChart = this.$echarts.init(chart);
        const option = {
          xAxis: {
            type: "category",
              data:this.GoodsAmountChartDataX,
          },
          yAxis: {
             type: 'value',

          },
          grid: {
            top: "10%",
            left: "0%",
            right: "0%",
            bottom: "7%",
            containLabel: true,
          },

          series: [
            {
              data: this.GoodsAmountChartDataY,
              type: "bar",
              itemStyle: {
              normal: {
                color: {
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "#00D6BA", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "#77FFC1", // 100% 处的颜色
                    },
                  ],
                  globalCoord: false, // 缺省为 false
                },
              },
            },
            },
          ],
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
              label: {
                backgroundColor: "#6a7985",
              },
            },
          },
        };
        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
        });
      }
      this.$on("hook:destroyed", () => {
        window.removeEventListener("resize", function () {
          myChart.resize();
        });
      });
    },
    getbarData() {
      const chart = this.$refs.barchart;
      if (chart) {
        const myChart = this.$echarts.init(chart);
        const option = {
          tooltip: {
            trigger: "item",
          },
          title: {
            text: "总装卸量",
            left: "400",
            bottom: "0",
          },
          grid: {
            top: "0%",
            left: "0%",
            right: "0%",
            bottom: "18%",
            containLabel: true,
          },
          color:['#FF5C2C','#999'],
          graphic:{
            type:"text",
            left:"center",
            top:"40%",
            style:{
                text:this.TargetStatusData[0].val2+'%',
                textAlign:"center",
                fill:"#fff",
                fontSize:16,
                fontWeight:700
            }
          },
          series: [
            {
              name: "总装卸量",
              type: "pie",
              radius: ["100%", "70%"],
              label: {
                show: false,
                position: "center",
              },
              data: [
                { value: this.TargetStatusData[0].val2 },
                { value: 100-this.TargetStatusData[0].val2 },
              ],
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)",
                },
              },
            },
          ],
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
              label: {
                backgroundColor: "#6a7985",
              },
            },
          },
        };
        
        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
        });
      }
      this.$on("hook:destroyed", () => {
        window.removeEventListener("resize", function () {
          myChart.resize();
        });
      });
    },
    getRightChartData_1() {
      const chart = this.$refs.rightpie_1;
      if (chart) {
        const myChart = this.$echarts.init(chart);
        const option = {
          tooltip: {
            trigger: "item",
          },
          grid: {
            top: "0%",
            left: "0%",
            right: "0%",
            bottom: "18%",
            containLabel: true,
          },
          color:['#FF5C2C','#999'],
           graphic:{
            type:"text",
            left:"center",
            top:"45%",
            style:{
                text:this.TargetStatusData[1].val2?this.TargetStatusData[1].val2+'%':'',
                textAlign:"center",
                fill:"#fff",
                fontSize:12,
                fontWeight:700
            }
          },
          series: [
            {
              name: "场磅船",
              type: "pie",
              radius: ["100%", "70%"],
              label: {
                show: false,
                position: "center",
              },
              data: [
                { value: this.TargetStatusData[1].val2?this.TargetStatusData[1].val2:'' },
                { value: 100-this.TargetStatusData[1].val2 },
              ],
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)",
                },
              },
            },
          ],
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
              label: {
                backgroundColor: "#6a7985",
              },
            },
          },
        };
        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
        });
      }
      this.$on("hook:destroyed", () => {
        window.removeEventListener("resize", function () {
          myChart.resize();
        });
      });
    },
    getRightChartData_2() {
      const chart = this.$refs.rightpie_2;
      if (chart) {
        const myChart = this.$echarts.init(chart);
        const option = {
          tooltip: {
            trigger: "item",
          },
          title: {
            text: "总装卸量",
            left: "400",
            bottom: "0",
          },
          grid: {
            top: "0%",
            left: "0%",
            right: "0%",
            bottom: "18%",
            containLabel: true,
          },
          color:['#FF5C2C','#999'],
          graphic:{
            type:"text",
            left:"center",
            top:"45%",
            style:{
                text:this.TargetStatusData[2].val2+'%'|| '',
                textAlign:"center",
                fill:"#fff",
                fontSize:12,
                fontWeight:700
            }
          },
          series: [
            {
              name: "船磅场",
              type: "pie",
              radius: ["100%", "70%"],
              label: {
                show: false,
                position: "center",
              },
              data: [
               { value: this.TargetStatusData[2].val2?this.TargetStatusData[2].val2:''},
               { value: 100- this.TargetStatusData[2].val2 },
              ],
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)",
                },
              },
            },
          ],
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
              label: {
                backgroundColor: "#6a7985",
              },
            },
          },
        };
        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
        });
      }
      this.$on("hook:destroyed", () => {
        window.removeEventListener("resize", function () {
          myChart.resize();
        });
      });
    },
    getRightChartData_3() {
      const chart = this.$refs.rightpie_3;
      if (chart) {
        const myChart = this.$echarts.init(chart);
        const option = {
          tooltip: {
            trigger: "item",
          },
          title: {
            text: "总装卸量",
            left: "400",
            bottom: "0",
          },
          grid: {
            top: "0%",
            left: "0%",
            right: "0%",
            bottom: "18%",
            containLabel: true,
          },
          color:['#FF5C2C','#999'],
           graphic:{
            type:"text",
            left:"center",
            top:"45%",
            style:{
                text:this.TargetStatusData[3].val2?this.TargetStatusData[3].val2+'%':'',
                textAlign:"center",
                fill:"#fff",
                fontSize:12,
                fontWeight:700
            }
          },
          series: [
            {
              name: "车磅场",
              type: "pie",
              radius: ["100%", "70%"],
              label: {
                show: false,
                position: "center",
              },
              data: [
                { value: this.TargetStatusData[3].val2?this.TargetStatusData[3].val2:''},
                { value: 100-this.TargetStatusData[3].val2 },
              ],
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)",
                },
              },
            },
          ],
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
              label: {
                backgroundColor: "#6a7985",
              },
            },
          },
        };
        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
        });
      }
      this.$on("hook:destroyed", () => {
        window.removeEventListener("resize", function () {
          myChart.resize();
        });
      });
    },
    getRightChartData_4() {
      const chart = this.$refs.rightpie_4;
      if (chart) {
        const myChart = this.$echarts.init(chart);
        const option = {
          tooltip: {
            trigger: "item",
          },
          title: {
            text: "总装卸量",
            left: "400",
            bottom: "0",
          },
          grid: {
            top: "0%",
            left: "0%",
            right: "0%",
            bottom: "18%",
            containLabel: true,
          },
          color:['#FF5C2C','#999'],
           graphic:{
            type:"text",
            left:"center",
            top:"45%",
            style:{
                text:this.TargetStatusData[4].val2?this.TargetStatusData[4].val2+'%':'',
                textAlign:"center",
                fill:"#fff",
                fontSize:12,
                fontWeight:700
            }
          },
          series: [
            {
              name: "车磅船",
              type: "pie",
              radius: ["100%", "70%"],
              label: {
                show: false,
                position: "center",
              },
              data: [
                 { value: this.TargetStatusData[4].val2?this.TargetStatusData[4].val2:''},
                { value: 100-this.TargetStatusData[4].val2 },
              ],
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)",
                },
              },
            },
          ],
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
              label: {
                backgroundColor: "#6a7985",
              },
            },
          },
        };
        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
        });
      }
      this.$on("hook:destroyed", () => {
        window.removeEventListener("resize", function () {
          myChart.resize();
        });
      });
    },
    getRightChartData_5() {
      const chart = this.$refs.rightpie_5;
      if (chart) {
        const myChart = this.$echarts.init(chart);
        const option = {
          tooltip: {
            trigger: "item",
          },
          title: {
            text: "总装卸量",
            left: "400",
            bottom: "0",
          },
          grid: {
            top: "0%",
            left: "0%",
            right: "0%",
            bottom: "18%",
            containLabel: true,
          },
          color:['#FF5C2C','#999'],
           graphic:{
            type:"text",
            left:"center",
            top:"45%",
            style:{
                text:this.TargetStatusData[5].val2?this.TargetStatusData[5].val2+'%':'',
                textAlign:"center",
                fill:"#fff",
                fontSize:12,
                fontWeight:700
            }
          },
          series: [
            {
              name: "Access From",
              type: "pie",
              radius: ["100%", "70%"],
              label: {
                show: false,
                position: "center",
              },
              data: [
                { value: this.TargetStatusData[5].val2?this.TargetStatusData[5].val2:''},
                { value: 100-this.TargetStatusData[5].val2 },
              ],
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)",
                },
              },
            },
          ],
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
              label: {
                backgroundColor: "#6a7985",
              },
            },
          },
        };
        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
        });
      }
      this.$on("hook:destroyed", () => {
        window.removeEventListener("resize", function () {
          myChart.resize();
        });
      });
    },
    getRightChartData_6() {
      console.log(this.TargetStatusData,'TargetStatusData')
      const chart = this.$refs.rightpie_6;
      if (chart) {
        const myChart = this.$echarts.init(chart);
        const option = {
          tooltip: {
            trigger: "item",
          },
          title: {
            text: "总装卸量",
            left: "400",
            bottom: "0",
          },
          grid: {
            top: "0%",
            left: "0%",
            right: "0%",
            bottom: "18%",
            containLabel: true,
          },
          color:['#FF5C2C','#999'],
         graphic:{
            type:"text",
            left:"center",
            top:"45%",
            style:{
                text:this.TargetStatusData[6].val2?this.TargetStatusData[6].val2+'%':'',
                textAlign:"center",
                fill:"#fff",
                fontSize:12,
                fontWeight:700
            }
          },
          series: [
            {
              name: "Access From",
              type: "pie",
              radius: ["100%", "70%"],
              label: {
                show: false,
                position: "center",
              },
              data: [
               { value: this.TargetStatusData[6].val2?this.TargetStatusData[6].val2:''},
                { value: 100-this.TargetStatusData[6].val2 },
              ],
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)",
                },
              },
            },
          ],
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
              label: {
                backgroundColor: "#6a7985",
              },
            },
          },
        };
        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
        });
      }
      this.$on("hook:destroyed", () => {
        window.removeEventListener("resize", function () {
          myChart.resize();
        });
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
@import "./index.less";
</style>
<style >
.sale-cont-box .ant-table-thead > tr > th,.sale-cont-box .ant-table-tbody > tr > td {
  padding: 8px 6px;
  overflow-wrap: break-word;
}
</style>
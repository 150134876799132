import Vue from 'vue'
import Router from 'vue-router'
// import WbCenter from '../views/pages/WbCenter/index'
import WbCenter from '../views/WbCenter'
import SaleCenter from '../views/SaleCenter/index'
import ShipCenter from '../views/ShipCenter'
import Production from '../views/Production'
import Equipment from '../views/Equipment'
import GoodsCenter from '../views/GoodsCenter'
import MonitoringCenter from '../views/MonitoringCenter'
import wharfCenter from '../views/wharfCenter'


Vue.use(Router)

export default new Router({
    routes: [{
            path: '/',
            name: 'wharfCenter',
            component: wharfCenter
        },
        {
            path: '/MonitoringCenter',
            name: 'MonitoringCenter',
            component: MonitoringCenter
        },
        {
            path: '/WbCenter',
            name: 'WbCenter',
            component: WbCenter
        },
        {
            path: '/ShipCenter',
            name: 'ShipCenter',
            component: ShipCenter
        },
        {
            path: '/SaleCenter',
            name: 'SaleCenter',
            component: SaleCenter
        },
        {
            path: '/Production',
            name: 'Production',
            component: Production
        },
        {
            path: '/Equipment',
            name: 'Equipment',
            component: Equipment
        },
        {
            path: '/MonitoringCenter',
            name: 'MonitoringCenter',
            component: MonitoringCenter
        },
        {
            path: '/GoodsCenter',
            name: 'GoodsCenter',
            component: GoodsCenter
        },
    ]
})
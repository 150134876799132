<template>
  <div id="MonitoringCenter-cont">
    <div class="MonitoringCenter-cont-box">
      <div class="MonitoringCenter_bg"></div>
      <div class="MonitoringCenter">
        <div class="MonitoringCenter-title">
          <div class="MonitoringCenter-title-left">
            <a-cascader
              class="MonitoringCenter-chose"
              :field-names="{ label: 'name', value: 'code', children: 'items' }"
              :options="options"
              :allowClear="false"
              placeholder="万丰港区"
              @change="onChange"
            />
          </div>
          <div class="MonitoringCenter-title-center">
            <img src="../../assets/image/left.png" alt="" @click="golast()"/>
            <img src="../../assets/image/title_img1.png" alt="" />
            <img src="../../assets/image/right.png" alt="" @click="gonext()"/>
          </div>
          <div class="MonitoringCenter-title-right">
            <div class="MonitoringCenter-title-right-button1">
              <img src="../../assets/image/back.png" alt="" @click="goback()" />
            </div>
            <div class="MonitoringCenter-title-right-button1">
              <img src="../../assets/image/fullScreen.png" alt=""  v-if="!isFullscreen"  @click="f11Click" />
              <img src="../../assets/image/fullScreenUn.png" alt=""   v-else  @click="f11Click" />

            </div>
          </div>
        </div>
        <div class="MonitoringCenter-top-echart">
          <div class="MonitoringCenter-top-echart-left">
            <div class="MonitoringCenter-port-date">
              <p><span>今日</span>港内数据<span>Port Data of Today</span></p>
              <div class="MonitoringCenter-port-date-num">
                <div>
                  <p>进出港<span>车次</span></p>
                  <p>{{ TodaySummary.val1 ? TodaySummary.val1 : "-" }}</p>
                </div>
                <div>
                  <p><span>散杂货</span>(吨)</p>
                  <p>{{ TodaySummary.val2 ? TodaySummary.val2 : "-" }}</p>
                </div>
                <div>
                  <p><span>集装箱</span>(吨)</p>
                  <p>{{ TodaySummary.val3 ? TodaySummary.val3 : "-" }}</p>
                </div>
              </div>
            </div>
            <div class="MonitoringCenter-busy">
              <p>
                今日码头作业<span>繁忙度</span><span>过磅车辆次数（次）</span>
              </p>
              <div ref="chart" style="width: 100%; height: 200px"></div>
            </div>
          </div>
          <div class="MonitoringCenter-top-echart-right">
            <div class="MonitoringCenter-port-date">
              <p>
                <span>本月</span>港内数据<span>Port Data of This Month</span>
              </p>
              <div class="MonitoringCenter-port-date-num">
                <div>
                  <p>进出港 <span>车次</span></p>
                  <p>
                    {{ ThisMonthSummary.val1 ? ThisMonthSummary.val1 : "-" }}
                  </p>
                </div>
                <div>
                  <p><span>散杂货</span>(万吨)</p>
                  <p>
                    {{ ThisMonthSummary.val2 ? ThisMonthSummary.val2 : "-" }}
                  </p>
                </div>
                <div>
                  <p><span>集装箱</span>(吨)</p>
                  <p>
                    {{ ThisMonthSummary.val3 ? ThisMonthSummary.val3 : "-" }}
                  </p>
                </div>
              </div>
            </div>
            <div class="MonitoringCenter-busy">
              <p>
                进出港车次环比
                <span class="spanbox">
                  <span
                    v-for="(item, i) in datalist"
                    :key="i"
                    :class="{ active: active == i }"
                    @click="choseTime(i)"
                  >
                    {{ item }}
                  </span>
                </span>
              </p>
              <div ref="barchart" style="width: 100%; height: 200px"></div>
            </div>
          </div>
        </div>
        <div class="MonitoringCenter-bottom">
          <div class="MonitoringCenter-bottom-table">
            <div class="MonitoringCenter-bottom-table-title">
              <p>车辆过磅监控 <span>Vehicle Weighing Monitoring</span></p>
            </div>
            <div class="MonitoringCenter-bottom-table-cont">
              <div class="table-box" id="table-box">
                <a-table
                  :columns="columns"
                  :data-source="columnsData"
                  :pagination="false"
                  :scroll="{ y: tableHeight }"
                  style="color: #000; overflow-x: hidden; overflow-y: hidden"
                  :customRow="handleCheck"
                  :locale="locale"
                  ref="carTable"
                />
              </div>
            </div>
          </div>
          <div class="MonitoringCenter-bottom-data">
            <div class="MonitoringCenter-bottom-data-title">
              <p>
                当前车辆称重信息
                <span>Current Vehicle Weighing Information</span>
              </p>
            </div>
            <div class="MonitoringCenter-bottom-data-cont">
              <div class="MonitoringCenter-bottom-data-currentinfo">
                <!-- 车辆信息 -->
                <div class="left">
                  <div class="left-top">
                    <div>
                      <p>车辆牌照</p>
                      <p>
                        {{
                          WagonBalanceDetail
                            ? WagonBalanceDetail.plateNumber
                            : "-"
                        }}
                      </p>
                    </div>
                    <div>
                      <p>轴数</p>
                      <p>
                        {{
                          WagonBalanceDetail
                            ? WagonBalanceDetail.axleCount
                            : "-"
                        }}
                      </p>
                    </div>
                  </div>
                  <div class="left-bottom">
                    <div>
                      <p>作业任务</p>
                      <p>
                        {{
                          WagonBalanceDetail 
                            ? (WagonBalanceDetail.taskRemark.length > 25 ? WagonBalanceDetail.taskRemark.substr(0,24) + '...' : WagonBalanceDetail.taskRemark)
                            : "-  "
                        }}
                      </p>
                    </div>
                  </div>
                </div>
                <!-- 照片 -->
                <viewer class="right" :images="photo">
                  <div class="photo">
                    <img src="../../assets/image/icon.png" alt="" />
                    过磅照片
                  </div>
                  <div class="photoBox">
                    <span v-if="WagonBalanceDetail!=null">
                      <span v-for="(item, index) in WagonBalanceDetail.picUri.split('$')"
                        :key="'photoBox_span_'+index">
                        <img
                          v-for="(data, index) in item.split(',')"
                          :src="'https://wechat.sfghjt.com/image'+data"
                          :key="'photoBox_'+index"
                        />
                      </span>
                    </span>
                  </div>
                </viewer>
              </div>
              <div class="MonitoringCenter-bottom-data-weight">
                <div>
                  <p>
                    {{ WagonBalanceDetail ? WagonBalanceDetail.tareKg : "-" }}
                  </p>
                  <p>皮重(kg)</p>
                </div>
                <div>
                  <p>
                    {{ WagonBalanceDetail ? WagonBalanceDetail.netKg : "-" }}
                  </p>
                  <p>净重(kg)</p>
                </div>
                <div>
                  <p>
                    {{ WagonBalanceDetail ? WagonBalanceDetail.grossKg : "-" }}
                  </p>
                  <p>毛重(kg)</p>
                </div>
              </div>
              <div class="MonitoringCenter-bottom-data-time">
                <div class="enter-time">
                  <p>进港过磅时间</p>
                  <p>
                    {{
                      WagonBalanceDetail
                        ? WagonBalanceDetail.inTime
                        : "- : - : -"
                    }}
                  </p>
                </div>
                <div class="arrowhead">
                  <div class="triangle"></div>
                </div>
                <div class="out-time">
                  <p>出港过磅时间</p>
                  <p>
                    {{
                      WagonBalanceDetail
                        ? WagonBalanceDetail.outTime
                        : "- : - : -"
                    }}
                  </p>
                </div>
              </div>
              <div class="MonitoringCenter-bottom-data-specific">
                <div class="left">
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <div class="right">
                  <div>
                    <p>发货单位</p>
                    <p>
                      {{
                        WagonBalanceDetail ? WagonBalanceDetail.fromObj : "-"
                      }}
                    </p>
                  </div>
                  <div>
                    <p>车队</p>
                    <p>
                      {{
                        WagonBalanceDetail ? WagonBalanceDetail.teamName : "-"
                      }}
                    </p>
                  </div>
                  <div>
                    <p>货名</p>
                    <p>
                      {{
                        WagonBalanceDetail ? WagonBalanceDetail.goodsName : "-"
                      }}
                    </p>
                  </div>
                  <div>
                    <p>收货单位</p>
                    <p>
                      {{ WagonBalanceDetail ? WagonBalanceDetail.toObj : "-" }}
                    </p>
                  </div>
                  <div>
                    <p>司机</p>
                    <p>
                      {{
                        WagonBalanceDetail ? WagonBalanceDetail.driverName : "-"
                      }}
                    </p>
                  </div>
                  <div>
                    <p>船舶</p>
                    <p>
                      {{
                        WagonBalanceDetail ? WagonBalanceDetail.shipName : "-"
                      }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const options = [
  {
    code: "wf",
    name: "万丰港区",
  },
];
const columns = [
  {
    title: "车牌号",
    dataIndex: "plateNumber",
    width: 100,
  },
  {
    title: "进港时间",
    dataIndex: "inTime",
    width: 100,
  },
  {
    title: "出港时间",
    dataIndex: "outTime",
    width: 100,
  },
  {
    title: "货名",
    dataIndex: "goodsName",
    width: 100,
  },
  {
    title: "皮重(kg)",
    dataIndex: "tareKg",
    width: 100,
  },
  {
    title: "毛重(kg)",
    dataIndex: "grossKg",
    width: 100,
  },
  {
    title: "净重(kg)",
    dataIndex: "netKg",
    width: 100,
  },
  {
    title: "任务",
    dataIndex: "taskRemark",
    width: 250,
  },
];
import {
  // 查询地磅列表接口
  getWagonBalanceList,
  // 查询地磅详细数据
  getWagonBalanceDetail,
  // 查询今日港内数据
  getTodaySummary,
  // 查询本月港内数据
  getThisMonthSummary,
  // 今日码头作业繁忙度图表数据
  getTodayBusyCharts,
  // 进出港车次环比图表数据
  getMonthBusyCharts,
} from "../../http/index.js";
import screenfull from 'screenfull' // 全屏功能
export default {
  name: "WbCenter",
  data() {
    return {
      //  data,
      locale:{
        emptyText: '暂无数据'
      },
      isFullscreen: false,
      columnsData: [],
      todayBusyDataX: [],
      todayBusyDataY: [],
      monthBusyDataX: [],
      monthBusyDataY: [],
      WagonBalanceDetail: [],
      TodaySummary: [],
      ThisMonthSummary: [],
      getfirstid: "",
      active: "1",
      columns,
      options,
      portName: "",
      datalist: ["日", "月", "年"],
      photo: [{ id: 1, img: require("../../assets/image/img.jpg") }],
      weightList: [
        {
          value: "-",
          name: "皮重(kg)",
        },
        {
          value: "50960",
          name: "净重(kg)",
        },
        {
          value: "-",
          name: "毛重(kg)",
        },
      ],
      specificList: [
        {
          name: "发货单位",
          vlaue: "山东金钟科技集团股份有限公司",
        },
        {
          name: "车队",
          vlaue: "10.24 #15 张兴",
        },
        {
          name: "货名",
          vlaue: "餐厨垃圾",
        },
        {
          name: "收货单位",
          vlaue: "光大环保能源（潍坊）有限公司",
        },
        {
          name: "司机",
          vlaue: "",
        },
        {
          name: "船舶",
          vlaue: "",
        },
      ],
      tableHeight: 0,
      currentRow: {},
      currentPk: "",
      currentType: "",
      intervalId: null,
      searchData: {},
      dataType:'2'
    };
  },
  created() {
    this.searchData = {
      apikey: "Sw4Mi3MdjII=",
      portName: this.portName ? this.portName.toString() : "wf",
    };

    this.getTableList();
    this.MonthBusyChartsFunc()
    // this.this.getDetailsList()
    // this.dataRefreh();
  },
  mounted() {
    this.$nextTick(() => {
      this.initHeight();
      this.init()
      this.isFullscreen=screenfull.isFullscreen
      // 拿到表格挂载后的真实DOM
      this.$refs.carTable.$el.querySelector('.ant-table-body').style.display='block'
      // this.$refs.carTable.$el.querySelector('.ant-table-body').scrollTop(100)
      console.log( this.$refs.carTable.$el.querySelector('.ant-table-body') ,'table11111111111111111')
      console.log( this.$refs.carTable.$el.querySelector('.ant-table-body').scrollTop  ,'22222222222222')
    });
    window.addEventListener('keydown', this.KeyDown, true)// 监听按键事件
  },
  beforeDestroy() {
    // 在页面销毁后，清除计时器
    this.clear();
    this.destroy()
  },
  methods: {
     KeyDown(event) {
      if (event.keyCode === 122) {
        // 禁用f11
        event.returnValue = false
        // 触发全屏的按钮
        this.f11Click()
      }
    },
    f11Click() {
      if (!screenfull.isEnabled) {
        this.$message({
          message: '浏览器不能全屏',
          type: 'warning'
        })
        return false
      }
      screenfull.toggle()
    },
    change() {
      this.isFullscreen = screenfull.isFullscreen
    },
    init() {
      if (screenfull.isEnabled) {
        screenfull.on('change', this.change) // ！！！！这儿方法名不能带括号
      }
    },
    destroy() {
      if (screenfull.isEnabled) {
        screenfull.off('change', this.change) // ！！！！这儿方法名不能带括号
      }
    },
    // 返回大屏
    goback() {
      this.$router.push({
        path: "/MonitoringCenter",
      });
    },
    golast(){
      console.log('qq')
      this.$router.push({
        path:'/Production'
      })
    },
    gonext(){
      console.log('qq')
      this.$router.push({
        path:'/shipCenter'
      })
    },
    // 定时刷新数据函数
    dataRefreh() {
      // 计时器正在进行中，退出函数
      if (this.intervalId !== null) {
        return;
      }
      // 计时器为空，操作
      this.intervalId = setInterval(() => {
        this.getTableList();
      }, 60000*5);
    },
    // 停止定时器
    clear() {
      this.intervalId && clearInterval(this.intervalId); //清除计时器
    },
    getTableList() {
      let params = {
        ...this.searchData,
        carType: 0,
      };
      getWagonBalanceList(params).then((res) => {
        this.columnsData = res;
        this.currentPk = res.length > 0 ? res[0].srcId : "";
        this.searchData = {
          ...this.searchData,
          pk: res.length > 0 ? res[0].srcId : "",
        };
        this.getDetailsList();
      });
    },
    MonthBusyChartsFunc(){
       // 进出港车次环比'
      let params = {
        dateType:this.dataType.toString() || '2',
        portName: this.portName ? this.portName.toString() : "wf",
      }
      getMonthBusyCharts(params).then((res) => {
        this.monthBusyDataX = res.dataX;
        this.monthBusyDataY = res.dataY.map(String);
        this.getbarData();
      })
    },
    getDetailsList() {
      getWagonBalanceDetail(this.searchData).then((res) => {
        this.WagonBalanceDetail = res;
      });
      getTodaySummary(this.searchData).then((res) => {
        this.TodaySummary = res;
      });
      getThisMonthSummary(this.searchData).then((res) => {
        this.ThisMonthSummary = res;
      });
      getTodayBusyCharts(this.searchData).then((res) => {
        this.todayBusyDataX = res.dataX;
        this.todayBusyDataY = res.dataY.map(String);
        this.getlineData();
      });
     
    },
    // 选取当行数据
    handleCheck(record, index) {
      return {
        on: {
          click: () => {
            this.searchData = {
              ...this.searchData,
              carType: 0,
              portName: this.portName.length > 0 ? this.portName.toString() : "wf",
            };
            this.searchData = {
              ...this.searchData,
              pk: record.srcId,
              portName: this.portName.length > 0 ? this.portName.toString() : "wf",
            };
            this.currentRow = record;
            this.getDetailsList();
          },
        },
      };
    },
    initHeight() {
      const tableBox = document.getElementById("table-box");
      const height = tableBox.offsetHeight;
      this.tableHeight = height;
    },
    // 年月日选择
    choseTime(i) {
      this.active = i;
      this.dataType = i + 1
      let params = {
        portName: this.portName || 'wf',
        dateType: this.dataType.toString(),
      }
      this.MonthBusyChartsFunc()
    },
    // 港区选择
    onChange(value) {
      this.portName = value;
      if (value.toString() === "jz") {
        // alert('济州港区数据正在接通中......')
        let param = {
          ...this.searchData,
          carType: 0,
          portName: "jz"
        }
        this.MonthBusyChartsFunc()
        getWagonBalanceList(param).then((res) => {
          this.columnsData = res;
          this.currentPk = res.length > 0 ? res[0].srcId : "";
          this.searchData = {
            pk: res.length > 0 ? res[0].srcId : "",
            portName: "jz",
          };
          this.getDetailsList();
        });
      } else {
         let param = {
          ...this.searchData,
          carType: 0,
          portName: "wf"
        }
          this.MonthBusyChartsFunc()
        getWagonBalanceList(param).then((res) => {
          this.columnsData = res;
          this.currentPk = res.length > 0 ? res[0].srcId : "";
          this.searchData = {
            pk: res.length > 0 ? res[0].srcId : "",
            portName: "wf",
          };
          this.getDetailsList();
        });
      }
    },
    getlineData() {
      const option = {
        xAxis: {
          type: "category",
          data: this.todayBusyDataX,
        },
        yAxis: {
          type: "value",
        },
        grid: {
          top: "10%",
          left: "0%",
          right: "0%",
          bottom: "27%",
          containLabel: true,
        },
        series: [
          {
            data: this.todayBusyDataY,
            type: "line",
            smooth: true,
            areaStyle: {
              normal: {
                color: {
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "#FA6400", // 0% 处的颜色
                    },
                    {
                      offset: 0.7,
                      color: "rgba(119, 255, 193,0)", // 100% 处的颜色
                    },
                  ],
                  globalCoord: false, // 缺省为 false
                },
              },
            },
          },
        ],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
      };
      this.initChartData(option, "chart");
    },
    getbarData() {
      const option = {
        color: ['#00D6BA'],
        xAxis: {
          type: "category",
          data: this.monthBusyDataX,
        },
        yAxis: {
          type: "value",
        },
        grid: {
          top: "10%",
          left: "0%",
          right: "0%",
          bottom: "27%",
          containLabel: true,
        },

        series: [
          {
            data: this.monthBusyDataY,
            type: "bar",
             itemStyle: {
              normal: {
                color: {
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "#00D6BA", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "#77FFC1", // 100% 处的颜色
                    },
                  ],
                  globalCoord: false, // 缺省为 false
                },
              },
            },
          },
        ],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
      };
      this.initChartData(option, "barchart");
    },
    initChartData(option, ele) {
      const chart = this.$refs[ele];
      if (chart) {
        const myChart = this.$echarts.init(chart);
        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
        });
      }
      this.$on("hook:destroyed", () => {
        window.removeEventListener("resize", function () {
          myChart.resize();
        });
      });
    },
  },
};
</script>

<style scoped lang="less">
@import "./index.less";
</style>

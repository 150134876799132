import Vue from 'vue'
import App from './App'
import Antd from 'ant-design-vue';
import router from './router'
import Dropdown from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css';
import 'viewerjs/dist/viewer.css';
import Viewer from 'v-viewer';
import apikey from './components/Global.vue'
// import '../postcss.config.js'
 
Vue.use(Viewer);

const echarts = require('echarts');

// Vue.prototype.$echarts = echarts
Vue.config.productionTip = false
Vue.prototype.apikey = apikey.apikey;
Vue.use(Antd, Dropdown, Viewer, {
    defaultOptions: {
        zIndex: 9999
    }
});
Viewer.setDefaults({
    Options: { 'inline': true, 'button': true, 'navbar': true, 'title': true, 'toolbar': true, 'tooltip': true, 'movable': true, 'zoomable': true, 'rotatable': true, 'scalable': true, 'transition': true, 'fullscreen': true, 'keyboard': true, 'url': 'data-source' }
})
Vue.prototype.$echarts = echarts
    /* eslint-disable no-new */
new Vue({
    el: '#app',
    router,
    render: h => h(App)
})
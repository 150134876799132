<template>
  <div id="port-cont">
    <div class="port-cont-box">
      <div class="port_bg"></div>
      <div class="sale">
        <div class="port-title">
          <div class="port-title-left">
            <a-cascader
              class="port-chose"
              :field-names="{ label: 'name', value: 'code', children: 'items' }"
              :options="options"
              :allowClear="false"
              placeholder="万丰港区"
              @change="onChange"
            />
          </div>
          <div class="port-title-center">
            <img src="../../assets/image/left.png" alt="" @click="golast()"/>
            <img src="../../assets/image/portCentertitle.png" alt="" />
            <img src="../../assets/image/right.png" alt="" @click="gonext()"/>
          </div>
          <div class="MonitoringCenter-title-right">
            <div class="MonitoringCenter-title-right-button1">
              <img src="../../assets/image/back.png" alt="" @click="goback()" />
            </div>
            <div class="MonitoringCenter-title-right-button1">
               <img src="../../assets/image/fullScreen.png" alt=""  v-if="!isFullscreen"  @click="f11Click" />
              <img src="../../assets/image/fullScreenUn.png" alt=""   v-else  @click="f11Click" />
            </div>
          </div>
        </div>
        <div class="port-content">
          <div class="port-cont-left">
            <div class="port-cont-left-top">
              <div class="port-date">
                <p>码头吞吐力情况<span>Wharf Throughput</span></p>
                <div class="port-type">
                  <div class="port-type-left">
                    <div class="active">散杂货</div>
                    <div>集装箱</div>
                  </div>
                  <div class="port-type-right">
                    <div
                      v-for="(time, i) in timelist"
                      :key="i"
                      :class="{ active: portactive == i }"
                      @click="portchoseTime(i)"
                    >
                      {{ time }}
                    </div>
                  </div>
                </div>
                <div class="port-date-num">
                  <div>
                    <p>本月<span>吞吐量</span>(万吨)</p>
                    <p class="p_1">
                      {{ HCSummaryData.val1 ? HCSummaryData.val1 : "-" }}
                    </p>
                  </div>
                  <div>
                    <p>本月最大<span>单日</span></p>
                    <p class="p_2">
                      {{ HCSummaryData.val2 ? HCSummaryData.val2 : "-" }}
                    </p>
                  </div>
                  <div>
                    <p>当日吞吐量(吨)</p>
                    <p class="p_2">
                      {{ HCSummaryData.val3 ? HCSummaryData.val3 : "-" }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="port-busy">
                <p>本月吞吐量变化(万吨)</p>
                <div ref="linechart" style="width: 100%; height: 100%"></div>
              </div>
            </div>
            <div class="port-cont-left-bottom">
              <div class="port-date">
                <div class="port-data-title">
                  <div>货物类型统计<span>Cargo Type</span></div>
                  <div>
                    <span
                      v-for="(time, i) in timelist"
                      :key="i"
                      :class="{ active: goodsactive == i }"
                      @click="goodschoseTime(i)"
                      style="text-align: center"
                    >
                      {{ time }}
                    </span>
                  </div>
                </div>

                <div class="port-date-num">
                  <div>
                    <p>本月货物类型数</p>
                    <p class="p_1">
                      {{
                        CargoTypeSummaryData.val1
                          ? CargoTypeSummaryData.val1
                          : "-"
                      }}
                    </p>
                  </div>
                  <div>
                    <p>本月最热货物</p>
                    <p class="p_2">
                      {{
                        CargoTypeSummaryData.val2
                          ? CargoTypeSummaryData.val2
                          : "-"
                      }}
                    </p>
                  </div>
                  <div>
                    <p>此类型货量占比</p>
                    <p class="p_2">
                      {{
                        CargoTypeSummaryData.val3
                          ? CargoTypeSummaryData.val3+'%'
                          : "-"
                      }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="port-busy">
                <p>各个种类货物量排名(万吨)</p>
                <div ref="barchart" style="width: 100%; height: 100%"></div>
              </div>
            </div>
          </div>
          <div class="port-cont-right">
            <div class="port-cont-right-left">
              <p class="port-cont-right-left-title">
                各种类货物客户追踪 <span>Tracking the Source</span>
              </p>
              <div class="port-cont-right-left-data">
                <p>本月<span>客户</span>数量</p>
                <p>{{ CustomerNumData.val1 ? CustomerNumData.val1 : "-" }}</p>
              </div>
              <div
                ref="chartDom"
                style="width: 100%; height: calc(100% - 100px)"
              ></div>
              <p class="port-cont-right-right-p">
                本月全部种类货物及货量(吨)
              </p>
            </div>
            <div class="port-cont-right-right">
              <div class="line"></div>
              <div class="port-chart-time">
                <span
                  v-for="(time, i) in timelist"
                  :key="i"
                  :class="{ active: active == i }"
                  @click="choseTime(i)"
                  style="text-align: center"
                >
                  {{ time }}
                </span>
              </div>
              <div class="port-right-tab1">
                <div class="left">
                  <div>
                    <p>
                      {{ FdTopSummaryData.val1 ? FdTopSummaryData.val1 : "-" }}
                    </p>
                    <p>货物类型</p>
                  </div>
                  <div>
                    <p>
                      {{ FdTopSummaryData.val2 ? FdTopSummaryData.val2 : "-" }}
                    </p>
                  </div>
                </div>
                <div class="right">
                  <div>
                    <p>
                      {{ FdTopSummaryData.val3 ? FdTopSummaryData.val3 : "-" }}
                    </p>
                    <p>客户</p>
                  </div>
                  <div>
                    <p>
                      {{ FdTopSummaryData.val4 ? FdTopSummaryData.val4 : "-" }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="port-right-tab2">
                <p>
                  <span>{{ FdTopSummaryData.val1 ? FdTopSummaryData.val1 : "-" }}所有客户</span> /
                  <span>{{ FdTopSummaryData.val3 ? FdTopSummaryData.val3 : "-" }}</span> 本月货量变化(万吨)
                </p>
                <div ref="StepLine" style="width: 100%; height: 100%"></div>
              </div>
              <div class="port-right-tab3">
                <p>
                  <span>{{ FdTopSummaryData.val1 ? FdTopSummaryData.val1 : "-" }}所有客户</span> /
                  <span>{{ FdTopSummaryData.val3 ? FdTopSummaryData.val3 : "-" }}</span> 本月货物销售销售信息
                </p>
                <div class="table-box" id="table-box">
                  <a-table
                    :columns="column"
                    :data-source="FdBottomListData"
                    :pagination="false"
                     :rowKey="(record,index)=>{return index}"
                    :scroll="{ y: tableHeight }"
                    style="color: #000; overflow-x: hidden; overflow-y: hidden"
                    :customRow="handleCheck"
                     :locale="locale"
                  >
                    <template slot="val2" slot-scope="text">
                      <span :style="{ color: '#FF5C2C' }">{{ text }}</span>
                    </template>
                    <template slot="val4" slot-scope="text">
                      <span
                        :style="`color: ${
                          text === '济宁热电' ? '#00B994' : '#ffffff'
                        }`"
                        >{{ text }}</span
                      >
                    </template>
                    <template slot="val5" slot-scope="text">
                      <span
                        :style="`color: ${
                          text === '已完成'
                            ? 'rgba(255, 255, 255, 0.5)'
                            : '#ffffff'
                        }`"
                        >{{ text }}</span
                      >
                    </template>
                  </a-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  // 码头吞吐量情况接口
  getHCSummary,
  // 本月吞吐量变化图表
  thisMonthHCChart,
  // 货物类型统计
  getCargoTypeSummary,
  // 各个种类货物量排名(万吨)
  getGoodsRankChart,
  // 本月客户数量
  getThisCustomerNum,
  // 旭日图表
  getFdChart,
  // 右上数据
  getFdTopSummary,
  // 煤炭所有客户/济宁热电本月货量变化(右中)
  getFdMiddleChart,
  // 煤炭所有客户/济宁热电本月货物销售销售信（右下）
  getFdBottomList,
  // 旭日图中间数据
  getCoreSummary,
} from "../../http/index";
import screenfull from 'screenfull' // 全屏功能
const options = [
  {
    code: "wf",
    name: "万丰港区",
  },
];

export default {
  name: "sale",
  data() {
    return {
      locale:{
        emptyText: '暂无数据'
      },
      isFullscreen: false,
      options,
      portactive: "0",
      goodsactive: "0",
      active: "0",
      timelist: ["月", "年"],
      tableHeight: 0,
      column: [
        {
          title: "合同时间",
          dataIndex: "val1",
          width: 100,
        },
        {
          title: "货名",
          dataIndex: "val2",
          width: 60,
          scopedSlots: { customRender: "val2" },
        },
        {
          title: "货量(t)",
          dataIndex: "val3",
          width: 60,
        },
        {
          title: "货源地",
          dataIndex: "val4",
          width: 70,
          scopedSlots: { customRender: "val4" },
        },
        {
          title: "合同状态",
          dataIndex: "val5",
          width: 60,
          scopedSlots: { customRender: "val5" },
        },
      ],
      portName: "",
      // 码头吞吐力datatype
      HCSummaryType: "",
      HCSummaryData: "",
      MonthHCChartDataX: "",
      MonthHCChartDataY: "",
      CargoTypeSummaryData: "",
      goodsType: "",
      GoodsRankChartDataX: [],
      GoodsRankChartDataY: [],
      CustomerNumData: "",
      FdChartData: [],
      CoreSummaryData: "",
      keyword: "",
      fdTimeType: "",
      FdTopSummaryData: {},
      FdMiddleChartDataX: [],
      FdMiddleChartDatAll: [],
      FdMiddleChartDatMe: [],
      FdBottomListData: [],
      intervalId: null,
      params: {},
      chartName:''
    };
  },
  mounted() {
    this.getlineData(),
      this.getStepLineData(),
      this.$nextTick(() => {
        this.initHeight();
        this.init()
        this.isFullscreen=screenfull.isFullscreen
      });
      window.addEventListener('keydown', this.KeyDown, true)// 监听按键事件
  },
    beforeDestroy() {
    // 在页面销毁后，清除计时器
    this.clear();
    this.destroy()
  },
  created() {
    this.params = {
      apikey: "Sw4Mi3MdjII=",
      portName: this.portName ? this.portName.toString() : "wf",
    };
    this.initData();
    
    // this.dataRefreh();
  },
  methods: {
    KeyDown(event) {
      if (event.keyCode === 122) {
        // 禁用f11
        event.returnValue = false
        // 触发全屏的按钮
        this.f11Click()
      }
    },
    f11Click() {
      if (!screenfull.isEnabled) {
        this.$message({
          message: '浏览器不能全屏',
          type: 'warning'
        })
        return false
      }
      screenfull.toggle()
    },
    change() {
      this.isFullscreen = screenfull.isFullscreen
    },
    init() {
      if (screenfull.isEnabled) {
        screenfull.on('change', this.change) // ！！！！这儿方法名不能带括号
      }
    },
    destroy() {
      if (screenfull.isEnabled) {
        screenfull.off('change', this.change) // ！！！！这儿方法名不能带括号
      }
    },
    // 返回大屏
    goback() {
      this.$router.push({
        path: "/MonitoringCenter",
      });
    },
    golast(){
      console.log('qq')
      this.$router.push({
        path:'/SaleCenter'
      })
    },
    gonext(){
      console.log('qq')
      this.$router.push({
        path:'/Production'
      })
    },
    initHeight() {
      const tableBox = document.getElementById("table-box");
      const height = tableBox.offsetHeight + 100;
      console.log(height);
      this.tableHeight = height;
    },
    // 定时刷新数据函数
    dataRefreh() {
      // 计时器正在进行中，退出函数
      if (this.intervalId !== null) {
        return;
      }
      // 计时器为空，操作
      this.intervalId = setInterval(() => {
        this.initData();
      }, 60000*5);
    },
    // 停止定时器
    clear() {
      this.intervalId && clearInterval(this.intervalId); //清除计时器
    },
    onChange(value) {
      this.portName = value;
      if (value.toString() === "wf") {
        this.params = {
          apikey: "Sw4Mi3MdjII=",
          portName: "wf",
        };
        this.initData();
        // getDeviceStatus(this.params)
      } else {
        // alert('济州港区数据正在接通中......')
        this.params = {
          apikey: "Sw4Mi3MdjII=",
          portName: "jz",
        };
        this.initData();
      }
    },
    //客户年月切换
    choseTime(i) {
      this.active = i;
      this.fdTimeType = i + 2;
      let param = {
          ...this.params,
          dateType: this.fdTimeType,
          keyword: this.keyword || `${this.FdChartData[0].name},${this.FdChartData[0].children[0].name}`,
        }
       this.getrightList(param)
    },
    // 码头吞吐力年月情况
    portchoseTime(i) {
      this.portactive = i;
      this.HCSummaryType = (i + 2).toString();
      this.getHCSummaryFunc()
    },
    // 货物类型统计时间切换
    goodschoseTime(i) {
      this.goodsactive = i;
      this.goodsType = (i + 2).toString();
      this.getCargoTypeFunc()
    },
    // 选取当行数据
    handleCheck(record, index) {
      return {
        on: {
          click: () => {
            console.log(record, index);
          },
        },
      };
    },
    // 页面初始化
    initData(){
      this.getHCSummaryFunc()
      this.getCargoTypeFunc()
     
      this.getDetail()
      setTimeout(()=>{
        this.getrightList()
      },1000)
    },
    // 码头吞吐力接口合集
    getHCSummaryFunc(){
      getHCSummary({
        ...this.params,
        type: "1",
        dateType: this.HCSummaryType || "2",
      }).then((res) => {
        this.HCSummaryData = res;
      });
      thisMonthHCChart({
        ...this.params,
        type: "1",
        dateType: this.HCSummaryType || "2",
      }).then((res) => {
        this.MonthHCChartDataX = res.dataX;
        this.MonthHCChartDataY = res.dataY;
        this.getlineData();
      });
    },
    getCargoTypeFunc(){
      getCargoTypeSummary({
        ...this.params,
        dateType: this.goodsType || "2",
      }).then((res) => {
        this.CargoTypeSummaryData = res;
      });
      getGoodsRankChart({
        ...this.params,
        dateType: this.goodsType || "2",
      }).then((res) => {
        this.GoodsRankChartDataX = res.dataX,
        this.GoodsRankChartDataY = res.dataY;
        this.getbarData();
      });
    },
    getrightList(){
      let param = {
        ...this.params,
        dateType: this.fdTimeType || 2,
        keyword: this.keyword || `${this.FdChartData[0].name},${this.FdChartData[0].children[0].name}`,
      }
      getFdTopSummary(param).then((res) => {
        this.FdTopSummaryData = res;
        this.chartName= this.FdTopSummaryData.val3
        getFdMiddleChart(param).then((res) => {
          this.FdMiddleChartDataX = res.dataX;
          this.FdMiddleChartDataAll = res.dataAll;
          this.FdMiddleChartDataMe = res.dataMe;
          this.getStepLineData();
        });
      });
      
      getFdBottomList(param).then((res) => {
        this.FdBottomListData = res;
      });
    },
    getDetail() {
      getThisCustomerNum(this.params).then((res) => {
        this.CustomerNumData = res;
      });
      
       getCoreSummary(this.params).then((res) => {
          this.CoreSummaryData = res.val1;
          getFdChart(this.params).then((res) => {

            this.FdChartData = res;
            this.getchartDomData();
            this.getrightList()
          });
      });
     
    },
    getlineData() {
      const option = {
        xAxis: {
          type: "category",
          data: this.MonthHCChartDataX,
        },
        yAxis: {
          type: "value",
        },
        grid: {
          top: "10%",
          left: "0%",
          right: "0%",
          bottom: "25%",
          containLabel: true,
        },
        series: [
          {
            data: this.MonthHCChartDataY,
            type: "line",
            smooth: true,
            areaStyle: {
              normal: {
                color: {
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "#FA6400", // 0% 处的颜色
                    },
                    {
                      offset: 0.7,
                      color: "rgba(119, 255, 193,0)", // 100% 处的颜色
                    },
                  ],
                  globalCoord: false, // 缺省为 false
                },
              },
            },
          },
        ],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
      };
      this.initChart(option,'linechart')    
    },
    getbarData() {
      const option = {
        xAxis: {
          type: "category",
          data: this.GoodsRankChartDataX,
        },
        yAxis: {
          type: "value",
          show: false,
        },
        grid: {
          top: "10%",
          left: "0%",
          right: "0%",
          bottom: "0%",
          containLabel: true,
        },
        series: [
          {
            data: this.GoodsRankChartDataY,
            type: "bar",
            labelLine: {
              //引导线设置
              normal: {
                show: true, //引导线显示
              },
            },
            smooth: true,
            itemStyle: {
              normal: {
                color: {
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "#00D6BA", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "#77FFC1", // 100% 处的颜色
                    },
                  ],
                  globalCoord: false, // 缺省为 false
                },
              },
            },
          },
        ],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
      };
      this.initChart(option,'barchart')    
    },
    getchartDomData() {
      const chart = this.$refs.chartDom;
      console.log(this.FdChartData,'this.FdChartData')
      if (chart) {
        const myChart = this.$echarts.init(chart);
        const option = {
          series: {
            type: "sunburst",
            data: this.FdChartData,
            radius: ["30%", "90%"],
            label: {
              rotate: "radial",
            },
          },
          graphic: {
            type: "text",
            left: "center",
            top: "48%",
            style: {
              text: `货物量总和\n${this.CoreSummaryData}(万吨)`,
              textAlign: "center",
              fill: "#ffffff",
              fontSize: 20,
              lineHeight: 30,
              fontWeight: 700,
            },
          },
        };

        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
        });
        myChart.on("click", (params) => {
          myChart.setOption(option);
          // console.log(params.name, this.params, this.fdTimeType, "zzzz");
          // this.keyword = params.name;
          // 不是点哪一个圈都要请求接口的!只有第二层才需要联动.
          if(params.treePathInfo.length!=3){
            // myChart.setOption(option);
            return;
          }
          // 把这个keyword提交到联动接口中
          this.keyword = params.treePathInfo[1].name+','+params.treePathInfo[2].name;
          // debugger
          console.log(this.keyword,'====')
          this.getrightList()
        });
      }
      this.$on("hook:destroyed", () => {
        window.removeEventListener("resize", function () {
          myChart.resize();
        });
      });
    },
    getStepLineData() {
      const option = {
          tooltip: {
            trigger: "axis",
          },

          grid: {
            left: "3%",
            right: "4%",
            bottom: "20%",
            top: "12%",
            containLabel: true,
          },

          xAxis: {
            type: "category",
            data: this.FdMiddleChartDataX,
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
                color: "#ccc",
              },
            },
          },
          yAxis: {
            type: "value",
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
                color: "#ccc",
              },
            },
          },
          series: [
            {
              name: "所有客户",
              type: "line",
              step: "start",
              data: this.FdMiddleChartDataAll,
            },
            {
              name: this.chartName,
              type: "line",
              step: "middle",
              data: this.FdMiddleChartDataMe,
            },
          ],
      };
      this.initChart(option,'StepLine')
    },
    // 图表初始化
    initChart(option, ele) {
      const chart = this.$refs[ele];
      if (chart) {
        const myChart = this.$echarts.init(chart);
        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
        });
      }
      this.$on("hook:destroyed", () => {
        window.removeEventListener("resize", function () {
          myChart.resize();
        });
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
@import "./index.less";
</style>

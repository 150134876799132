<template>
  <div id="Ship-cont">
    <div class="Ship-cont-box">
      <div class="Ship_bg"></div>
      <div class="Ship">
        <div class="Ship-title">
          <div class="Ship-title-left">
            <a-cascader
              class="Ship-chose"
              :field-names="{ label: 'name', value: 'code', children: 'items' }"
              :options="options"
              :allowClear="false"
              placeholder="万丰港区"
              @change="onChange"
            />
          </div>
          <div class="ship-title-center">
            <img src="../../assets/image/left.png" alt="" @click="golast()"/>
            <img src="../../assets/image/ship_title.png" alt="" />
            <img src="../../assets/image/right.png" alt=""  @click="gonext()"/>
          </div>
          <div class="ship-title-right">
            <div class="ship-title-right-button1">
              <img src="../../assets/image/back.png" alt="" @click="goback()"/>
            </div>
            <div class="ship-title-right-button1">
               <img src="../../assets/image/fullScreen.png" alt=""  v-if="!isFullscreen"  @click="f11Click" />
               <img src="../../assets/image/fullScreenUn.png" alt=""   v-else  @click="f11Click" />
            </div>
          </div>
        </div>
        <div class="Ship-top-echart">
          <div class="Ship-top-echart-left">
            <div class="Ship-port-date">
              <p class="todayData">
                <span>今日</span>船舶数据<span>Ship Data of Today</span>
              </p>
              <div class="Ship-port-date-num">
                <div>
                  <p>港内船数(艘)</p>
                  <p>
                    {{ shipTodaySummary.val1 ? shipTodaySummary.val1 : "-" }}
                  </p>
                </div>
                <span class="line"></span>
                <div>
                  <p><span>在锚地(艘)</span></p>
                  <p>
                    {{ shipTodaySummary.val2 ? shipTodaySummary.val2 : "-" }}
                  </p>
                </div>
                <div>
                  <p><span></span>预到港(艘)</p>
                  <p>
                    {{ shipTodaySummary.val3 ? shipTodaySummary.val3 : "-" }}
                  </p>
                </div>
                <div>
                  <p>进港(艘)</p>
                  <p>
                    {{ shipTodaySummary.val4 ? shipTodaySummary.val4 : "-" }}
                  </p>
                </div>
              </div>
            </div>
            <div class="Ship-busy">
              <p>今日泊位作业<span>压力</span>锚地船舶量</p>
              <div
                ref="chart"
                style="width: 100%; height: 96%; margin-top: -50px"
              ></div>
            </div>
          </div>
          <div class="Ship-top-echart-right">
            <div class="Ship-port-date">
              <p class="todayData">
                <span>本月</span>船舶数据<span>Ship Data of This Month</span>
              </p>
              <div class="Ship-port-date-num">
                <div>
                  <p>进出港 <span>(艘次)</span></p>
                  <p>
                    {{ shipMonthSummary.val1 ? shipMonthSummary.val1 : "-" }}
                  </p>
                </div>
                <span class="line"></span>
                <div>
                  <p><span class="color_active">散杂货</span>运输量（万吨）</p>
                  <p>
                    {{ shipMonthSummary.val2 ? shipMonthSummary.val2 : "-" }}
                  </p>
                </div>
                <div>
                  <p><span class="color_active">集装箱</span>(箱)</p>
                  <p>
                    {{ shipMonthSummary.val3 ? shipMonthSummary.val3 : "-" }}
                  </p>
                </div>
              </div>
            </div>
            <div class="Ship-busy">
              <p>
                进出港船舶艘次环比
                <span class="spanbox">
                  <span
                    v-for="(item, i) in datalist"
                    :key="i"
                    :class="{ active: active == i }"
                    @click="choseTime(i)"
                  >
                    {{ item }}
                  </span>
                </span>
              </p>
              <div
                ref="barchart"
                style="width: 100%; height: 100%; margin-top: 0px"
              ></div>
            </div>
          </div>
        </div>
        <div class="Ship-bottom">
          <div class="Ship-bottom-table">
            <div class="Ship-bottom-table-title">
              <p>船舶进出港监控 <span>Ship Entry and Exit Monitoring</span></p>
            </div>
            <div class="Ship-bottom-table-cont">
              <div class="table-box" id="table-box">
                <a-table
                  :columns="columns"
                  :data-source="columnsData"
                  :pagination="false"
                  :scroll="{ y: tableHeight }"
                  style="color: #000; overflow-x: hidden"
                  :customRow="handleCheck"
                  :locale="locale"
                />
              </div>
            </div>
          </div>
          <div class="Ship-bottom-data">
            <div class="Ship-bottom-data-title">
              <p>
                当前船舶作业信息
                <span>Current Ship Operation Information</span>
              </p>
            </div>
            <div class="Ship-bottom-data-cont">
              <div class="Ship-bottom-data-currentinfo">
                <!-- 车辆信息 -->
                <div class="left">
                  <div class="left-top">
                    <div>
                      <p>
                        {{ shipDetail.shipName ? shipDetail.shipName : "-" }}
                      </p>
                      <p>
                        {{ shipDetail.shipType ? shipDetail.shipType : "-" }}
                        <span>{{
                          shipDetail.plateNumber ? shipDetail.plateNumber : "-"
                        }}</span>
                      </p>
                    </div>
                    <div>
                      <p>
                        {{
                          shipDetail.shipStatus ? shipDetail.shipStatus : "-"
                        }}
                      </p>
                      <p>目前状态</p>
                    </div>
                  </div>
                  <div class="left-bottom">
                    <div>
                      <img src="../../assets/image/icon_1.png" alt="" />
                      <span>{{
                        shipDetail.driverName ? shipDetail.driverName : "-"
                      }}</span>
                    </div>
                    <div>
                      <img src="../../assets/image/icon_2.png" alt="" />
                      <span>{{
                        shipDetail.driverPhone ? shipDetail.driverPhone : "-"
                      }}</span>
                    </div>
                  </div>
                </div>
                <!-- 照片 -->
                <viewer class="right" :images="photo">
                  <div class="photo">
                    <img src="../../assets/image/icon.png" alt="" />
                    过磅照片
                  </div>
                  <img
                    v-for="(src, index) in photo"
                    :src="src.img"
                    :key="index"
                  />
                </viewer>
              </div>
              <div class="Ship-bottom-data-weight">
                <div class="left">
                  <div>
                    <p>装/卸</p>
                    <p class="color">
                      {{ shipDetail.inOrOut ? shipDetail.inOrOut : "-" }}
                    </p>
                  </div>
                  <div>
                    <p>货物名称</p>
                    <p>
                      {{ shipDetail.goodsName ? shipDetail.goodsName : "-" }}
                    </p>
                  </div>
                </div>
                <div class="center">
                  <p>货物重量(吨)</p>
                  <p>{{ shipDetail.goodsTon ? shipDetail.goodsTon : "-" }}</p>
                </div>
                <div class="right bgGrey">
                  <div>
                    <p>预到港</p>
                  </div>
                  <div>
                    <p>{{ shipDetail.actualMMDD ? shipDetail.actualMMDD : "-" }}</p>
                    <p>{{ shipDetail.actualHHSS ? shipDetail.actualHHSS : "-" }}</p>
                  </div>
                </div>
              </div>
              <div class="Ship-bottom-data-time">
                <div class="enter-time">
                  <p>
                    {{
                      shipDetail.actualMMDD ? shipDetail.actualMMDD : "-:-:-"
                    }}
                  </p>
                  <p>
                    {{
                      shipDetail.actualHHSS ? shipDetail.actualHHSS : "-:-:-"
                    }}
                  </p>
                </div>
                <div class="enter">进港</div>
                <div class="arrowhead">
                  <div class="triangle"></div>
                </div>
                <div class="out">离港</div>
                <div class="out-time">
                  <p>
                    {{ shipDetail.leaveMMDD ? shipDetail.leaveMMDD : "-:-:-" }}
                  </p>
                  <p>
                    {{ shipDetail.leaveHHSS ? shipDetail.leaveHHSS : "-:-:-" }}
                  </p>
                </div>
              </div>
              <div class="Ship-bottom-data-specific">
                <div class="left">
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <div class="right">
                  <div>
                    <p>客户 联系人</p>
                    <p>
                      {{
                        shipDetail.customerName ? shipDetail.customerName : "-"
                      }}
                    </p>
                  </div>
                  <div>
                    <p>联系方式</p>
                    <p>
                      {{
                        shipDetail.customerPhone
                          ? shipDetail.customerPhone
                          : "-"
                      }}
                    </p>
                  </div>
                  <div>
                    <p>货主</p>
                    <p>
                      {{ shipDetail.ownerName ? shipDetail.ownerName : "-" }}
                    </p>
                  </div>
                  <div>
                    <p>联系方式</p>
                    <p>
                      {{ shipDetail.ownerPhone ? shipDetail.ownerPhone : "-" }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const options = [
  {
    code: "wf",
    name: "万丰港区",
  },
];
const columns = [
  {
    title: "船舶名称",
    dataIndex: "shipName",
    width: 100,
    // fixed: 'left',
  },
  {
    title: "装/卸",
    dataIndex: "inOrOut",
    width: 100,
  },
  {
    title: "船舶类型",
    dataIndex: "shipType",
    width: 100,
  },
  // {
  //   title: "预到港时间",
  //   dataIndex: "etaTime",
  //   width: 100,
  // },
  {
    title: "进港时间",
    dataIndex: "actualTime",
    width: 100,
  },
  {
    title: "离港时间",
    dataIndex: "leaveTime",
    width: 100,
  },
  {
    title: "货物名称",
    dataIndex: "goodsName",
    width: 100,
  },
  {
    title: "货重(t)",
    dataIndex: "goodsTon",
    width: 80,
  },
  {
    title: "客户",
    dataIndex: "customerName",
    width: 100,
  },
  {
    title: "目前状态",
    dataIndex: "shipStatus",
    width: 100,
    // fixed: 'right',
  },
  // {
  //   title: 'Action',
  //   key: 'operation',
  //   dataIndex:'operation',
  //   fixed: 'right',
  //   width: 100,
  //   scopedSlots: { customRender: 'action' },
  // },
];
import {
  getShipList,
  getShipDetail,
  getShipTodaySummary,
  getShipMonthSummary,
  getShipTodayBusyCharts,
  getShipMonthBusyCharts,
} from "../../http/index.js";
import screenfull from 'screenfull' // 全屏功能
export default {
  name: "Ship",
  data() {
    return {
      locale:{
        emptyText: '暂无数据'
      },
      isFullscreen: false,
      shiptype: ["散杂货船", "集装箱船"],
      active: "1",
      columns,
      options,
      datalist: ["日", "月", "年"],
      photo: [{ id: 1, img: require("../../assets/image/img.jpg") }],
      tableHeight: 0,
      columnsData: [],
      portName: "",
      currentRow: "",
      searchData: "",
      shipDetail: "",
      shipTodaySummary: "",
      shipMonthSummary: "",
      shipTodayBusyChartsX: [],
      shipTodayBusyChartsY: [],
      shipMonthBusyChartsX: [],
      intervalId: null,
      shipMonthBusyChartsY: [],
      searchDataList:{}
    };
  },
  created() {
    this.getShipListData()

    this.dataRefreh();
  },
  mounted() {
    this.getlineData();
    this.getbarData();
    this.$nextTick(() => {
      this.initHeight();
      this.init()
      this.isFullscreen=screenfull.isFullscreen
    });
    window.addEventListener('keydown', this.KeyDown, true)// 监听按键事件
  },
  methods: {
    KeyDown(event) {
      if (event.keyCode === 122) {
        // 禁用f11
        event.returnValue = false
        // 触发全屏的按钮
        this.f11Click()
      }
    },
    f11Click() {
      if (!screenfull.isEnabled) {
        this.$message({
          message: '浏览器不能全屏',
          type: 'warning'
        })
        return false
      }
      screenfull.toggle()
    },
    change() {
      this.isFullscreen = screenfull.isFullscreen
    },
    init() {
      if (screenfull.isEnabled) {
        screenfull.on('change', this.change) // ！！！！这儿方法名不能带括号
      }
    },
    destroy() {
      if (screenfull.isEnabled) {
        screenfull.off('change', this.change) // ！！！！这儿方法名不能带括号
      }
    },
     // 返回大屏
    goback(){
      console.log('qq')
      this.$router.push({
        path:'/MonitoringCenter'
      })
    },
    golast(){
      console.log('qq')
      this.$router.push({
        path:'/WbCenter'
      })
    },
    gonext(){
      console.log('qq')
      this.$router.push({
        path:'/MonitoringCenter'
      })
    },
    // 定时刷新数据函数
    dataRefreh() {
      // 计时器正在进行中，退出函数
      if (this.intervalId !== null) {
        return;
      }
      // 计时器为空，操作
      this.intervalId = setInterval(() => {
       this.getShipListData() //加载数据函数
      }, 60000*5);
    },
    // 停止定时器
    clear() {
      this.intervalId && clearInterval(this.intervalId); //清除计时器
    },
    initHeight() {
      const tableBox = document.getElementById("table-box");
      const height = tableBox.offsetHeight;
      console.log(height);
      this.tableHeight = height;
    },
    getShipListData(){
      this.searchDataList = { 
        shipType: 0, 
        portName: this.portName.length > 0? this.portName.toString():'wf',
        apikey: "Sw4Mi3MdjII=" }
      getShipList(this.searchDataList).then(
        (res) => {
          this.columnsData = res;
          this.searchData = {
            pk:this.currentRow?this.currentRow.id:res.length > 0 ? res[0].id : "",
            // pk: res.length > 0 ? res[0].id : "",
            portName:this.portName .length > 0? this.portName.toString():'wf',
            apikey: "Sw4Mi3MdjII=",
          };
          this.currentPk = res.length > 0 ? res[0].id : "";
          this.getDetailsList();
        }
      );
    },
     // 选取当行数据
    handleCheck(record, index) {
      return {
        on: {
          click: () => {
            this.searchDataList = {
              shipType: 0, 
            portName:this.portName .length > 0? this.portName.toString():'wf',
              apikey: "Sw4Mi3MdjII=" 
            }
            this.searchData = {
              pk: record.id,
            portName:this.portName .length > 0? this.portName.toString():'wf',
              apikey: "Sw4Mi3MdjII=",
            };
            this.currentRow = record;
            console.log(this.searchData,'111')

            this.getDetailsList();
          },
        },
      };
    },
    getDetailsList() {
      console.log(this.searchData,'222')
      getShipDetail(this.searchData).then((res) => {
        this.shipDetail = res ? res : "";
      });
      getShipTodaySummary(this.searchData).then((res) => {
        this.shipTodaySummary = res;
      });
      getShipMonthSummary(this.searchData).then((res) => {
        this.shipMonthSummary = res;
      });
      getShipTodayBusyCharts(this.searchData).then((res) => {
        this.shipTodayBusyChartsX = res.dataX;
        this.shipTodayBusyChartsY = res.dataY.map(String);
        this.getlineData();
      });
      console.log("qqqqq",this.searchData)
      getShipMonthBusyCharts({
        ...this.searchData,
        ...{
          dateType: parseInt(this.active) + 1 ? parseInt(this.active) + 1 : "1",
        },
      }).then((res) => {
        this.shipMonthBusyChartsX = res.dataX;
        this.shipMonthBusyChartsY = res.dataY.map(String);
        this.getbarData();
      });
    },
   
   

    // 港区选择
    onChange(value) {
      // 默认wf港区
      this.portName = value;
      if (value.toString() === "jz") {
        // alert('济州港区数据正在接通中......')
        getShipList({
          shipType: 0,
          portName: "jz",
          apikey: "Sw4Mi3MdjII=",
        }).then((res) => {
          this.columnsData = res;
          this.currentPk = res.length > 0 ? res[0].id : "";
          this.searchData = {
            pk: res.length > 0 ? res[0].id : "",
            portName: "jz",
            apikey: "Sw4Mi3MdjII=",
          };
          this.searchDataList = {
            shipType: 0, 
            portName: "jz",
            apikey: "Sw4Mi3MdjII=" 
          }
          this.getShipListData()
        });
      } else {
        getShipList({
          shipType: 0,
          portName: "wf",
          apikey: "Sw4Mi3MdjII=",
        }).then((res) => {
          this.columnsData = res;
          this.currentPk = res.length > 0 ? res[0].id : "";
          this.searchDataList = {
            shipType: 0, 
            portName: "wf",
            apikey: "Sw4Mi3MdjII=" 
          }
          this.searchData = {
            pk: res.length > 0 ? res[0].id : "",
            portName: "wf",
            apikey: "Sw4Mi3MdjII=",
          };
          this.getShipListData()
          // this.getDetailsList();
        });
      }
    },
     // 年月日选择
    choseTime(i) {
      this.active = i;
      console.log("bb",this.currentRow.id,this.currentPk)
      this.searchData={
        dateType: i + 1,
        pk: this.currentRow.id ? this.currentRow.id : this.currentPk,
        portName: this.portName.toString() ? this.portName.toString() : "jz",
        apikey: "Sw4Mi3MdjII=",
      }
      getShipMonthBusyCharts(this.searchData).then((res) => {
        this.shipMonthBusyChartsX = res.dataX;
        this.shipMonthBusyChartsY = res.dataY.map(String);
        this.getbarData();
      });
    },
    getlineData() {
      const chart = this.$refs.chart;
      if (chart) {
        const myChart = this.$echarts.init(chart);
        const option = {
          xAxis: {
            type: "category",
            data: this.shipTodayBusyChartsX,
          },
          yAxis: {
            type: "value",
          },
          legend: {
            data: ["集装箱船舶(艘)", "散杂货船舶(艘)"],
            itemGap: 5,
            right: 0,
            top: "0",
            with: 50,
            orient: "vertical",
            textStyle: {
              fontSize: 10,
              color: "#ffffff",
            },
          },
          grid: {
            left: "0%",
            right: "0%",
            bottom: "18%",
            top: "20%",
            containLabel: true,
          },
          series: [
            {
              name: "集装箱船舶(艘)",
              type: "line",
              stack: "Total",
              data: [],
              label: {
                // show: true ,
                position: "`inside`",
              },
            },
            {
              name: "散杂货船舶(艘)",
              type: "line",
              stack: "Total",
              data: this.shipTodayBusyChartsY,
              label: {
                // show: true ,
                position: "`inside`",
              },
            },
          ],
           tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
              label: {
                backgroundColor: "#6a7985",
              },
            },
          },
        };
        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
        });
      }
      this.$on("hook:destroyed", () => {
        window.removeEventListener("resize", function () {
          myChart.resize();
        });
      });
    },
    getbarData() {
      const chart = this.$refs.barchart;
      if (chart) {
        const myChart = this.$echarts.init(chart);
        const option = {
          xAxis: {
            type: "category",
            data: this.shipMonthBusyChartsX,
          },
          yAxis: {
            type: "value",
          },
          grid: {
            left: "0%",
            right: "0%",
            bottom: "48%",
            top: "5%",
            containLabel: true,
          },
          series: [
            {
              data: this.shipMonthBusyChartsY,
              type: "bar",
              itemStyle: {
                normal: {
                  color: {
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                      {
                        offset: 0,
                        color: "#00D6BA", // 0% 处的颜色
                      },
                      {
                        offset: 1,
                        color: "#77FFC1", // 100% 处的颜色
                      },
                    ],
                    globalCoord: false, // 缺省为 false
                  },
                },
              },
            },
          ],
           tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
              label: {
                backgroundColor: "#6a7985",
              },
            },
          },
        };
        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
        });
      }
      this.$on("hook:destroyed", () => {
        window.removeEventListener("resize", function () {
          myChart.resize();
        });
      });
    },
  },
    beforeDestroy() {
    // 在页面销毁后，清除计时器
    this.clear();
    this.destroy()
  },
};
</script>

<style scoped lang="less">
#Ship-cont {
  width: 100%;
  // min-width: 1900px;
  // min-height: 1080px;
  height: 100%;
  background: #000;
  color: #ffffff;
  overflow: hidden;
}
.Ship-cont-box {
  width: 100%;
  height: 100%;
  background: url("../../assets/image/MonitoringCenter-bg.png") no-repeat;
  background-size: 100%;
  position: absolute;
  padding: 0 2.5%;
  box-sizing: border-box;
  .Ship_bg {
    width: 70%;
    height: 100%;
    // 船舶线条背景
    background: url("../../assets/image/ship_bg.png") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    right: 0;
    top: 0;
  }
  .Ship {
    position: relative;
    height: 100%;
  }
  .Ship-title {
    width: 100%;
    height: 5.5rem;
    padding-top: 2rem;
    display: flex;
    justify-content: space-between;
    .Ship-title-left {
      width: 20rem;
      height: 3.3rem;
      line-height: 3.3rem;
      background: url("../../assets/image/button_1.png") no-repeat;
      background-size: 11.6rem 100%;
      text-align: center;
      color: #ffffff;
      position: relative;
      .Ship-chose {
        width: 10.6rem;
        height: 2.9rem;
        margin-top: 0.1rem;
        border: none;
        font-size: 1.2rem;
        color: #ffffff;
        background: #000;
        margin-left: 0;
        position: absolute;
        left: 2px;
      }
    }
    .Ship-title-center {
      width: 60%;
      height: 2.7rem;
      text-align: center;
      line-height: 2rem;
      font-size: 1.5rem;
      letter-spacing: 8px;
      background: url("../../assets/image/title.png") no-repeat;
      background-size: 100% 100%;
    }
    .ship-title-center {
      width: 60%;
      height: 3.2rem;
      text-align: center;
      line-height: 1.6rem;
      font-size: 1.4rem;
      letter-spacing: 8px;
      background: url("../../assets/image/title.png") no-repeat;
      background-size: 100% 100%;
      font-family: "pangmen";
      img:nth-child(2) {
        width: 307px;
        height: 28px;
        margin: 0 44px;
      }
      img:nth-child(1),
      img:nth-child(3) {
        width: 35px;
        height: 35px;
      }
    }

    .ship-title-right {
      float: right;
      width: 306px;
      height: 53px;
      display: flex;
      justify-content: flex-end;
      .ship-title-right-button1,
      .ship-title-right-button2 {
        width: 52px;
        font-size: 20px;
        margin-left: 20px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .Ship-title-right {
      width: 23rem;
      height: 3.3rem;

      .Ship-title-right-button1 {
        width: 10.6rem;
        height: 2.9rem;
        background: url("../../assets/image/button_1.png") no-repeat;
        background-size: 100% 100%;
        font-size: 1.2rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 2.9rem;
        float: right;
        text-align: center;
        letter-spacing: 1px;
      }
    }
  }
}

.Ship-top-echart {
  width: 100%;
  height: 195px;
  display: flex;
  justify-content: space-between;
  margin-top: 31px;
  .Ship-top-echart-left {
    width: 49%;
    height: 100%;
    background: url("../../assets/image/left-border.png") no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    .Ship-port-date {
      width: 50%;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      padding: 0 20px;
      .todayData {
        width: 100%;
        height: 5rem;
        line-height: 5rem;
        font-size: 1.2rem;
        color: #ffffff;
        font-weight: normal;
        span:nth-child(1) {
          color: #ff5c2c;
        }
        span:nth-child(2) {
          display: inline-block;
          font-size: 0.9rem;
          margin-left: 1.1rem;
        }
      }
      .Ship-port-date-num {
        display: flex;
        justify-content: space-between;
        position: relative;
        margin-top: 0px;
        .line {
          width: 2px;
          height: 40px;
          background: #979797;
          display: inline-block;
          position: absolute;
          top: 25px;
          left: 20%;
        }
        div {
          width: 33.3%;
          height: 100%;
          p:nth-child(1) {
            width: 100%;
            height: 1.2rem;
            font-size: 12px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ffffff;
            line-height: 1.2rem;
            span {
              color: #ff5c2c;
            }
          }
          p:nth-child(2) {
            width: 100%;
            height: 4rem;
            font-size: 2rem;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ffffff;
            height: 4rem;
          }
        }
      }
    }
    .Ship-busy {
      width: 49%;
      p {
        width: 92%;
        height: 5rem;
        line-height: 5.5rem;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        span:nth-child(1) {
          color: #ff5c2c;
        }
        span:nth-child(2) {
          display: inline-block;
          float: right;
          font-size: 0.4rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
        }
      }
    }
  }
  .Ship-top-echart-right {
    width: 49%;
    height: 100%;
    background: url("../../assets/image/left-border.png") no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    .Ship-port-date {
      width: 50%;
      font-size: 22px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ff5c2c;
      padding: 0 20px;
      .todayData {
        width: 100%;
        height: 5rem;
        line-height: 5rem;
        font-size: 1.2rem;
        color: #ffffff;
        font-weight: normal;

        span:nth-child(1) {
          color: #ff5c2c;
        }
        span:nth-child(2) {
          display: inline-block;
          font-size: 0.9rem;
          margin-left: 10px;
        }
      }
      .Ship-port-date-num {
        display: flex;
        justify-content: space-between;
        margin-top: 0px;
        div {
          width: 33.3%;
          height: 100%;
          .color_active {
            color: #ff5c2c;
          }
          p:nth-child(1) {
            width: 100%;
            height: 1.2rem;
            font-size: 12px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ffffff;
            line-height: 1.2rem;
          }
          p:nth-child(2) {
            width: 100%;
            height: 56px;
            font-size: 2rem;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ffffff;
            line-height: 4rem;
          }
        }
      }
    }
    .Ship-busy {
      width: 49%;
      p {
        width: 100%;
        height: 5rem;
        line-height: 5.5rem;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        display: flex;
        justify-content: space-between;
        .spanbox {
          width: 5.8rem;
          background: rgba(46, 47, 48, 0.5);
          line-height: 2rem;
          font-size: 0.6rem;
          text-align: center;
          height: 2rem;
          display: flex;
          justify-content: space-between;
          display: inline-block;
          margin-top: 1.9rem;
          span {
            width: 1.9rem;
            height: 100%;
            display: inline-block;
          }
          .active {
            color: #ff5c2c;
          }
        }
      }
    }
  }
}
.Ship-bottom {
  width: 100%;
  margin-top: 2.3rem;
  width: 100%;
  height: calc(100% - 22rem);
  display: flex;
  justify-content: space-between;
  .Ship-bottom-table {
    width: 60%;
    height: 100%;
    // border: 1px solid;
    .Ship-bottom-table-title {
      width: 100%;
      height: 3.5rem;
      background: url("../../assets/image/left-title.png") no-repeat;
      background-size: 100% 100%;
      p {
        width: 92%;
        margin: auto;
        line-height: 3.5rem;
        font-size: 1.2rem;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        span {
          line-height: 3.5rem;
          font-size: 0.8rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          display: inline-block;
          margin-left: 1rem;
        }
      }
    }
    .Ship-bottom-table-cont {
      width: 100%;
      padding: 1.2rem;
      margin-top: 0.7rem;
      margin-bottom: 1rem;
      height: calc(100% - 5.2rem);
      background: url("../../assets/image/left-table-border.png") no-repeat;
      background-size: 100% 100%;
      .Ship-bottom-table-history {
        width: 252px;
        height: 2.8rem;
        line-height: 2.8rem;
        background: rgba(46, 47, 48, 0.75);
        justify-content: space-between;
        box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.5);
        span {
          display: inline-block;
          width: 126px;
          text-align: center;
          line-height: 2.8rem;
        }
      }
      .table-box {
        height: 100%;
        overflow: hidden;
      }
    }
    
  }
  .Ship-bottom-data {
    width: 39%;
    height: 100%;

    .Ship-bottom-data-title {
      width: 100%;
      height: 3.5rem;
      background: url("../../assets/image/left-title.png") no-repeat;
      background-size: 100% 100%;
      p {
        width: 92%;
        margin: auto;
        line-height: 3.5rem;
        font-size: 1.2rem;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        span {
          line-height: 3.5rem;
          font-size: 0.8rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          display: inline-block;
          margin-left: 1rem;
        }
      }
    }
    .Ship-bottom-data-cont {
      width: 100%;
      margin-top: 0.7rem;
      margin-bottom: 1rem;
      height: calc(100% - 5.2rem);
      background: url("../../assets/image/left-table-border.png") no-repeat;
      background-size: 100% 100%;
      // overflow-y: scroll;
      // overflow: hidden;
      .Ship-bottom-data-currentinfo {
        width: 100%;
        height: 184px;
        padding: 35px 37px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        .left {
          width: 58%;
          height: 100%;
          .left-top {
            width: 100%;
            height: 50%;
            display: flex;
            justify-content: space-between;
            div {
              p:nth-child(1) {
                width: 100%;
                height: 40px;
                font-size: 26px;
                font-family: PingFangSC-Semibold, PingFang SC;
                color: #ffffff;
                line-height: 40px;
              }
              P:nth-child(2) {
                width: 100%;
                height: 22px;
                font-size: 14px;
                font-family: PingFangSC-Semibold, PingFang SC;
                color: rgba(255, 255, 255, 0.5);
                line-height: 22px;
                span {
                  display: inline-block;
                  margin: 0 10px;
                }
              }
            }
            div:nth-child(2) {
              p:nth-child(1) {
                color: #00b994;
              }
            }
          }
          .left-bottom {
            width: 60%;
            height: 50%;
            display: flex;
            justify-content: space-between;
            margin-top: 30px;
            div:nth-child(1) {
              font-size: 16px;
              font-family: PingFangSC-Semibold, PingFang SC;
              color: #ffffff;
              line-height: 22px;
              img {
                width: 20px;
                height: 15px;
                margin-right: 5px;
              }
            }
            div:nth-child(2) {
              font-size: 16px;
              font-family: PingFangSC-Semibold, PingFang SC;
              color: #ffffff;
              line-height: 22px;
              img {
                width: 12px;
                height: 15px;
                margin-right: 5px;
              }
            }
          }
        }
        .right {
          width: 35%;
          height: 100%;
          position: relative;
          .photo {
            width: 6rem;
            height: 1.8rem;
            text-align: center;
            line-height: 1.8rem;
            position: absolute;
            font-size: 10px;
            background: rgba(46, 47, 48, 0.75);
            box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.5);
            img {
              width: 0.9rem;
              height: 0.9rem;
            }
          }
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .Ship-bottom-data-weight {
        width: 100%;
        height: 17.54%;
        display: flex;
        background: rgba(46, 47, 48, 0.5);
        justify-content: space-between;
        .left {
          width: 33%;
          display: flex;
          justify-content: space-between;
          div {
            width: 50%;
            height: 100%;
            padding: 19px 14px;
            p:nth-child(1) {
              width: 100%;
              height: 22px;
              font-size: 16px;
              font-family: PingFangSC-Semibold, PingFang SC;
              text-align: center;
              color: rgba(255, 255, 255, 0.5);
              line-height: 22px;
            }
            p:nth-child(2) {
              width: 100%;
              height: 40px;
              font-size: 28px;
              text-align: center;
              font-family: PingFangSC-Semibold, PingFang SC;
              color: #ffffff;
              line-height: 40px;
            }
            .color {
              color: #00b994 !important;
            }
          }
        }
        .center {
          width: 33%;
          padding: 19px 14px;
          p:nth-child(1) {
            width: 100%;
            height: 22px;
            font-size: 16px;
            font-family: PingFangSC-Semibold, PingFang SC;
            text-align: center;
            color: rgba(255, 255, 255, 0.5);
            line-height: 22px;
          }
          p:nth-child(2) {
            width: 100%;
            height: 40px;
            font-size: 28px;
            text-align: center;
            font-family: PingFangSC-Semibold, PingFang SC;
            color: #ffffff;
            line-height: 40px;
          }
        }
        .right {
          width: 33%;
          display: flex;
          justify-content: space-between;
          padding: 19px 14px;
          div:nth-child(1) {
            width: 50%;
            p:nth-child(1) {
              width: 100%;
              height: 22px;
              font-size: 16px;
              font-family: PingFangSC-Semibold, PingFang SC;
              text-align: center;
              color: rgba(255, 255, 255, 0.5);
              line-height: 22px;
            }
          }
          div:nth-child(2) {
            width: 50%;
            p:nth-child(1) {
              width: 100%;
              height: 28px;
              font-size: 20px;
              font-family: PingFangSC-Semibold, PingFang SC;
              color: #ffffff;
              line-height: 28px;
              text-align: center;
            }
            P:nth-child(2) {
              width: 100%;
              height: 40px;
              font-size: 28px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #ffffff;
              line-height: 40px;
              text-align: center;
            }
          }
        }
        .bgGrey{
          background: rgba(46, 47, 48, 0.5);
        }
      }
      .Ship-bottom-data-time {
        width: 100%;
        height: 104px;
        line-height: 104px;
        display: flex;
        justify-content: space-around;
        box-sizing: border-box;
        padding: 16px 0;
        .enter {
          width: 80px;
          height: 104px;
          line-height: 69px;
          font-size: 16px;
          font-family: PingFangSC-Semibold, PingFang SC;
          color: #ff5c2c;
          text-align: left;
        }
        .out {
          width: 80px;
          line-height: 69px;
          font-size: 16px;
          font-family: PingFangSC-Semibold, PingFang SC;
          color: rgba(255, 255, 255, 0.5);
          text-align: right;
        }
        .enter-time {
          width: 35%;
          height: 100%;
          p:nth-child(1) {
            width: 100%;
            text-align: center;
            font-size: 16px;
            font-family: PingFangSC-Semibold, PingFang SC;
            color: #ff5c2c;
            line-height: 30px;
          }
          p:nth-child(2) {
            width: 100%;
            text-align: center;
            height: 3rem;
            font-size: 32px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ffffff;
            line-height: 2rem;
          }
        }
        .out-time {
          width: 35%;
          height: 100%;
          p:nth-child(1) {
            width: 100%;
            text-align: center;
            height: 1.5rem;
            font-size: 16px;
            line-height: 2.3rem;
            font-family: PingFangSC-Semibold, PingFang SC;
            color: rgba(255, 255, 255, 0.5);
          }
          p:nth-child(2) {
            width: 100%;
            text-align: center;
            height: 3rem;
            font-size: 32px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ffffff;
            line-height: 2rem;
          }
        }
        .arrowhead {
          width: 50px;
          height: 35px;
          background: url("../../assets/image/triangle.png") no-repeat;
          background-size: 100% 100%;
          margin-top: 15px;
        }
      }
      .Ship-bottom-data-specific {
        background: rgba(46, 47, 48, 0.5);
        width: 100%;
        height: 25.3%;
        margin-top: -0.5rem;
        padding: 20px;
        display: flex;
        justify-content: space-between;
        .left {
          width: 5px;
          margin-top: 12px;
          div:nth-child(1) {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: #74fec1;
          }
          div:nth-child(2) {
            width: 1px;
            margin: 5px 4px;
            height: 3.6rem;
            border-right: 1px dashed rgba(255, 255, 255, 0.5);
          }
          div:nth-child(3) {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: #ff5c2c;
          }
        }
        .right {
          width: 96%;
          height: 100%;
          display: flex;
          justify-content: space-around;
          flex-wrap: wrap;
          margin-top: 0.3rem;
          div {
            width: 50%;
            text-align: center;
            margin-bottom: 20px;
            p:nth-child(1) {
              width: 100%;
              text-align: left;
              height: calc(100% -0.3rem);
              font-size: 0.7rem;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: rgba(255, 255, 255, 0.5);
              line-height: calc(100% -0.3rem);
              margin-bottom: 3px;
            }
            p:nth-child(2) {
              width: 100%;
              text-align: left;
              height: calc(100% -2.3rem);
              font-size: 10px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #ffffff;
              line-height: calc(100% -2.3rem);
              margin-bottom: 3px;
            }
          }
        }
      }
    }
  }
}
.actives {
  color: #ff5c2c;
  background: #000;
  opacity: 0.8;
}
</style>

<template>
  <div id="Equipment-cont">
    <div class="Equipment-cont-box">
      <div class="Equipment_bg"></div>
      <div class="Equipment">
        <div class="Equipment-title">
          <div class="Equipment-title-left">
            <a-cascader
              class="Equipment-chose"
              :field-names="{ label: 'name', value: 'code', children: 'items' }"
              :options="options"
              :allowClear="false"
              placeholder="万丰港区"
              @change="onChange"
            />
          </div>
          <div class="sale-title-center">
            <img src="../../assets/image/left.png" alt="" @click="golast()"/>
            <img src="../../assets/image/equipment-title.png" alt="" />
            <img src="../../assets/image/right.png" alt="" @click="gonext()"/>
          </div>
          <div class="sale-title-right">
            <div class="sale-title-right-button1">
              <img src="../../assets/image/back.png" alt="" @click="goback()" />
            </div>
            <div class="sale-title-right-button1">
               <img src="../../assets/image/fullScreen.png" alt=""  v-if="!isFullscreen"  @click="f11Click" />
              <img src="../../assets/image/fullScreenUn.png" alt=""   v-else  @click="f11Click" />
            </div>
          </div>
        </div>
        <div class="Equipment-top-echart">
          <div class="Equipment-top-echart-left">
            <div class="Equipment-port-date">
              <p class="todayData">
                <span></span>当前港内设备情况<span
                   style="font-size:16px"> Current Equipment condition in Port</span
                >
              </p>
              <div class="Equipment-port-date-num">
                <div class="data_1">
                  <p class="data_p1">港内生产设备总数</p>
                  <p class="data_p2">
                    {{ deviceStatus.val1 ? deviceStatus.val1 : "-" }}
                  </p>
                  <div class="line_1"></div>
                </div>
                <div class="data_2">
                  <div>
                    <p class="data_p1">装卸设备数</p>
                    <p class="data_p2">
                      {{ deviceStatus.val2 ? deviceStatus.val2 : "-" }}
                    </p>
                  </div>
                  <div>
                    <p class="data_p1">运输设备数</p>
                    <p class="data_p2">
                      {{ deviceStatus.val3 ? deviceStatus.val3 : "-" }}
                    </p>
                  </div>
                  <div class="line_2"></div>
                </div>
                <div class="data_3">
                  <div>
                    <p class="data_p3">工作中</p>
                    <p class="data_p2">
                      {{ deviceStatus.val4 ? deviceStatus.val4 : "-" }}
                    </p>
                  </div>
                  <div class="line_3"></div>
                </div>

                <div class="data_4">
                  <div>
                    <p class="data_p1">空闲中</p>
                    <p class="data_p2">
                      {{ deviceStatus.val5 ? deviceStatus.val5 : "-" }}
                    </p>
                  </div>
                  <div>
                    <p class="data_p4">故障/维修中</p>
                    <p class="data_p2">
                      {{ deviceStatus.val6 ? deviceStatus.val6 : "-" }}
                    </p>
                  </div>
                  <div>
                    <p class="data_p5">保养中</p>
                    <p class="data_p2">
                      {{ deviceStatus.val7 ? deviceStatus.val7 : "-" }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="Equipment-top-echart-right">
            <div class="Equipment-port-date">
              <div class="todayData">
                <span></span>能耗及维保费用<span style="font-size:16px"
                  > Customer Data of This Month</span
                >
                <div class="spanbox">
                  <span
                    v-for="(item, i) in datalist"
                    :key="i"
                    :class="{ active: active == i }"
                    @click="choseTime(i)"
                    style="font-size:10px"
                  >
                    {{ item }}
                  </span>
                </div>
              </div>
              <div class="Equipment-port-date-num">
                <div>
                  <p>本月累计 <span>电耗</span>(度)</p>
                  <p>{{ EnergyLossCost.val1 ? EnergyLossCost.val1 : "-" }}</p>
                </div>
                <div>
                  <p>本月累计 <span>油费</span>(万元)</p>
                  <p>{{ EnergyLossCost.val2 ? EnergyLossCost.val2 : "-" }}</p>
                </div>
                <div>
                  <p>本月累计 <span>维保费</span>(万元)</p>
                  <p>{{ EnergyLossCost.val3 ? EnergyLossCost.val3 : "-" }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="Equipment-bottom">
          <div class="Equipment-bottom-left">
            <div class="todayData">
              <span></span>当前单个设备信息<span
                >Current Equipment condition in Port</span
              >
              <div class="spanbox">
                <span
                  v-for="(item, i) in typeList"
                  :key="i"
                  :class="{ active: actives == i }"
                  @click="choseTypeList(i)"
                  style="font-size:10px"
                >
                  {{ item }}
                </span>
              </div>
            </div>
            <div class="Equipment-info-box" id="Equipment-info-box">
               <div class="Equipment-info-box-mask"></div>
              <div
                class="Equipment-info-content"
                id="Equipment-info-content"
                @mousedown="mouseMoveEvent"
              >
                <div
                  v-for="(item, i) in CurrentDeviceStatus"
                  :key="i"
                  class="infolistItem"
                  :class="
                    currentIndex === i
                      ? 'Equipment-infolist1'
                      : 'Equipment-infolist'
                  "
                  @click="giveEquipmentId(item.pk,i)"
                >
                  <span class="triangle"></span>
                  <div class="Equipment-infolist-top">
                    <div
                      :class="
                        item.val2 === '待机'
                          ? 'imgBox-leisure'
                          : item.val2 === '工作'
                          ? 'imgBox-working'
                          : item.val2 === '维护'
                          ? 'imgBox-malfunction'
                          : item.val2 === '保养'
                          ? 'imgBox-maintain'
                          : 'imgBox-leisure'
                      "
                    >
                      <img  v-if="item.img" :src="picurlqz+item.img"/>
                      
                      <!-- <img v-if="item.val2 === '待机'" src="../../assets/img/shebei1.png" alt=""  />
                      <img v-if="item.val2 === '工作'" src="../../assets/img/shebei2.png" alt=""  />
                      <img v-if="item.val2 === '维护'" src="../../assets/img/shebei3.png" alt=""  />
                      <img v-if="item.val2 === '保养'" src="../../assets/img/shebei4.png" alt=""  /> -->
                      <img v-else src="../../assets/img/shebei5.png" alt=""  />
                      <span
                        :class="
                          item.val2 === '待机'
                            ? 'leisure'
                            : item.val2 === '工作'
                            ? 'working'
                            : item.val2 === '维护'
                            ? 'malfunction'
                            : item.val2 === '保养'
                            ? 'maintain'
                            : 'leisure'
                        "
                      >
                        {{ item.val2 ? item.val2 : "-" }}</span
                      >
                    </div>
                    <div class="right">
                      <p>{{ item.val1 ? item.val1 : "-" }}</p>
                      <p>{{ item.val3 ? item.val3 : "-" }}</p>
                    </div>
                  </div>
                  <div class="Equipment-infolist-center">
                    {{ item.val4 ? item.val4 : "-" }}
                  </div>
                  <div class="Equipment-infolist-bottom">
                    <div>
                      <p>本月电耗(度)</p>
                      <p>{{ item.val5 ? item.val5 : "-" }}</p>
                    </div>
                    <span class="line"></span>
                    <div>
                      <p>本月维保(元)</p>
                      <p>{{ item.val6 ? item.val6 : "-" }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
           
            <div class="Equipment-left-bottom">
              <div class="Equipment-left-bottom-left">
                <div class="todayData">
                  <span>工作记录</span>
                </div>
                <div class="table-box" id="table-box">
                  <a-table
                    :columns="columns"
                    :data-source="WorklogByDevice"
                    :pagination="false"
                    :scroll="{ y: tableHeight }"
                    :locale="locale"
                    style="color: #000; overflow-x: hidden; overflow-y: hidden"
                  />
                </div>
              </div>
              <div class="Equipment-left-bottom-center">
                <div class="todayData">
                  <span></span>该设备本月耗电量变化(度)<span></span>
                  <div class="spanbox">
                    <span
                      v-for="(item, i) in consumeType"
                      :key="i"
                      :class="{ active: active3 == i }"
                      @click="choseconsumeType(i)"
                      style="font-size:10px"
                    >
                      {{ item }}
                    </span>
                  </div>
                </div>
                <div ref="linechart" style="width: 100%; height: 220px"></div>
              </div>
              <div class="Equipment-left-bottom-right">
                <div class="Equipment-title">
                  <p>维修故障次数各月份占比</p>
                  <p>累计故障次数：{{ DeviceIdnum ? DeviceIdnum : "-" }}</p>
                </div>
                <div ref="piechart" style="width: 100%; height: 170px"></div>
              </div>
            </div>
          </div>
          <div class="Equipment-bottom-right">
            <div class="top">
              <div class="todayData">
                <span>本月能耗及维保费用排名</span>
                <div class="spanbox">
                  <span
                    v-for="(item, i) in rankingList"
                    :key="i"
                    :class="{ active: rankingactive == i }"
                    @click="choserankingList(i)"
                    style="font-size:10px"
                  >
                    {{ item }}
                  </span>
                </div>
              </div>
              <div ref="barchart" style="width: 100%; height: 100%"></div>
            </div>
            <div class="bottom">
              <div class="todayData">
                <span>各设备维修故障次数与费用占比对比</span>
              </div>
              <div class="pieBox">
                <div>
                  <div
                    class="ecahrt"
                    ref="maintenancechart"
                    style="width: 100%; height: 88%"
                  ></div>
                  <div class="circle">
                    本月 <span>维修次数</span>
                    <p>
                      {{
                        MaintainNumCostChart.num1
                          ? MaintainNumCostChart.num1
                          : "-"
                      }}
                    </p>
                  </div>
                  <p>本月 <span>维修次数</span>占比</p>
                </div>
                <div>
                  <div
                    class="ecahrt"
                    ref="moneychart"
                    style="width: 100%; height: 88%"
                  ></div>
                  <div class="circle">
                    <span>维修费用(万元)</span>
                    <p>
                      {{
                        MaintainNumCostChart.num2
                          ? MaintainNumCostChart.num2
                          : "-"
                      }}
                    </p>
                  </div>
                  <p>本月 <span>维修费用</span>占比</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const options = [
  {
    code: "wf",
    name: "万丰港区",
  },
];
const columns = [
  {
    title: "工作时间",
    dataIndex: "val1",
    width: 130,
  },
  {
    title: "工作量",
    dataIndex: "val2",
    width: 70,
    // fixed: 'left',
  },
  {
    title: "时长",
    dataIndex: "val3",
    width: 65,
  },
];

import {
  getDeviceStatus,
  getEnergyLossCost,
  getCurrentDeviceStatus,
  getWorklogByDeviceId,
  getEnergyLossRank,
  getEnergyLossByDeviceId,
  getMaintainNumByDeviceId,
  getMaintainNumCostChart,
} from "../../http/index.js";
import screenfull from 'screenfull' // 全屏功能
export default {
  name: "Equipment",
  data() {
    return {
      picurlqz:'https://sfghjt.com/image/',
      isFullscreen: false,
      currentIndex: 0,
      active: "0",
      actives: "0",
      active3: "0",
      rankingactive: "0",
      columns,
      options,
      datalist: ["月", "年"],
      typeList: ["全部", "装卸", "运输"],
      consumeType: ["电耗", "油耗"],
      rankingList: ["电", "油", "维保"],
      tableHeight: 0,
      params: {},
      params1: {},
      portName: "",
      EnergyLossCost: "",
      dateType: "",
      deviceStatus: "",
      CurrentDeviceStatus: "",
      deviceId: "",
      WorklogByDevice: [],
      energyType: "",
      EnergyLossRankY: [],
      EnergyLossRankX: [],
      energyTypeId: "",
      EnergyLossByDeviceIdX: [],
      EnergyLossByDeviceIdY: [],
      MaintainNumByDeviceId: [],
      DeviceIdnum: "",
      MaintainNumCostChart: "",
      intervalId: null,
      locale:{
        emptyText: '暂无数据'
      }
    };
  },
  created() {
    this.params = {
      apikey: "Sw4Mi3MdjII=",
      portName: this.portName ? this.portName.toString() : "jz",

    };

    this.getCommonData()
    this.initHeight();
    this.dataRefreh();
  },
  mounted() {
    document.addEventListener("mouseup", this.mouseUpEvent);
    this.$nextTick(() => {
      this.initHeight();
      this.init()
      this.isFullscreen=screenfull.isFullscreen
    });
    window.addEventListener('keydown', this.KeyDown, true)// 监听按键事件
  },
  beforeDestroy() {
    // 在页面销毁后，清除计时器
    this.clear();
    this.destroy()
    document.removeEventListener("mouseup", this.mouseUpEvent);
  },

  methods: {
    KeyDown(event) {
      if (event.keyCode === 122) {
        // 禁用f11
        event.returnValue = false
        // 触发全屏的按钮
        this.f11Click()
      }
    },
    f11Click() {
      if (!screenfull.isEnabled) {
        this.$message({
          message: '浏览器不能全屏',
          type: 'warning'
        })
        return false
      }
      screenfull.toggle()
    },
    change() {
      this.isFullscreen = screenfull.isFullscreen
    },
    init() {
      if (screenfull.isEnabled) {
        screenfull.on('change', this.change) // ！！！！这儿方法名不能带括号
      }
    },
    destroy() {
      if (screenfull.isEnabled) {
        screenfull.off('change', this.change) // ！！！！这儿方法名不能带括号
      }
    },
    // 返回大屏
    goback() {
      this.$router.push({
        path: "/MonitoringCenter",
      });
    },
    golast(){
      console.log('qq')
      this.$router.push({
        path:'/MonitoringCenter'
      })
    },
    gonext(){
      console.log('qq')
      this.$router.push({
        path:'/SaleCenter'
      })
    },
    calculateSliderWidth() {
      const width = document.getElementById("Equipment-info-box").clientWidth;
      const contentArr = document.getElementsByClassName("infolistItem");
      console.log(width,contentArr,'contentArr')
      document.getElementById("Equipment-info-content").style.width =
        (contentArr.length+1) * (width / 4.5) + 20 + "px";
      for (let i = 0; i < contentArr.length; i++) {
        contentArr[i].style.width = width / 4.5 + "px";
      }
    },
    mouseMoveEvent(e) {
      //记录鼠标按下瞬间的位置
      this.currentX = e.clientX;
      const ele = document.getElementById("Equipment-info-content");
      this.divX = ele.offsetLeft;
      ele.addEventListener("mousemove", this.mouseMoveReal);
    },
    mouseMoveReal(x) {
      const event = event || window.event;
      const ele = document.getElementById("Equipment-info-content");
      //实时监听鼠标位置
      const moveX = event.clientX - this.currentX;
      ele.style.left = moveX + this.divX + "px";
    },
    mouseUpEvent() {
      const ele = document.getElementById("Equipment-info-content");
      ele.removeEventListener("mousemove", this.mouseMoveReal);
      const width = document.getElementById("Equipment-info-box").clientWidth;
      const eleWidth = ele.clientWidth;
      const eleLeftg = ele.style.left.replace("px", "") * 1;

      if (eleLeftg >= 0) {
        ele.style.left = 0;
      } else if (Math.abs(eleLeftg) >= eleWidth - width - 20) {
        ele.style.left = -(eleWidth - width - 20) + "px";
      }
    },
    initHeight() {
      const tableBox = document.getElementById("table-box");
      const height = tableBox.offsetHeight - 50;
      this.tableHeight = height;
      this.tableHeight = 280;
    },
    // 定时刷新数据函数
    dataRefreh() {
      // 计时器正在进行中，退出函数
      if (this.intervalId !== null) {
        return;
      }
      // 计时器为空，操作
      this.intervalId = setInterval(() => {
        this.getCommonData();
      }, 60000*5);
    },
    // 停止定时器
    clear() {
      this.intervalId && clearInterval(this.intervalId); //清除计时器
    },
    // 港区选择
    onChange(value) {
      
      this.portName = value;
      if (value.toString() === "wf") {
        this.params = {
          apikey: "Sw4Mi3MdjII=",
          portName: "wf",
        };
        this.getCommonData();
      } else {
        // alert('济州港区数据正在接通中......')
        this.params = {
          apikey: "Sw4Mi3MdjII=",
          portName: "jz",
        };
        this.getCommonData();
      }
    },
    // 调用所有接口
    getCommonData() {
      this.getDetails();
      this.getEquipmentList();
      this.getEnergyLossCostlist();
      this.getEnergyLossRankList();
    },
    // 能耗极维保时间选择
    choseTime(i) {
      this.active = i;
      this.dateType = (i + 2).toString()
      this.getEnergyLossCostFun()
    },

    // 当前单个设备信息
    choseTypeList(i) {
      this.actives = i;
      this.currentIndex = 0;
      let dateType = 0;
      if (i === 0) {
        dateType = 1;
      } else if (i === 1) {
        dateType = 2;
      } else {
        dateType = 3;
      }
      this.getCurrentDeviceStatusFun(dateType);
    },
    giveEquipmentId(id,i) {
      this.deviceId = id;
      this.currentIndex = i;
      this.params = {
        ...this.params,
        deviceId: id,
      };
      this.getEquipmentList();
      this.getEnergyLossCostlist({
        ...this.params,
        energyType: this.energyTypeId ? this.energyTypeId : "1",
      });
    },
    //    本月耗电量
    choseconsumeType(i) {
      this.active3 = i;
      const energyType = (i + 1).toString();
      this.getEnergyLossCostlist({ ...this.params, energyType });
    },

    //本月能耗及维保费用排名
    choserankingList(i) {
      this.rankingactive = i;
      const energyType = (i + 1).toString();
      this.getEnergyLossRankList({ ...this.params, energyType });
    },
    getEnergyLossCostlist(params) {
      getEnergyLossByDeviceId({
        ...this.params,
        deviceId: this.deviceId || "1",
        ...params,
      }).then((res) => {
        this.EnergyLossByDeviceIdX = res.dataX;
        this.EnergyLossByDeviceIdY = res.dataY;
        this.getlineData();
      });
    },
    getEquipmentList() {
      getWorklogByDeviceId({
        ...this.params,
        deviceId: this.deviceId ? this.deviceId : "1",
      }).then((res) => {
        this.WorklogByDevice = res;
      });
      getMaintainNumByDeviceId({
        ...this.params,
        deviceId: this.deviceId ? this.deviceId : "1",
      }).then((res) => {
        this.MaintainNumByDeviceId = res.children;
        this.DeviceIdnum = res.totalNum;
        this.getpieData();
      });
    },
    getEnergyLossRankList(param) {
      getEnergyLossRank({
        ...this.params,
        energyType: this.energyType || "1",
        ...param,
      }).then((res) => {
        (this.EnergyLossRankX = res.dataX), (this.EnergyLossRankY = res.dataY);
        this.getbarData();
      });
    },
    getDetails() {
      getDeviceStatus(this.params).then((res) => {
        this.deviceStatus = res;
      });
      this.getEnergyLossCostFun()
      this.getCurrentDeviceStatusFun()
      getMaintainNumCostChart(this.params).then((res) => {
        this.MaintainNumCostChart = res;
        this.getpieDatamain();
        this.getpieDatamoney();
      });
    },
    getCurrentDeviceStatusFun(dateType = '1') {
      getCurrentDeviceStatus({ ...this.params, dateType }).then((res) => {
        this.CurrentDeviceStatus = res;
        this.deviceId = res[0].pk;
        this.params = {
          ...this.params,
          deviceId: this.deviceId,
        };
        const ele = document.getElementById("Equipment-info-content");
        ele.style.left = 0 + "px";
        this.giveEquipmentId(this.deviceId,0);
        setTimeout(() => {
          this.calculateSliderWidth();
        }, 500);
      });
    },
    getEnergyLossCostFun() {
      getEnergyLossCost({
        ...this.params,
        dateType: this.dateType || "2",
      }).then((res) => {
        this.EnergyLossCost = res;
      });
    },
    getbarData() {
      const option = {
        xAxis: {
          type: "category",
          data: this.EnergyLossRankX,
        },
        yAxis: {
          type: "value",
        },
        grid: {
          top: "10%",
          left: "0%",
          right: "0%",
          bottom: "18%",
          containLabel: true,
        },

        series: [
          {
            data: this.EnergyLossRankY,
            type: "bar",
            itemStyle: {
              normal: {
                color: {
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "#FA6400", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "rgba(119, 255, 193,0)", // 100% 处的颜色
                    },
                  ],
                  globalCoord: false, // 缺省为 false
                },
              },
            },
          },
        ],
        tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
              label: {
                backgroundColor: "#6a7985",
              },
            },
          },
      };
      this.initChart(option, "barchart");
    },
    getlineData() {
      const option = {
        xAxis: {
          type: "category",
          data: this.EnergyLossByDeviceIdX,
          splitLine: {
              show: true,
              lineStyle:{
                 color: ['#8c8c8c'],
                 width: 1,
                 type: 'dashed'
             }
　　      }
        },
        yAxis: {
          type: "value",
          splitLine: {
              show: true,
              lineStyle:{
                 color: ['#8c8c8c'],
                 width: 1,
                 type: 'dashed'
             }
　　      }
        },
        grid: {
          top: "10%",
          left: "4%",
          right: "4%",
          bottom: "10%",
          containLabel: true,
        },
        series: [
          {
            data: this.EnergyLossByDeviceIdY,
            type: "line",
            // smooth: true,
            areaStyle: {
              normal: {
                color: {
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "#FA6400", // 0% 处的颜色
                    },
                    {
                      offset: 0.7,
                      color: "rgba(119, 255, 193,0)", // 100% 处的颜色
                    },
                  ],
                  globalCoord: false, // 缺省为 false
                },
              },
            },
          },
        ],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
      };
      this.initChart(option, "linechart");
    },
    getpieData() {
      const option = {
        tooltip: {
          trigger: "item",
        },
        color: [
          "#FF722C",
          "#FF8D1A",
          "#59E7A6",
          "#59E77B",
          "#FFAA2C",
          "#00D0A6",
        ],
        series: [
          {
            name: "故障次数",
            type: "pie",
            radius: "90%",
            label: {
              show: false,
              position: "center",
            },
            data: this.MaintainNumByDeviceId,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ]
      };
      this.initChart(option, "piechart");
    },

    getpieDatamain() {
      const option = {
        tooltip: {
          trigger: "item",
        },
        color: [
          "#FF722C",
          "#FF8D1A",
          "#59E7A6",
          "#59E77B",
          "#FFAA2C",
          "#00D0A6",
        ],
        series: [
          {
            name: "维修次数",
            type: "pie",
            radius: ["60%", "85%"],
            center: ["50%", "45%"],
            label: {
              show: false,
              position: "center",
            },
            data: this.MaintainNumCostChart.list1,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ]
      };
      this.initChart(option, "maintenancechart");
    },
    getpieDatamoney() {
      const option = {
        tooltip: {
          trigger: "item",
        },
        color: [
          "#FF722C",
          "#FF8D1A",
          "#59E7A6",
          "#59E77B",
          "#FFAA2C",
          "#00D0A6",
        ],
        series: [
          {
            name: "维修费用",
            type: "pie",
            radius: ["60%", "85%"],
            center: ["50%", "45%"],
            label: {
              show: false,
              position: "center",
            },
            data: this.MaintainNumCostChart.list2,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],

      };
      this.initChart(option, "moneychart");
    },
    // 初始化图表
    initChart(option, ele) {
      const chart = this.$refs[ele];
      if (chart) {
        const myChart = this.$echarts.init(chart);
        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
        });
      }
      this.$on("hook:destroyed", () => {
        window.removeEventListener("resize", function () {
          myChart.resize();
        });
      });
    },
  },
};
</script>

<style scoped lang="less">
@import "./index.less";
</style>
<style >
.Equipment-cont-box .ant-table-thead > tr > th,.Equipment-cont-box .ant-table-tbody > tr > td {
  padding: 8px 6px;
  overflow-wrap: break-word;
}
</style>
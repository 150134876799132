<template>
  <div class="content">
    <div class="dispatch_title">
      <img src="../../assets/content/title.png" alt="">
    </div>
    <div class="dispath_content">
      <div class="dispath_cont_left">
        <div class="top">
          <p>监控中心</p>
          <div class="left_box">
            <div class="dibang" >
              <p>地磅区</p>
              <div class="videobox" v-for="(item, index) in CameraListData.slice(0,3) " :key="index">
                <video
                :id="'video' + item.id"
                controls
                autoplay
                muted
                type='video/mp4'
              ></video>
              <span class="info">地磅#1</span>
              </div>
            </div>
            <div class="dibang">
              <p>泊位装卸区</p>
              <div class="videobox" v-for="(item, index) in CameraListData.slice(3,6)" :key="index">
                <video
                :id="'video' + item.id"
                controls
                autoplay
                muted
                type='video/mp4'
              ></video>
              </div>
            </div>
            <div class="dibang">
              <p>堆场区</p>
              <div class="videobox" v-for="(item, index) in CameraListData.slice(6,9)" :key="index">
                <video
                :id="'video' + item.id"
                controls
                autoplay
                muted
                type='video/mp4'
              ></video>
              </div>
            </div>
          </div>
           <div class="mask"></div>
        </div>
        <div class="center">
          <p>车辆生产</p>
          <div class="center-title">
            <img src="../../assets/content/car.png" alt="">
            <div>
              <p>{{VehicleSummaryData.val1 ? VehicleSummaryData.val1 : '-'}}</p>
              <p>计划<span>(吨)</span></p>
            </div>
            <div>
              <p>{{VehicleSummaryData.val2 ? VehicleSummaryData.val2 : '-'}}</p>
              <p>实际<span>(吨)</span></p>
            </div>
            <img src="../../assets/content/icon_1.png" alt="">
          </div>
          <div class="table-box" id="table-box">
            <a-table
                :columns="column"
                :data-source="VehicleListData"
                :pagination="false"
                :scroll="{ y: tableHeight }"
                style="color: #000; "
                :customRow="handleCheck"
                :locale="locale"
                :rowKey="(record,index)=>{return index}"
              >
              <template slot="val3" slot-scope="text">
                <span :style="{color:'#FF5C2C'}">{{ text }}</span>
              </template>
              <template slot="val5" slot-scope="text">
                <img style="width:57px; height:24px;" :src="carImgList[text]" alt="">
              </template>
            </a-table>  
          </div>
        </div>
         <div class="bottom">
          <p>船舶生产</p>
          <div class="center-title">
            <img src="../../assets/content/ship.png" alt="">
            <div>
              <p>{{ShipSummaryData.val1 ? ShipSummaryData.val1 : '-'}}</p>
              <p>计划<span>(吨)</span></p>
            </div>
            <div>
              <p>{{ShipSummaryData.val2 ? ShipSummaryData.val2 : '-'}}</p>
              <p>实际<span>(吨)</span></p>
            </div>
            <img src="../../assets/content/icon_1.png" alt="">
          </div>
          <div class="table-box" id="table-box">
            <a-table
                :columns="shipcolums"
                :data-source="mcgetShipListData"
                :pagination="false"
                :scroll="{ y: tableHeight }"
                style="color: #000; overflow-x: hidden; overflow-y: hidden"
                :customRow="handleCheck"
                :locale="locale"
                :rowKey="(record,index)=>{return index}"
              >
              <template slot="val3" slot-scope="text">
                <span :style="{color:'#FF5C2C'}">{{ text }}</span>
              </template>
              <template slot="val5" slot-scope="text">
                <img style="width:57px; height:24px;" :src="shipimgList[text]" alt="">
              </template>
            </a-table>
          </div>
        </div>
      </div>
      <div class="dispath_cont_left_bottom">
        <div class="top">
          <p>监控中心</p>
          <div class="left_box">
            <div class="dibang" >
              <p>地磅区</p>
              <div class="videobox" v-for="(item, index) in CameraListData.slice(0,3) " :key="index">
                <video
                :id="'video' + item.id"
                controls
                autoplay
                muted
                type='video/mp4'
              ></video>
              <span class="info">地磅#1</span>
              </div>
            </div>
            <div class="dibang">
              <p>泊位装卸区</p>
              <div class="videobox" v-for="(item, index) in CameraListData.slice(3,6)" :key="index">
                <video
                :id="'video' + item.id"
                controls
                autoplay
                muted
                type='video/mp4'
              ></video>
              </div>
            </div>
            <div class="dibang">
              <p>堆场区</p>
              <div class="videobox" v-for="(item, index) in CameraListData.slice(6,9)" :key="index">
                <video
                :id="'video' + item.id"
                controls
                autoplay
                muted
                type='video/mp4'
              ></video>
              </div>
            </div>
          </div>
           <div class="mask"></div>
        </div>
        <div class="center">
          <p>车辆生产</p>
          <div class="center-title">
            <img src="../../assets/content/car.png" alt="">
            <div>
              <p>{{VehicleSummaryData.val1 ? VehicleSummaryData.val1 : '-'}}</p>
              <p>计划<span>(吨)</span></p>
            </div>
            <div>
              <p>{{VehicleSummaryData.val2 ? VehicleSummaryData.val2 : '-'}}</p>
              <p>实际<span>(吨)</span></p>
            </div>
            <img src="../../assets/content/icon_1.png" alt="">
          </div>
          <div class="table-box" id="table-box">
            <a-table
                :columns="column"
                :data-source="VehicleListData"
                :pagination="false"
                :scroll="{ y: tableHeight }"
                style="color: #000; "
                :customRow="handleCheck"
                :locale="locale"
                :rowKey="(record,index)=>{return index}"
              >
              <template slot="val3" slot-scope="text">
                <span :style="{color:'#FF5C2C'}">{{ text }}</span>
              </template>
              <template slot="val5" slot-scope="text">
                <img style="width:57px; height:24px;" :src="carImgList[text]" alt="">
              </template>
            </a-table>  
          </div>
        </div>
         <div class="bottom">
          <p>船舶生产</p>
          <div class="center-title">
            <img src="../../assets/content/ship.png" alt="">
            <div>
              <p>{{ShipSummaryData.val1 ? ShipSummaryData.val1 : '-'}}</p>
              <p>计划<span>(吨)</span></p>
            </div>
            <div>
              <p>{{ShipSummaryData.val2 ? ShipSummaryData.val2 : '-'}}</p>
              <p>实际<span>(吨)</span></p>
            </div>
            <img src="../../assets/content/icon_1.png" alt="">
          </div>
          <div class="table-box" id="table-box">
            <a-table
                :columns="shipcolums"
                :data-source="mcgetShipListData"
                :pagination="false"
                :scroll="{ y: tableHeight }"
                style="color: #000; overflow-x: hidden; overflow-y: hidden"
                :customRow="handleCheck"
                :locale="locale"
                :rowKey="(record,index)=>{return index}"
              >
              <template slot="val3" slot-scope="text">
                <span :style="{color:'#FF5C2C'}">{{ text }}</span>
              </template>
              <template slot="val5" slot-scope="text">
                <img style="width:57px; height:24px;" :src="shipimgList[text]" alt="">
              </template>
            </a-table>
          </div>
        </div>
      </div>
      <div class="dispath_cont_center">
        <div class="dispath_cont_center_head">
          <div>
            <p>{{topSummaryList.val1 ? topSummaryList.val1 : '-'}}</p>
            <p>计划<span>装卸量(吨)</span></p>
          </div>
          <div>
            <p>{{topSummaryList.val2 ? topSummaryList.val2 : '-'}}</p>
            <p>实际<span>装卸量(吨)</span></p>
          </div>
          <div>
            <p>{{topSummaryList.val3 ? topSummaryList.val3 : '-'}}</p>
            <p>堆场<span>存放量(吨)</span></p>
          </div>
          <div>
            <p>{{topSummaryList.val4 ? topSummaryList.val4 : '-'}}</p>
            <p>警报情况</p>
          </div>
        </div>
        <div class="dispath_cont_center_tuichang">
          <div class="background_top">
            <div v-for="(item,i) in 6" :key="i"></div>  
          </div> 
          <div class="background_cont">
           <div class="aa"></div>
            <div class="background_cont_wrapper" id="background_cont_wrapper">
               
              <div class="slider" id="slider" @mousedown="mouseMoveEvent" >
                <div class="content_box" v-for="(item,i) in StoreStatusListData" :key="i" v-if="cardDataShow">
                  <div class="content_item">
                    <div class="content_item_mask"></div>
                
                    <div class="info" id="info">
                      <p>{{item.val1}}<span>%</span> </p>
                      <p>{{item.val2}}</p>
                    </div>
                    <div class='info_num'>{{item.val3}}</div>
                  </div>
                </div>
              </div>
              
            </div>
            <div class="bb"></div>
          </div> 
          <div class="berthage" id="berthage">
            <div class="mask"></div>
            <div class="state_box" id="state_box" @mousedown="mouseMoveEventImg">
              <div class="state" id='state' v-for="(items,z) in BerthStatusListData" :key="z">
                <!-- <img  :src="items.val1 ? require(`@/assets/content/${items.val1}`) : ''" ondragstart='return false' alt="">
                <img :src="items.val3 ? require(`@/assets/content/${items.val3}`) : ''"  ondragstart='return false' alt=""> -->
                <!-- 0门吊  1抓斗 2 吊钩   3 皮带机-->
                <!-- val1 设备名称, val2 设备类型 val3 设备状态 val4 泊位名称  val5 泊位状态 -->
                <!-- val3 0：工作；1：待机 2：保养 3：维护 4:故障 -->

                <!-- 0门吊 -->
                <img v-if="items.val2==0&&items.val3==0" src="../../assets/content/md_3.png"  ondragstart='return false' alt="">
                <img v-if="items.val2==0&&items.val3==1" src="../../assets/content/md_1.png"  ondragstart='return false' alt="">
                <img v-if="items.val2==0&&items.val3>1" src="../../assets/content/md_5.png"  ondragstart='return false' alt="">
                <!-- 1抓斗 -->
                <img v-if="items.val2==1&&items.val3==0" src="../../assets/content/zd_3.png"  ondragstart='return false' alt="">
                <img v-if="items.val2==1&&items.val3==1" src="../../assets/content/zd_1.png"  ondragstart='return false' alt="">
                <img v-if="items.val2==1&&items.val3>1" src="../../assets/content/zd_5.png"  ondragstart='return false' alt="">
                <!-- 2吊钩 -->
                <img v-if="items.val2==2&&items.val3==0" src="../../assets/content/diaogou_3.png"  ondragstart='return false' alt="">
                <img v-if="items.val2==2&&items.val3==1" src="../../assets/content/diaogou_1.png"  ondragstart='return false' alt="">
                <img v-if="items.val2==2&&items.val3>1" src="../../assets/content/diaogou_5.png"  ondragstart='return false' alt="">
                <!-- 3皮带机 -->
                <img v-if="items.val2==3&&items.val3==0" src="../../assets/content/pd_3.png"  ondragstart='return false' alt="">
                <img v-if="items.val2==3&&items.val3==1" src="../../assets/content/pd_1.png"  ondragstart='return false' alt="">
                <img v-if="items.val2==3&&items.val3>1"  src="../../assets/content/pd_5.png"  ondragstart='return false' alt="">
                
                <img v-if="items.val5==1" src="../../assets/content/ship3.png"  ondragstart='return false' alt="">
                <img v-if="items.val5==0" src="../../assets/content/ship2.png"  ondragstart='return false' alt="">
                <div class="berth">{{items.val4 ? items.val4 : ''}}</div>
              </div> 
            </div> 

          </div>
          <div class="ship">
            <img src="@/assets/content/icon2.png" v-for="(item,j) in 5" :key="j" alt="">
          </div>
        </div>
        <div class="godataCenter" @click="godataCenter">
              <img  src="../../assets/content/gocenter.png" alt="" >
        </div>
      </div>
      <div class="dispath_cont_right">
          <div class="top">
          <p>警报记录</p>
          <div class="center-title">
             <div>
              <p>4</p>
              <p>监控警报</p>
            </div>
            <div>
              <p>1</p>
              <p>设备警报</p>
            </div>
            <div>
              <p>0</p>
              <p>人员警报</p>
            </div>
          </div>
          <div class="table-box" id="table-box">
            <a-table
                :columns="warningcolumn"
                :data-source="warningcolumndata"
                :pagination="false"
                :scroll="{ y: tableHeight }"
                style="color: #000; overflow-x: hidden; overflow-y: hidden"
                :customRow="handleCheck"
                :locale="locale"
                :rowKey="(record,index)=>{return index}"
              >
              <template slot="val2" slot-scope="text">
                <span :style=" `color:${ text === '进' ? 'FF5C2C' :'#00B994' }`">{{ text }}</span>
              </template>
              <!-- <template slot="val3" slot-scope="text">
                <img :style=" `color:${ text === '进' ? 'FF5C2C' :'#00B994' }`" alt="">
              </template> -->
            </a-table>
          </div>
        </div>
        <div class="center">
          <p>堆场进出</p>
          <div class="center-title">
            <img src="../../assets/content/home.png" alt="">
            <div>
              <p>{{StoreSummaryData.val1?StoreSummaryData.val1:'-'}}</p>
              <p>进量<span>(吨)</span></p>
            </div>
            <div>
              <p>{{StoreSummaryData.val2?StoreSummaryData.val2:'-'}}</p>
              <p>出量<span>(吨)</span></p>
            </div>
            <img src="../../assets/content/icon_1.png" alt="">
          </div>
          <div class="table-box" id="table-box">
            <a-table
                :columns="storecolumn"
                :data-source="StoreListData"
                :pagination="false"
                :scroll="{ y: tableHeight }"
                style="color: #000; overflow-x: hidden; overflow-y: hidden"
                :customRow="handleCheck"
                :locale="locale"
                :rowKey="(record,index)=>{return index}"
              >
              <template slot="val2" slot-scope="text">
                <span :style=" `color:${ text === '进' ? '#FF5C2C' :'#00B994' }`">{{ text }}</span>
              </template>
               <template slot="val3" slot-scope="text">
                <span :style=" `color:${ text === '进' ? '#FF5C2C' :'#00B994' }`">{{ text }}</span>
              </template>
            </a-table>
          </div>
        </div>
         <div class="bottom">
          <p>设备生产</p>
          <div class="center-title">
            <img src="../../assets/content/xcar.png" alt="">
            <div>
              <p>{{DeviceSummaryData.val1 ? DeviceSummaryData.val1 : '-'}}</p>
              <p>计划<span>(吨)</span></p>
            </div>
            <div>
              <p>{{DeviceSummaryData.val2 ? DeviceSummaryData.val2 : '-'}}</p>
              <p>实际<span>(吨)</span></p>
            </div>
            <img src="../../assets/content/icon_1.png" alt="">
          </div>
          <div class="table-box" id="table-box">
            <a-table
                :columns="Devicecolums"
                :data-source="DeviceListData"
                :pagination="false"
                :scroll="{ y: tableHeight }"
                style="color: #000; overflow-x: hidden; overflow-y: hidden"
                :customRow="handleCheck"
                :locale="locale"
                :rowKey="(record,index)=>{return index}"
              >
               <!-- <template slot="val3" slot-scope="text">
                <span :style="`{color:'#FF5C2C'}`">{{ text }}</span>
              </template> -->
              <template slot="val3" slot-scope="text">
                <span :style="`color: ${ text === '装' ? '#FF5C2C' : '#00B994' }`">{{ text }}</span>
              </template>
            </a-table>
          </div>
        </div>
      </div>
      <div class="dispath_cont_right_bottom">
          <div class="top">
          <p>警报记录</p>
          <div class="center-title">
             <div>
              <p>4</p>
              <p>监控警报</p>
            </div>
            <div>
              <p>1</p>
              <p>设备警报</p>
            </div>
            <div>
              <p>0</p>
              <p>人员警报</p>
            </div>
          </div>
          <div class="table-box" id="table-box">
            <a-table
                :columns="warningcolumn"
                :data-source="warningcolumndata"
                :pagination="false"
                :scroll="{ y: tableHeight }"
                style="color: #000; overflow-x: hidden; overflow-y: hidden"
                :customRow="handleCheck"
                :locale="locale"
                :rowKey="(record,index)=>{return index}"
              >
              <template slot="goodsname" slot-scope="text">
                <span :style="{color:'#FF5C2C'}">{{ text }}</span>
              </template>
              <template slot="optType" slot-scope="text">
                <img style="width:57px; height:24px;" :src="shipimgList[text]" alt="">
              </template>
            </a-table>
          </div>
        </div>
        <div class="center">
          <p>堆场进出</p>
          <div class="center-title">
            <img src="../../assets/content/home.png" alt="">
            <div>
              <p>11,540</p>
              <p>进量<span>(吨)</span></p>
            </div>
            <div>
              <p>2,540</p>
              <p>出量<span>(吨)</span></p>
            </div>
            <img src="../../assets/content/icon_1.png" alt="">
          </div>
          <div class="table-box" id="table-box">
            <a-table
                :columns="column"
                :data-source="columnsData"
                :pagination="false"
                :scroll="{ y: tableHeight }"
                style="color: #000; overflow-x: hidden; overflow-y: hidden"
                :customRow="handleCheck"
                :locale="locale"
                :rowKey="(record,index)=>{return index}"
              >
              <template slot="goodsname" slot-scope="text">
                <span :style="{color:'#FF5C2C'}">{{ text }}</span>
              </template>
              <template slot="optType" slot-scope="text">
                <img style="width:57px; height:24px;" :src="shipimgList[text]" alt="">
              </template>
            </a-table>
          </div>
        </div>
         <div class="bottom">
          <p>设备生产</p>
          <div class="center-title">
            <img src="../../assets/content/xcar.png" alt="">
            <div>
              <p>11,540</p>
              <p>计划<span>(吨)</span></p>
            </div>
            <div>
              <p>2,540</p>
              <p>实际<span>(吨)</span></p>
            </div>
            <img src="../../assets/content/icon_1.png" alt="">
          </div>
          <div class="table-box" id="table-box">
            <a-table
                :columns="shipcolums"
                :data-source="columnsData"
                :pagination="false"
                :scroll="{ y: tableHeight }"
                style="color: #000; overflow-x: hidden; overflow-y: hidden"
                :customRow="handleCheck"
                :locale="locale"
                :rowKey="(record,index)=>{return index}"
              >
              <template slot="goodsname" slot-scope="text">
                <span :style="{color:'#FF5C2C'}">{{ text }}</span>
              </template>
              <template slot="optType" slot-scope="text">
                <img style="width:57px; height:24px;" :src="shipimgList[text]" alt="">
              </template>
            </a-table>
          </div>
        </div>
      </div>
    </div>
    <div class="dispath_bottom_tab">
      <div class="dispath_tabbox">
        <div  v-for="(item,i) in tablist" :key="i">
          <img :src="item.src" alt="" @click="godetails(i)">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
//=> 引入flv.js
import {
  getTopSummary,
  getCameraList,
  getVehicleSummary,
  mcgetVehicleList,
  getShipSummary,
  mcgetShipList,
  getStoreSummary,
  getStoreList,
  getDeviceSummary,
  getDeviceList,
  getStoreStatusList,
  getBerthStatusList
  } from '../../http//index'
import flvjs from "flv.js";
export default {
  data() {
    return {
      locale:{
        emptyText: '暂无数据'
      },
      tablist:[
        {
          id:1,
          src:require("../../assets/content/scjh_text.png")
        },
        {
          id:2,
          src:require("../../assets/content/cljk_text.png")
        },
        {
          id:3,
          src:require("../../assets/content/cbjk_text.png")
        },
        {
          id:4,
          src:require("../../assets/content/dp.png")
        },
        {
          id:5,
          src:require("../../assets/content/sbgl_text.png")
        },
        {
          id:6,
          src:require("../../assets/content/xssj_text.png")
        },
        {
          id:7,
          src:require("../../assets/content/hwsj_text.png")
        }
      ],
  
      tableHeight: 0,
      //=> 后台反的视频流
      // list: [
      //   {
      //     src: "http://58.57.206.122:8000/live/platea.flv",
      //     id: 0,
      //   },
      //   {
      //     src: "http://58.57.206.122:8000/live/plateb.flv",
      //     id: 1,
      //   },
      //   {
      //     src: "http://58.57.206.122:8000/live/topa.flv",
      //     id: 2,
      //   },
      //   {
      //     src: "http://58.57.206.122:8000/live/topb.flv",
      //     id: 3,
      //   },
      // ],
      carImgList:{
        1: require('../../assets/image/toShip.png'),
        2: require('../../assets/image/car.png'),
        3: require('../../assets/image/ship.png'),
        4: require('../../assets/image/toPort.png'),

      },
      shipimgList:{
        1: require('../../assets/image/car.png'),
        3: require('../../assets/image/toCar_1.png'),
        5: require('../../assets/image/car.png'),
        6: require('../../assets/image/toPort.png'),
      },
      warningcolumn:[
        {
          title: "时间",
          dataIndex: "time",
          width: 70,
        },
        {
          title: "类型",
          dataIndex: "type",
          width: 70,
        },
        {
          title: "内容",
          dataIndex: "content",
          width: 120,
        },
      ],
      warningcolumndata:[
        {
          time:'10-24 12:00',
          type:'监控警报',
          content:'警报：有人进入危险区域'
        },
        {
          time:'10-24 12:00',
          type:'监控警报',
          content:'警报：有人进入危险区域'
        },
        {
          time:'10-24 12:00',
          type:'监控警报',
          content:'警报：有人进入危险区域'
        },
        {
          time:'10-24 12:00',
          type:'监控警报',
          content:'警报：有人进入危险区域'
        },
      ],
      shipcolums:[
        {
          title: "船名",
          dataIndex: "val1",
          width: 80,
        },
        {
          title: "进港时间",
          dataIndex: "val2",
          width: 65,
          scopedSlots: { customRender: 'val2' },

        },
        {
          title: "货量(t)",
          dataIndex: "val3",
          width: 65,
        },
        {
          title: "货名",
          dataIndex: "val4",
          width: 60,
          scopedSlots: { customRender: 'val4' },
        },
        {
          title: "货物流向",
          dataIndex: "val5",
          width: 90,
          scopedSlots: { customRender: 'val5' },

        },
      ],
      Devicecolums:[
        {
          title: "时间",
          dataIndex: "val1",
          width: 70,
        },
        {
          title: "设备",
          dataIndex: "val2",
          width: 80,

        },
        {
          title: "装/卸",
          dataIndex: "val3",
          width: 80,
          scopedSlots: { customRender: 'val3' },

        },
        {
          title: "货量",
          dataIndex: "val4",
          width: 70,
        },
        {
          title: "客户",
          dataIndex: "val5",
          width: 100,
        },
      ],
      storecolumn:[{
          title: "时间",
          dataIndex: "val1",
          width: 70,
        },
        {
          title: "进/出",
          dataIndex: "val2",
          width: 70,
          scopedSlots: { customRender: 'val2' },

        },
        {
          title: "货量(t)",
          dataIndex: "val3",
          width: 80,
          scopedSlots: { customRender: 'val2' },
        },
        {
          title: "货名",
          dataIndex: "val4",
          width: 70,
          scopedSlots: { customRender: 'val4' },
        },
        {
          title: "客户",
          dataIndex: "val5",
          width: 100,
          scopedSlots: { customRender: 'val5' },

        },],
      column: [
        {
          title: "车牌号",
          dataIndex: "val1",
          width: 85,
        },
        {
          title: "进港时间",
          dataIndex: "val2",
          width: 65,
          scopedSlots: { customRender: 'goodsname' },

        },
        {
          title: "货量(kg)",
          dataIndex: "val3",
          width: 70,
        },
        {
          title: "货名",
          dataIndex: "val4",
          width: 65,
          scopedSlots: { customRender: 'val4' },
        },
        {
          title: "货物流向",
          dataIndex: "val5",
          width: 100,
          scopedSlots: { customRender: 'val5' },

        },
      ],
      
      columnsData:[
        {
          carnum:'鲁B 67533',
          intime:'17:00',
          kg:'56000',
          goodsname:'煤炭',
          optType:'1'
        },
        {
          carnum:'鲁B 67533',
          intime:'17:00',
          kg:'56000',
          goodsname:'煤炭',
          optType:'1'
        },
        {
          carnum:'鲁B 67533',
          intime:'17:00',
          kg:'56000',
          goodsname:'煤炭',
          optType:'5'
        },
        {
          carnum:'鲁B 67533',
          intime:'17:00',
          kg:'56000',
          goodsname:'煤炭',
          optType:'1'
        },
        {
          carnum:'鲁B 67533',
          intime:'17:00',
          kg:'56000',
          goodsname:'煤炭',
          optType:'1'
        }
      ],
      duichangList:[
        {
          list_1:'80',
          totle:'90000',
          percent:'3000',
          num:'一号煤堆场'
        },
        {
          list_1:'80',
          totle:'90000',
          percent:'3000',
          num:'二号煤堆场'
        },
        {
          list_1:'80',
          totle:'90000',
          percent:'3000',
          num:'三号煤堆场'
        },
        {
          list_1:'80',
          totle:'90000',
          percent:'3000',
          num:'四号煤堆场'
        },
         {
          list_1:'80',
          totle:'90000',
          percent:'3000',
          num:'四号煤堆场'
        },
         {
          list_1:'80',
          totle:'90000',
          percent:'3000',
          num:'四号煤堆场'
        },

      ],
      berthageState:[
        {
          code:'11',
        },
        {
          code:'21'
        },
        {
          code:'31'
        },
        {
          code:'41'
        },
      ],
      currentX: 0,
      currentImgX:0,
      divX: 0,
      topSummaryList:'',
      portName:'',
      CameraListData:[],
      VehicleSummaryData:'',
      VehicleListData:[],
      ShipSummaryData:[],
      mcgetShipListData:[],
      StoreSummaryData:[],
      StoreListData:[],
      DeviceSummaryData:'',
      DeviceListData:[],
      StoreStatusListData:'',
      BerthStatusListData:[],
      intervalId:null,
      cardDataShow:false,
      players:[]
    };
  },
  beforeDestroy() {
    document.removeEventListener('mouseup', this.mouseUpEvent)
    document.removeEventListener('mouseup', this.mouseUpEventImg)
    this.closePlayer()
    this.clear();
  },
  mounted() {
    document.addEventListener('mouseup', this.mouseUpEvent)
    document.addEventListener('mouseup', this.mouseUpEventImg)
    this.$nextTick(() => {
        this.initHeight();
       
    });
    
    // setTimeout(()=>{
    //     this.calculateSliderWidth()
    //     this.calculateSliderWidthImg()
    // },3000)
    setTimeout(()=>{
        this.startPlay()
    },5000)
  },
  created(){
    this.params = {
        apikey:'Sw4Mi3MdjII=',
        portName:'wf'
    },
    this.getDataList()
    this. dataRefreh()
  },
  methods: {
     closePlayer() {
        this.CameraListData.forEach((item, index) => {
          if(this.players[index]){
            this.players[index].pause()
            this.players[index].unload()
            this.players[index].detachMediaElement()
            this.players[index].destroy()
            this.players[index] = null
          }
        })
      },
    // 定时刷新数据函数
    dataRefreh() {
      // 计时器正在进行中，退出函数
      if (this.intervalId !== null) {
        return;
      }
      // 计时器为空，操作
      this.intervalId = setInterval(() => {
        this.getDataList();
      }, 60000*5);
    },
    // 停止定时器
    clear() {
      this.intervalId && clearInterval(this.intervalId); //清除计时器
    },
    godataCenter(){
      this.$router.push({
          path:'/'
        })
    },
    // 点击底部按钮跳转页面
    godetails(e){
      if(e === 0){
        this.$router.push({
          path:'/Production'
        })
      }else if(e === 1){
        this.$router.push({
          path:'/WbCenter'
        })
      }else if( e === 2){
        this.$router.push({
          path:'/shipCenter'
        })
      }else if( e === 4){
        this.$router.push({
          path:'/Equipment'
        })
      }else if( e === 5){
        this.$router.push({
          path:'/SaleCenter'
        })
      }else if( e === 6){
        this.$router.push({
          path:'/GoodsCenter'
        })
      }
    },
    getDataList(){
      getTopSummary(this.params).then((res)=>{
        this.topSummaryList = res
      })

       getCameraList(this.params).then((res)=>{
            this.CameraListData = res       
          })
      getVehicleSummary(this.params).then((res)=>{
        this.VehicleSummaryData = res
      })
      mcgetVehicleList(this.params).then((res)=>{
        this.VehicleListData = res
      })
      getShipSummary(this.params).then((res)=>{
        this.ShipSummaryData = res
      })
      mcgetShipList(this.params).then((res)=>{
        this.mcgetShipListData = res
      })
       getStoreSummary(this.params).then((res)=>{
        this.StoreSummaryData = res
      })
       getStoreList(this.params).then((res)=>{
        this.StoreListData = res
      })
      getDeviceSummary(this.params).then((res)=>{
        this.DeviceSummaryData = res
      })
      getDeviceList(this.params).then((res)=>{
        this.DeviceListData = res
      })
      getStoreStatusList(this.params).then((res)=>{
        this.StoreStatusListData = res
        this.cardDataShow=true
        setTimeout(()=>{
              this.calculateSliderWidth()
          },500)
      })
      getBerthStatusList(this.params).then((res)=>{
          this.BerthStatusListData = [...res]
          setTimeout(()=>{
              this.calculateSliderWidthImg()
          },500)
      })
  
    },
     // 鼠标移入图片状态
    mouseOver() {
     
    },
    // 鼠标移出图片状态
    mouseLeave() {
      
    },
    handleCheck() {},
    initHeight() {
      const tableBox = document.getElementById("table-box");
      const height = tableBox.offsetHeight;
      console.log(height);
      this.tableHeight = height;
    },
    calculateSliderWidth() {
      const width = document.getElementById('background_cont_wrapper').clientWidth
      const contentArr = document.getElementsByClassName('content_box')
      document.getElementById('slider').style.width = contentArr.length * (width / 4) + 20 + 'px'
      for (let i = 0; i < contentArr.length; i++) {
        contentArr[i].style.width = width / 4 + 'px'
      }
    },
    calculateSliderWidthImg() {
      const width = document.getElementById('berthage').clientWidth
      const contentArr = document.getElementsByClassName('state')
      document.getElementById('state_box').style.width = contentArr.length * (width / 6) + 20 + 'px'
     for (let i = 0; i < contentArr.length; i++) {
        contentArr[i].style.width = width / 6 + 'px'
      }
    },
    mouseMoveEvent(e) {
      //记录鼠标按下瞬间的位置
      this.currentX =  e.clientX;
      const ele = document.getElementById('slider')
      this.divX = ele.offsetLeft
      console.log(this.divX)
      ele.addEventListener('mousemove', this.mouseMoveReal)
    },
    mouseMoveEventImg(e){
      //记录鼠标按下瞬间的位置
      this.currentImgX =  e.clientX;
      const ele = document.getElementById('state_box')
      this.divX = ele.offsetLeft
      console.log(this.divX)
      ele.addEventListener('mousemove', this.mouseMoveRealImg)  
    },
    mouseMoveReal(x) {
      const event = event || window.event
      const ele = document.getElementById('slider')
      //实时监听鼠标位置
      const moveX = event.clientX - this.currentX
      console.log(moveX)
      ele.style.left = (moveX + this.divX) + 'px'
    },
     mouseMoveRealImg(x) {
      const event = event || window.event
      const ele = document.getElementById('state_box')
      //实时监听鼠标位置
      const moveX = event.clientX - this.currentImgX
      console.log(moveX)
      ele.style.left = (moveX + this.divX) + 'px'
    },
    mouseUpEvent() {
      const ele = document.getElementById('slider')
      ele.removeEventListener('mousemove', this.mouseMoveReal)
      const width = document.getElementById('background_cont_wrapper').clientWidth
     const eleWidth = ele.clientWidth
      const eleLeftg = ele.style.left.replace('px', '') * 1
      if (eleLeftg >= 0) {
        ele.style.left = 0
      } else if (Math.abs(eleLeftg) >= (eleWidth - width - 20)) {
        ele.style.left = - (eleWidth - width - 20) + 'px'
      }
    },
    mouseUpEventImg() {
      const ele = document.getElementById('state_box')
      ele.removeEventListener('mousemove', this.mouseMoveRealImg)
      const width = document.getElementById('berthage').clientWidth
      const eleWidth = ele.clientWidth
      const eleLeftg = ele.style.left.replace('px', '') * 1
     if (eleLeftg >= 0) {
        ele.style.left = 0
      } else if (Math.abs(eleLeftg) >= (eleWidth - width - 20)) {
        ele.style.left = - (eleWidth - width - 20) + 'px'
      }
    },
   
    //视频浏览
    startPlay() {
      this.CameraListData.forEach((item, index) => {
        if (flvjs.isSupported()) {
          // 判断是否有直播链接，防止一直循环
          if (item.src) {
            let player = null;
            let videoElement = document.getElementById("video" + item.id);
            player = flvjs.createPlayer({
              type: "flv", //=> 媒体类型 flv 或 mp4
              isLive: true, //=> 是否为直播流
              hasAudio: false, //=> 是否开启声音
              url: 'http://data.sfghjt.com'+item.src, //=> 视频流地址
            });
            player.attachMediaElement(videoElement); //=> 绑DOM
            player.load();
            player.play();
            this.players[index]=player
          }
        } else {
          console.log("flvjs不支持");
        }
      });
    },
  }
};
</script>

<style scoped lang="less">
@import "./index.less";
</style>
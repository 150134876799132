import { request } from "./request";
// 汇总中心
export function getAllPortSummary() {
    return request({
        url: `/api/summary/getAllPortSummary`,
        method: 'get',
    })
}
export function getAllPortThroughput() {
    return request({
        url: `/api/summary/getAllPortThroughput`,
        method: 'get',
    })
}
export function getOnePortSummary(data = {}) {
    return request({
        url: `/api/summary/getOnePortSummary`,
        method: 'post',
        data
    })
}


export function getWagonBalanceList(data = {}) {
    // console.log(data, 'data')
    return request({
        url: `/api/wb/getWagonBalanceList?carType=${data.carType}`,
        method: 'post',
        data
    })
}
export function getWagonBalanceDetail(data = {}) {
    return request({
        url: `/api/wb/getWagonBalanceDetail?pk=${data.pk}`,
        method: 'post',
        data
    })
}
export function getTodaySummary(data = {}) {
    return request({
        url: `/api/wb/getTodaySummary?pk=${data.pk}`,
        method: 'post',
        data
    })
}
export function getThisMonthSummary(data = {}) {
    return request({
        url: `/api/wb/getThisMonthSummary?pk=${data.pk}`,
        method: 'post',
        data
    })
}

/**
 * 今日码头作业繁忙度
 * @param {*} data 
 * @returns 
 */
export function getTodayBusyCharts(data = {}) {
    return request({
        url: `/api/wb/getTodayBusyCharts`,
        method: 'post',
        data
    })
}

/**
 * 进出港环比 1日，2月，3年
 * @param {*} data 
 * @returns 
 */
export function getMonthBusyCharts(data = {}) {
    return request({
        url: `/api/wb/getMonthBusyCharts`,
        method: 'post',
        data
    })
}
// 港内船舶监控中心

// 船舶列表
export function getShipList(data = {}) {
    return request({
        url: `/api/ship/getShipList`,
        method: 'post',
        data
    })
}
// 船舶列表详情
export function getShipDetail(data = {}) {
    return request({
        url: `/api/ship/getShipDetail`,
        method: 'post',
        data
    })
}
// 今日港内数据
export function getShipTodaySummary(data = {}) {
    return request({
        url: `/api/ship/getTodaySummary`,
        method: 'post',
        data
    })
}
// 本月港内数据
export function getShipMonthSummary(data = {}) {
    return request({
        url: `/api/ship/getThisMonthSummary`,
        method: 'post',
        data
    })
}
// 今日码头繁忙度图表
export function getShipTodayBusyCharts(data = {}) {
    return request({
        url: `/api/ship/getTodayBusyCharts`,
        method: 'post',
        data
    })
}
// 进出口车次环比
export function getShipMonthBusyCharts(data = {}) {
    return request({
        url: `/api/ship/getMonthBusyCharts`,
        method: 'post',
        data
    })
}
// 生产调度查询车辆
export function getVehicleList(data = {}) {
    return request({
        url: `/api/dispatch/getVehicleList`,
        method: 'post',
        data
    })
}
// 生产调度船舶列表
export function getshipList(data = {}) {
    return request({
        url: `/api/dispatch/getShipList`,
        method: 'post',
        data
    })
}
// 生产调度查询车辆船舶装卸量接口
export function getSummary(data = {}) {
    return request({
        url: `/api/dispatch/getSummary`,
        method: 'post',
        data
    })

}
// 销售数据中心接口
// 销售数据中心
export function getThisMonthSales(data = {}) {
    return request({
        url: `/api/sales/getThisMonthSales`,
        method: 'post',
        data
    })
}
// 当前销售情况
export function getCurrentSales(data = {}) {
    return request({
        url: `/api/sales/getCurrentSales`,
        method: 'post',
        data
    })
}
// 本月客户数据
export function getThisMonthCustomer(data = {}) {
    return request({
        url: `/api/sales/getThisMonthCustomer`,
        method: 'post',
        data
    })
}
// 销售进度监控
export function getProgressList(data = {}) {
    return request({
        url: `/api/sales/getProgressList`,
        method: 'post',
        data
    })
}
// 销售进度监控
export function getTargetStatus(data = {}) {
    return request({
        url: `/api/sales/getTargetStatus`,
        method: 'post',
        data
    })
}
// 客户按销售货物量排名
export function getCustomerRankByGoods(data = {}) {
    return request({
        url: `/api/sales/getCustomerRankByGoods`,
        method: 'post',
        data
    })
}
//客户按销售单量排名
export function getCustomerRankByOrders(data = {}) {
    return request({
        url: `/api/sales/getCustomerRankByOrders`,
        method: 'post',
        data
    })
}
// 
export function getGoodsAmountChart(data = {}) {
    return request({
        url: `/api/sales/getGoodsAmountChart`,
        method: 'post',
        data
    })
}




// 设备监控管理中心

// 当前港内设备情况
export function getDeviceStatus(data = {}) {
    return request({
        url: `/api/device/getDeviceStatus`,
        method: 'post',
        data
    })
}
//  能耗及维保费用
export function getEnergyLossCost(data = {}) {
    return request({
        url: `/api/device/getEnergyLossCost`,
        method: 'post',
        data
    })
}
// 当前单个设备信息
export function getCurrentDeviceStatus(data = {}) {
    return request({
        url: `/api/device/getCurrentDeviceStatus`,
        method: 'post',
        data
    })
}
// 工作记录
export function getWorklogByDeviceId(data = {}) {
    return request({
        url: `/api/device/getWorklogByDeviceId`,
        method: 'post',
        data
    })
}
// 本月能耗及维保费用排名
export function getEnergyLossRank(data = {}) {
    return request({
        url: `/api/device/getEnergyLossRank`,
        method: 'post',
        data
    })
}
// 该设备本月耗电量变化(度)
export function getEnergyLossByDeviceId(data = {}) {
    return request({
        url: `/api/device/getEnergyLossByDeviceId`,
        method: 'post',
        data
    })
}
// 维修故障次数各月份占比
export function getMaintainNumByDeviceId(data = {}) {
    return request({
        url: `/api/device/getMaintainNumByDeviceId`,
        method: 'post',
        data
    })
}

// 各设备维修故障次数占比对比
export function getMaintainNumCostChart(data = {}) {
    return request({
        url: `/api/device/getMaintainNumCostChart`,
        method: 'post',
        data
    })
}
// 监控大屏
//本月销售数据
export function getTopSummary(data = {}) {
    return request({
        url: `/api/mc/getTopSummary`,
        method: 'post',
        data
    })
}
// 视频监控中心
export function getCameraList(data = {}) {
    return request({
        url: `/api/mc/getCameraList`,
        method: 'post',
        data
    })
}
// 车辆生产列表上半部
export function getVehicleSummary(data = {}) {
    return request({
        url: `/api/mc/getVehicleSummary`,
        method: 'post',
        data
    })
}
// 车辆生产列表
export function mcgetVehicleList(data = {}) {
    return request({
        url: `/api/mc/getVehicleList`,
        method: 'post',
        data
    })
}
// 船舶生产列表上半部
export function getShipSummary(data = {}) {
    return request({
        url: `/api/mc/getShipSummary`,
        method: 'post',
        data
    })
}
// 船舶生产列表
export function mcgetShipList(data = {}) {
    return request({
        url: `/api/mc/getShipList`,
        method: 'post',
        data
    })
}
// 堆场进出列表上半部
export function getStoreSummary(data = {}) {
    return request({
        url: `/api/mc/getStoreSummary`,
        method: 'post',
        data
    })
}
// 堆场进出列表 
export function getStoreList(data = {}) {
    return request({
        url: `/api/mc/getStoreList`,
        method: 'post',
        data
    })
}
// 设备生产列表上半部分
export function getDeviceSummary(data = {}) {
    return request({
        url: `/api/mc/getDeviceSummary`,
        method: 'post',
        data
    })
}
// 设备生产列表
export function getDeviceList(data = {}) {
    return request({
        url: `/api/mc/getDeviceList`,
        method: 'post',
        data
    })
}
// 堆场状态列表
export function getStoreStatusList(data = {}) {
    return request({
        url: `/api/mc/getStoreStatusList`,
        method: 'post',
        data
    })
}
// 泊位状态列表
export function getBerthStatusList(data = {}) {
    return request({
        url: `/api/mc/getBerthStatusList`,
        method: 'post',
        data
    })
}






// 港口货物数据中心接口
// 本月吞吐力情况
export function getHCSummary(data = {}) {
    return request({
        url: `/api/goodsCenter/getHCSummary`,
        method: 'post',
        data
    })
}
// 本月吞吐力变化表
export function thisMonthHCChart(data = {}) {
    return request({
        url: `/api/goodsCenter/thisMonthHCChart`,
        method: 'post',
        data
    })
}
// 货物类型统计
export function getCargoTypeSummary(data = {}) {
    return request({
        url: `/api/goodsCenter/getCargoTypeSummary`,
        method: 'post',
        data
    })
}
// 货物类型统计变化表
export function getGoodsRankChart(data = {}) {
    return request({
        url: `/api/goodsCenter/getGoodsRankChart`,
        method: 'post',
        data
    })
}
// 本月客户数量
export function getThisCustomerNum(data = {}) {
    return request({
        url: `/api/goodsCenter/getThisCustomerNum`,
        method: 'post',
        data
    })
}
// 点击某一客户统计在指定时间类型中，所选客户在同级客户中的占比以及所选货物类型在同级中的占比
export function getFdTopSummary(data = {}) {
    return request({
        url: `/api/goodsCenter/getFdTopSummary`,
        method: 'post',
        data
    })
}
// 煤炭所有客户/济宁热电本月货量变化(点击上图某一个客户生成本图)
export function getFdMiddleChart(data = {}) {
    return request({
        url: `/api/goodsCenter/getFdMiddleChart`,
        method: 'post',
        data
    })
}
// 煤炭所有客户/济宁热电本月货物销售销售信(点击上图某一个客户生成本列表)
export function getFdBottomList(data = {}) {
    return request({
        url: `/api/goodsCenter/getFdBottomList`,
        method: 'post',
        data
    })
}
// 各种类货物客户追踪 旭日图表
export function getFdChart(data = {}) {
    return request({
        url: `/api/goodsCenter/getFdChart`,
        method: 'post',
        data
    })
}
// 旭日图中心统计数据
export function getCoreSummary(data = {}) {
    return request({
        url: `/api/goodsCenter/getCoreSummary`,
        method: 'post',
        data
    })
}